import { checkTokenExpiration } from './authAction'
import { SUCCESS_NOTIFIER, toastNotifier } from '../../component/toaster/Toaste'
import { errorHandler } from '../../component/errorHandler/errorHandler'
import {
  GET_APPLICATIONS_SUCCESS,
  GET_APPLICATIONS_LOADING,
  GET_APPLICATIONS_ERROR,
  GET_APPLICATIONS_BY_JOB_ID_SUCCESS,
  GET_APPLICATIONS_BY_JOB_ID_LOADING,
  GET_APPLICATIONS_BY_JOB_ID_ERROR,
  POST_APPLICATIONS_SUCCESS,
  POST_APPLICATIONS_LOADING,
  POST_APPLICATIONS_ERROR,
  GET_RESUME_SUCCESS,
  GET_RESUME_LOADING,
  GET_RESUME_ERROR,
} from '../types'
import {
  getJobApplicationsCall,
  postJobApplicationsCall,
  getJobApplicationsByJobIdCall,
  updateJobApplicationsCall,
  getJobApplicationsByIdCall,
} from '../../component/network/axios'
import { APPLICATION, isNoData } from '../../component/utility'

export const getApplicationsAction = () => {
  return async (dispatch) => {
    dispatch({ type: GET_APPLICATIONS_LOADING })
    try {
      const data = await getJobApplicationsCall()
      dispatch({ type: GET_APPLICATIONS_SUCCESS, payload: data.data })
    } catch (error) {
      errorHandler({ error })
      dispatch({ type: GET_APPLICATIONS_ERROR, payload: error.message })

      checkTokenExpiration({
        dispatch,
        parentFunction: getApplicationsAction,
        error,
      })
    }
  }
}

export const getApplicationsByJobIdAction = (formData) => {
  return async (dispatch) => {
    dispatch({ type: GET_APPLICATIONS_BY_JOB_ID_LOADING })
    try {
      const data = await getJobApplicationsByJobIdCall({ params: formData })
      isNoData(data, APPLICATION)
      dispatch({ type: GET_APPLICATIONS_BY_JOB_ID_SUCCESS, payload: data.data })
    } catch (error) {
      errorHandler({ error })
      dispatch({ type: GET_APPLICATIONS_BY_JOB_ID_ERROR, payload: error.message })

      checkTokenExpiration({
        dispatch,
        parentFunction: getApplicationsAction,
        error,
      })
    }
  }
}

export const postApplicationsAction = (formData) => {
  return async (dispatch) => {
    dispatch({ type: POST_APPLICATIONS_LOADING })
    try {
      const data = await postJobApplicationsCall({ body: formData })
      dispatch({ type: POST_APPLICATIONS_SUCCESS, payload: data.data })
      toastNotifier({ type: SUCCESS_NOTIFIER, message: 'Job Applied' })
    } catch (error) {
      errorHandler({ error })
      dispatch({ type: POST_APPLICATIONS_ERROR, payload: error.message })

      checkTokenExpiration({
        dispatch,
        parentFunction: postApplicationsAction,
        error,
      })
    }
  }
}

export const updateApplicationsAction = (formData, id, jobId) => {
  return async (dispatch) => {
    try {
      await updateJobApplicationsCall({ body: formData, params: { id } })
      dispatch(getApplicationsByJobIdAction({ id: jobId }))
      toastNotifier({ type: SUCCESS_NOTIFIER, message: 'Applications Updated' })
    } catch (error) {
      errorHandler({ error })

      checkTokenExpiration({
        dispatch,
        parentFunction: updateApplicationsAction,
        error,
      })
    }
  }
}

export const getApplicationsByIdAction = (formData) => {
  return async (dispatch) => {
    dispatch({ type: GET_RESUME_LOADING })
    dispatch({ type: GET_APPLICATIONS_LOADING })
    try {
      const data = await getJobApplicationsByIdCall({ params: formData })
      isNoData(data, APPLICATION)
      dispatch({
        type: GET_RESUME_SUCCESS,
        payload: { Resume: data.data.JobApplication[0].Resume[0] },
        
      })
      dispatch({
        type: GET_APPLICATIONS_SUCCESS,
        payload: {data:data.data},
        
      })
      // console.log(data.data.JobApplication[0]);
    } catch (error) {
      errorHandler({ error })
      dispatch({ type: GET_RESUME_ERROR, payload: error.message })
      dispatch({ type: GET_APPLICATIONS_ERROR, payload: error.message })

      checkTokenExpiration({
        dispatch,
        parentFunction: getApplicationsAction,
        error,
      })
    }
  }
}
