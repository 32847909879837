import axios from 'axios'
import { isJwtExpired } from 'jwt-check-expiration'

// import * as types from "../types"
import { errorHandler } from '../../component/errorHandler/errorHandler'
import { loginCall, logoutCall, renewAccessTokenCall } from '../../component/network/axios'
import { INFO_NOTIFIER, SUCCESS_NOTIFIER, toastNotifier } from '../../component/toaster/Toaste'
import {
  AUTH_LOADING_START,
  AUTH_LOADING_STOP,
  AUTH_LOGOUT_SUCCESS,
  SET_ACCESS_TOKEN,
} from '../types'
import {
  MODAL_CONTENT_ON_EMPLOYEE_REGISTRATION,
  MODAL_CONTENT_ON_EMPLOYER_REGISTRATION,
  showModal,
} from '../ui'

export const checkAuth = () => async (dispatch) => {
  try {
    const userRole = await localStorage.getItem('userRole')
    const accessToken = await localStorage.getItem('accessToken')
    const refreshToken = await localStorage.getItem('refreshToken')
    const subscriptionType = await localStorage.getItem('subscriptionType')

    if (refreshToken && accessToken) {
      dispatch({
        type: SET_ACCESS_TOKEN,
        payload: {
          accessToken,
          refreshToken,
          subscriptionType,
          userRole,
        },
      })
    }
    else{
      dispatch({
        type: AUTH_LOADING_STOP,
      })

    }
  } catch (error) {
    errorHandler({ error })
  }
}

export const loginAction =
  ({ data, userRole }) =>
  async (dispatch) => {
    try {
      dispatch({ type: AUTH_LOADING_START })
      const res = await loginCall({ userRole, body: data })
      await localStorage.setItem('refreshToken', res.data.tokens.refreshToken)
      await localStorage.setItem('accessToken', res.data.tokens.accessToken)
      await localStorage.setItem('subscriptionType', res.data.subscriptionType)
      await localStorage.setItem('userRole', userRole)
      dispatch({
        type: SET_ACCESS_TOKEN,
        payload: {
          accessToken: res.data.tokens.accessToken,
          refreshToken: res.data.tokens.refreshToken,
          subscriptionType: res.data.subscriptionType,

          userRole,
        },
      })
    } catch (error) {
      errorHandler({ error })
    } finally {
      dispatch({ type: AUTH_LOADING_STOP })
    }
  }

export const candidateSignUp = (data,navigate) => async (dispatch) => {
  try {
    dispatch({ type: AUTH_LOADING_START })
    await axios.post(`${process.env.REACT_APP_BASE_URL}/candidate/registerCandidate`, data)
    toastNotifier({ type: SUCCESS_NOTIFIER, message: 'Successfully Registered As Employee' })
    dispatch(showModal(MODAL_CONTENT_ON_EMPLOYEE_REGISTRATION,navigate))
    navigate('/auth/login')
    dispatch({ type: SET_ACCESS_TOKEN })
  } catch (error) {
    errorHandler({ error })
  }
}

export const employerSignUp = (data,navigate) => async (dispatch) => {
  try {
    dispatch({ type: AUTH_LOADING_START })
    await axios.post(`${process.env.REACT_APP_BASE_URL}/employer/registerEmployer`, data)
    toastNotifier({ type: SUCCESS_NOTIFIER, message: 'Successfully Registered As Employer' })
    navigate('/auth/login')
    dispatch(showModal(MODAL_CONTENT_ON_EMPLOYER_REGISTRATION,navigate))
    dispatch({ type: SET_ACCESS_TOKEN })
  } catch (error) {
    errorHandler({ error })
  }
}

export const checkTokenExpiration = async ({ dispatch, parentFunction, error, params }) => {
  if (
    error?.response?.data?.message &&
    error.response.data.message[0] &&
    error.response.data.message[0] === 'Access token has expired'
  ) {
    try {
      const data = await renewAccessTokenCall()
      const accessToken = data.data.NewAccessToken
      localStorage.setItem('accessToken', accessToken)
      dispatch(parentFunction({ ...params }))
    } catch (err) {
      toastNotifier({ type: INFO_NOTIFIER, message: err.message[0] })
      console.log(err);
    }
  }
}

export const logoutAction = () => async (dispatch) => {
  dispatch({ type: AUTH_LOADING_START })
  try {
    await logoutCall()
  } catch (error) {
    checkTokenExpiration({ dispatch, parentFunction: logoutAction, error })
  } finally {
    dispatch({
      type: AUTH_LOGOUT_SUCCESS,
      payload: {
        accessToken: null,
        refreshToken: null,
        userRole: null,
      },
    })
    localStorage.clear()
    dispatch({ type: AUTH_LOADING_STOP })
  }
}



export const checkTokens = () =>{
  if(localStorage.getItem('accessToken') && localStorage.getItem('refreshToken')){
    if(isJwtExpired(localStorage.getItem('accessToken'))){
      if(isJwtExpired(localStorage.getItem('refreshToken'))){
        return true
      }
      else{
        return false
      }
  }else{
    return false
  }
  }
  

}