import { Link } from "react-router-dom";
import PrivacyPolicy from "../PrivacyPolicy";
import mainlogo from '../assets/images/logo.png'

export default function footer() {
  return (
    <div>
      <footer className="section footer-creative context-dark">
        <div className="footer-creative-main">
          <div className="container">
            <div className="row row-50 ">




              <div className="col-sm-6 col-lg-4 col-xl-3">

                <img style={{marginTop:'-15px'}} width={250} src={mainlogo} alt="logo" />


                {/* <p className="footer-creative-title">Recent News posts</p> */}
                {/* <div className="footer-creative-divider" /> */}
                {/* <div className="post-line-group">
                  <a className="post-line" href="#">
                    <time className="post-line-time" dateTime="2018">
                      <span className="post-line-time-day">25</span>
                      <span className="post-line-time-month">April</span>
                    </time>
                    <p className="post-line-text">Canada adds 12,500 jobs in modest July rebound</p>
                  </a>
                  <a className="post-line" href="#">
                    <time className="post-line-time" dateTime="2018">
                      <span className="post-line-time-day">14</span>
                      <span className="post-line-time-month">April</span>
                    </time>
                    <p className="post-line-text">Outsourcing vs. In-House Digital Marketing</p>
                  </a>
                </div> */}
              </div>


              <div className="col-lg-6 col-xl-6">
                <p className="footer-creative-title">Quick Links</p>
                <div className="footer-creative-divider" />

                <div className="row row-narrow row-15">
                  <div className="col-6">
                    <ul className="list-marked-1">
                      <li>
                        <Link to="/auth/signup">Browse Jobs</Link>
                      </li>
                      {/* <li>
                        <a href="job-listing-full.html">Browse Jobs</a>
                      </li> */}
                      {/* <li>
                        <a href="job-listing.html">Browse Categories</a>
                      </li> */}
                      <li>
                        <Link to="/auth/signup">Submit Resume</Link>
                      </li>
                      {/* <li>
                        <a href="companies.html">Companies</a>
                      </li> */}
                      <li>
                        <Link to="/termsofservices">Terms of Services</Link>
                      </li>
                    </ul>
                  </div>
                  <div className="col-6">
                    <ul className="list-marked-1">

                      <li>
                        <Link to="/auth/signup">Post a Job</Link>
                      </li>
                      {/* <li>
                        <a href="post-a-job.html">Post a Job</a>
                      </li> */}
                      <li>
                        <Link to="/auth/signup">Find a Candidate</Link>
                      </li>
                      {/* <li>
                        <a href="candidates-grid.html">Find a Candidate</a>
                      </li> */}

                      <li>
                        <Link to="/pricing">Pricing</Link>
                      </li>
                      {/* <li>
                        <a href="#">Pricing</a>
                      </li> */}
                      <li>
                        <a href="#">FAQ</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              {/* <div className="col-sm-6 col-lg-4 col-xl-3">
                <p className="footer-creative-title">Recent News posts</p>
                <div className="footer-creative-divider" />
                <div className="post-line-group">
                  <a className="post-line" href="#">
                    <time className="post-line-time" dateTime="2018">
                      <span className="post-line-time-day">25</span>
                      <span className="post-line-time-month">April</span>
                    </time>
                    <p className="post-line-text">Canada adds 12,500 jobs in modest July rebound</p>
                  </a>
                  <a className="post-line" href="#">
                    <time className="post-line-time" dateTime="2018">
                      <span className="post-line-time-day">14</span>
                      <span className="post-line-time-month">April</span>
                    </time>
                    <p className="post-line-text">Outsourcing vs. In-House Digital Marketing</p>
                  </a>
                </div>
              </div> */}

              {/* <div className="col-sm-6 col-lg-3">
                <p className="footer-creative-title">Jobs in Popular cities</p>
                <div className="footer-creative-divider" />
                <div className="row row-narrow row-15">
                  <div className="col-6">
                    <ul className="list list-1 list-icons">
                      <li>
                        <a href="job-listing-full.html">
                          <span className="icon icon-sm mdi mdi-map-marker" />
                          <span>San Francisco</span>
                        </a>
                      </li>
                      <li>
                        <a href="job-listing-full.html">
                          <span className="icon icon-sm mdi mdi-map-marker" />
                          <span>Washington</span>
                        </a>
                      </li>
                      <li>
                        <a href="job-listing-full.html">
                          <span className="icon icon-sm mdi mdi-map-marker" />
                          <span>Sacramento</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="col-6">
                    <ul className="list list-1 list-icons">
                      <li>
                        <a href="job-listing-full.html">
                          <span className="icon icon-sm mdi mdi-map-marker" />
                          <span>New York</span>
                        </a>
                      </li>
                      <li>
                        <a href="job-listing-full.html">
                          <span className="icon icon-sm mdi mdi-map-marker" />
                          <span>Ontario</span>
                        </a>
                      </li>
                      <li>
                        <a href="job-listing-full.html">
                          <span className="icon icon-sm mdi mdi-map-marker" />
                          <span>Chicago</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        <div className="footer-creative-aside">
          <div className="container">
            <p className="rights text-start">
              &copy; 2021 IMI APEXWAY SOLUTIONS (SMC-PRIVATE) LIMITED. All rights reserved. Developed by
              {/* <span>EmployCity</span>
              <span>&nbsp;&copy;&nbsp;</span>
              <span className="copyright-year" />
              <span>&nbsp;</span> */}
              <Link to="https://www.chaynztech.com/"> ChaynzTech</Link>
            </p>
          </div>
        </div>
      </footer>
    </div>
  )
}
