import {
  GET_RESUME_ERROR,
  GET_RESUME_LOADING,
  GET_RESUME_SUCCESS,
  UPDATE_RESUME_LOADING,
  UPDATE_RESUME_SUCCESS,
  UPDATE_RESUME_ERROR,
} from '../types'

const getInitialState = {
  getResumeLoading: true,
  getResumeSuccess: [],
  getResumeError: '',
}

export const getResumeReducer = (state = getInitialState, { type, payload }) => {
  switch (type) {
    case GET_RESUME_LOADING:
      return {
        ...state,
        getResumeLoading: true,
      }
    case GET_RESUME_SUCCESS:
      return {
        ...state,
        getResumeLoading: false,
        getResumeSuccess: payload,
      }
    case GET_RESUME_ERROR:
      return {
        ...state,
        getResumeLoading: false,
        getResumeError: payload,
      }

    default:
      return state
  }
}

const updateInitialState = {
  updateResumeLoading: false,
  updateResumeSuccess: [],
  updateResumeError: '',
}

export const updateResumeReducer = (state = updateInitialState, { type, payload }) => {
  switch (type) {
    case UPDATE_RESUME_LOADING:
      return {
        ...state,
        updateResumeLoading: true,
      }
    case UPDATE_RESUME_SUCCESS:
      return {
        ...state,
        updateResumeLoading: false,
        updateResumeSuccess: payload,
      }
    case UPDATE_RESUME_ERROR:
      return {
        ...state,
        updateResumeLoading: false,
        updateResumeError: payload,
      }

    default:
      return state
  }
}
