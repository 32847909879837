import React, { useState } from 'react'
import { ListGroupItem, Card, ListGroup, Row, Col } from 'react-bootstrap'
import blogimg1 from './assets/images/blogimg1.jpg'
import blogimg2 from './assets/images/blogimg2.jpg'
import blogimg3 from './assets/images/blogimg3.jpg'
import blogimg4 from './assets/images/blogimg4.jpg'
import blogimg5 from './assets/images/blogimg5.jpg'
import blogimg6 from './assets/images/blogimg6.jpg'

import {BiComment} from "react-icons/bi";
import {FiThumbsUp} from "react-icons/fi"

const Blog = () => {
  const [data, setData] = useState(
      [
    {
      img: blogimg1,
      title: 'The Future of Work for Americans',
      date: ' November 23, 2018',
      text: 'There’s no denying that the landscape of work is changing. More and more companies are adopting',
      Comments: 3,
      like: 30,
    },
    {
      img: blogimg2,
      title: 'How to Find a Business Development Job',
      date: ' November 23, 2018',
      text: 'Business development professionals are at the heart of all sorts of organizations, from startups to',
      Comments: 3,
      like: 30,
    },
    {
      img: blogimg3,
      title: 'Searching for Job When You Have No Time',
      date: ' November 23, 2018',
      text: 'You’re committed to your job search, and you use every ounce of your free time you have to comb',
      Comments: 3,
      like: 30,
    },
    {
      img: blogimg4,
      title: 'Organize Your Workspace with These Tips',
      date: ' November 23, 2018',
      text: 'What’s the hardest working location in your house? If you’re like many workers, it’s your office',
      Comments: 3,
      like: 30,
    },
    {
      img: blogimg5,
      title: 'Finding a Remote Editing Job to Satisfy Your Needs',
      date: ' November 23, 2018',
      text: 'In this post, we’d like to focus on members who found remote editing jobs, allowing them to work',
      Comments: 3,
      like: 30,
    },
    {
      img: blogimg6,
      title: 'Online Teaching: Theory and Basics',
      date: ' November 23, 2018',
      text: 'Have you been considering an online teacher job but don’t know exactly how to get started? A great first',
      Comments: 3,
      like: 30,
    },
  ]
  )
  return (
    <>
      <section className="section breadcrumbs-custom breadcrumbs-custom-overlay-3">
        <div id="Blogbg" className="breadcrumbs-custom-main bg-image bg-gray-700">
          <div className="container">
            <h3 className="breadcrumbs-custom-title">Blog</h3>
          </div>
        </div>
      </section>

      <div className="container blog_wrapper ">
        <div className="row ">
          {data && data.length !== 0 ? (
            data.map((val, ind) => {
              return (
                <div className="col-sm-6 col-lg-4 mt-3 mb-3">
                  <Card key={ind}>
                    <Card.Img variant="top" src={val.img} />
                    <Card.Body>
                      <Card.Title className="cardBtnlink">{val.title}</Card.Title>

                      <Card.Text className=" cardDate">{val.date}</Card.Text>

                      <Card.Text className="text-secondary">{val.text}</Card.Text>

                      <div className="row">
                        <div className="col-7 d-flex align-items-center ">
                          <Card.Link href="#" className="cardBtnlink">
                            <BiComment size={17} color='#52be71'/> {val.Comments} Comments
                          </Card.Link>
                        </div>
                        <div className="col-5  d-flex align-items-center ">
                          <Card.Link href="#" className="cardBtnlink">
                          <FiThumbsUp className='mb-1' size={17} color='#52be71'/> {val.like} Likes
                          </Card.Link>
                        </div>
                      </div>
                     
                    </Card.Body>
                  </Card>
                </div>
              )
            })
          ) : (
            <h1>data not found!</h1>
          )}
        </div>
      </div>
    </>
  )
}

export default Blog
