import React, { useEffect, useState } from 'react'
import { Buffer } from 'buffer'
import { useDispatch, useSelector } from 'react-redux'
import { getJobAction } from '../store/actions/jobsAction'
import { getProfileAction } from '../store/actions/profileAction'
import FormInput from './formFields/FormInput'
import { COMPANY_DETAIL_FIELDS, JOB_FIELDS } from './helper/inputConstants'
import { qualificationToSelect } from './helper/parse'
import { Link } from 'react-router-dom'
import { getQualificationsAction } from '../store/actions/qualificationsAction'
import { formatDate } from './helper/getDateAndFormat'
import { deleteJobAdByIdCall } from './network/axios'
import { useNavigate } from 'react-router-dom'
import { errorHandler } from './errorHandler/errorHandler'
import { POST_JOB_ERROR, POST_JOB_SUCCESS, UPDATE_JOB_ERROR, UPDATE_JOB_SUCCESS } from '../store/types'
import { SUCCESS_NOTIFIER, toastNotifier, ERROR_NOTIFIER } from './toaster/Toaste'
import { ProcessLoader } from './loaders/ProcessLoader'

import { FaRegMoneyBillAlt } from 'react-icons/fa'
import { IoLocationSharp } from 'react-icons/io5'
import { BiTimeFive } from 'react-icons/bi'
import { BsGlobe } from 'react-icons/bs'


export const JobDetailsById = ({ jobId }) => {
  const [loading, setLoading] = useState(true)
  const [jobAdData, setJobAdData] = useState(null)
  const [jobDetails, setJobDetails] = useState(JOB_FIELDS.filter(e => e.name !== 'SalaryRange.to'))

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { auth, getApplicationsByJobId, getJob, getQualifications } = useSelector(state => state)

  useEffect(() => {
    if (jobId) dispatch(getJobAction({ id: jobId }))
    dispatch(getProfileAction())
  }, [jobId])

  useEffect(() => {
    if (
      getQualifications?.getQualificationsLoading &&
      getQualifications?.getQualificationsSuccess?.length === 0
    ) {
      dispatch(getQualificationsAction())
    }

    if (!getJob.getJobLoading) {
      setLoading(false)
      setJobAdData(getJob?.getJobSuccess?.JobAd)
    }
  }, [getJob])

  const getQualification = qualificationId => {
    if (qualificationId) {
      if (getQualifications?.getQualificationsSuccess) {
        const findInd = getQualifications.getQualificationsSuccess.find(
          e => e._id === qualificationId
        )
        return findInd?.Qualification ? findInd?.Qualification : 'an error occurred!'
      } else {
        return 'an error occurred!'
      }
    } else {
      return 'an error occurred!'
    }
  }




  const deleteJobHandle = () => {
    deleteJobAdByIdCall({params:{ id: jobId }}).then((success) => {
      if (success?.data?.message?.[0].toLowerCase() === 'success') {
        navigate('/jobs')
        toastNotifier({ type: SUCCESS_NOTIFIER, message: 'Job deleted successfully!' })
      }
      else {
        toastNotifier({ type: ERROR_NOTIFIER, message: success?.data?.message[0] })
      }
    })
      .catch((error) => {
        if (error) {
          errorHandler({ error })
          dispatch({ type: UPDATE_JOB_ERROR, payload: error.message })
        }
        else {
          toastNotifier({ type: ERROR_NOTIFIER, message: 'An error occurred' })
        }
      })
  }

  return (
    <>
      {!jobAdData ? (
        // <div>Job details loading...</div>
        <ProcessLoader height='60' />
      ) : (
        <>
          {/* <section className="section breadcrumbs-custom breadcrumbs-custom-overlay-2">
            <div id="postbg" className="breadcrumbs-custom-main bg-image bg-gray-700">
              <div className="container">
                <h3 className="breadcrumbs-custom-title">Post a Job</h3>
              </div>
            </div>
          </section> */}
          {/* <form className="rd-mailform" id="create-job-form" onSubmit={(e)=>handleSubmit(e)} /> */}
          <div className='container'>
            <div className='col-12 '>
              <div className='d-flex justify-content-end align-items-center flex-wrap'>



                <div className='d-flex align-items-center'>
                  <div className='me-3'>
                    <button className='button delete-job-button' onClick={deleteJobHandle} > Delete Job</button>

                  </div>
                  <div>
                    <Link to={`/job-update/${jobId}`} className='button button-primary'>
                      Edit Job
                    </Link>
                  </div>

                </div>
              </div>
            </div>


            <div>



              <div>
                <div className='mb-4 mt-3 col-md-12 col-sm-12 col-xs-12 col-lg-12 col-xl-12 d-lg-flex d-block justify-content-between'>
                  <h3>{jobAdData?.VacancyTitle}</h3>


                  {auth.userRole === 'employer' && <div className='d-flex align-items-center justify-content-end'>

                    {jobAdData?.isSuspended && <div className='d-flex align-items-center my-2'>
                      <div className='rounded-pill border border-2 border-warning px-3 text-warning' >Suspended</div>
                    </div>}

                    {jobAdData?.isDeleted && <div className='ms-3 d-flex align-items-center my-2'>
                      <div className='rounded-pill border border-2 border-danger px-3 text-danger' >Deleted</div>
                    </div>}

                  </div>}
                </div>
                {/* <div className='layout-details'> */}

                <div className='col-md-12 col-sm-12 col-xs-12 col-lg-12 col-xl-12'>
                  <article className='company-light company-light_1'>

                    {jobAdData?.Logo ? <figure className='company-light-logo'>
                      <img
                        className='company-light-logo-image'
                        src={`data:jpg;base64,${Buffer.from(
                          jobAdData?.Logo?.data ? jobAdData?.Logo?.data : ''
                        ).toString('base64')}`}
                        alt=''
                      />
                    </figure> : <figure className='company-light-logo'>
                      <img
                        className='company-light-logo-image'
                        src=''
                        alt=''
                      />
                    </figure>}

                    <div className='company-light-main'>
                      <h5 className='company-light-title'>
                        {jobAdData?.CompanyDetails ? jobAdData?.CompanyDetails?.CompanyName : 'N/A'}
                      </h5>
                      <div className='company-light-info'>
                        <div className='row row-15 row-bordered my-0'>
                          <div className='col-md-6 col-sm-12 col-xs-12 col-lg-6 col-xl-6'>
                            <ul className='list list-xs list-unstyled'>
                              <li>
                                <p className='object-inline object-inline_sm'>
                                  <span className='icon icon-1 text-success me-2 mdi mdi-map-marker'>
                                    <IoLocationSharp fontSize={17} color='#52be71' />
                                  </span>
                                  <span className='text-muted' style={{ fontSize: '14px' }}>
                                    {jobAdData?.Location ? jobAdData?.Location : 'not available'}
                                  </span>
                                </p>
                              </li>
                              <li>
                                <p className='object-inline object-inline_sm'>
                                  <span className='icon icon-default text-success me-2 mdi mdi-clock'>
                                    <BiTimeFive fontSize={17} color='#52be71' />
                                  </span>
                                  <span className='text-muted' style={{ fontSize: '14px' }}>
                                    Closing Date: {formatDate(jobAdData.ClosingDate)}
                                  </span>
                                </p>
                              </li>
                              <li className='d-md-none d-block'>
                                <p className='object-inline object-inline_sm'>
                                  <span className='icon icon-sm text-success me-2 mdi mdi-cash' >
                                    <FaRegMoneyBillAlt fontSize={17} color='#52be71' />
                                  </span>
                                  <span className='text-muted' style={{ fontSize: '14px' }}>
                                    Salary:{' '}
                                    <span style={{ fontSize: '14px' }}>
                                      {` 
                                      ${jobAdData?.SalaryRange?.From}${jobAdData?.SalaryRange?.Currency} - ${jobAdData?.SalaryRange?.To}${jobAdData?.SalaryRange?.Currency}`}
                                    </span>
                                  </span>
                                </p>
                              </li>
                              <li className='d-md-none d-block'>
                                <p className='object-inline object-inline_sm'>
                                  <span className='icon icon-1 text-success me-2 mdi mdi-web'>
                                    <BsGlobe fontSize={17} color='#52be71' />
                                  </span>
                                  <span className='text-muted' style={{ fontSize: '14px' }}>
                                    {jobAdData?.CompanyDetails && jobAdData?.CompanyDetails?.Website ? <a href={`${jobAdData?.CompanyDetails?.Website}`}>
                                      {jobAdData?.CompanyDetails?.Website}
                                    </a> : 'N/A'}
                                  </span>
                                </p>
                              </li>
                            </ul>
                          </div>
                          <div className='col-md-6 col-sm-12 col-xs-12 col-lg-6 col-xl-6 d-none d-md-block border-start'>
                            <ul className='list list-xs list-unstyled mb-0'>
                              <li>
                                <p className='object-inline object-inline_sm'>
                                  <span className='icon icon-sm text-success me-2 mdi mdi-cash' >
                                    <FaRegMoneyBillAlt fontSize={17} color='#52be71' />
                                  </span>
                                  <span className='text-muted' style={{ fontSize: '14px' }}>
                                    Salary:{' '}
                                    <span style={{ fontSize: '14px' }}>
                                      {` 
                                      ${jobAdData?.SalaryRange?.From}${jobAdData?.SalaryRange?.Currency} - ${jobAdData?.SalaryRange?.To}${jobAdData?.SalaryRange?.Currency}`}
                                    </span>
                                  </span>
                                </p>
                              </li>
                              <li>
                                <p className='object-inline object-inline_sm'>
                                  <span className='icon icon-1 text-success me-2 mdi mdi-web'>
                                    <BsGlobe fontSize={17} color='#52be71' />
                                  </span>
                                  <span className='text-muted' style={{ fontSize: '14px' }} >

                                    {jobAdData?.CompanyDetails && jobAdData?.CompanyDetails?.Website ? <a target='_blank' href={`${jobAdData?.CompanyDetails?.Website}`}>
                                      {jobAdData?.CompanyDetails?.Website} </a> : 'N/A'}
                                  </span>

                                </p>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </article>
                </div>


                {auth.userRole === 'candidate' && (
                  <button
                    disabled={
                      applicationInProgress ||
                      postApplications.postApplicationsLoading ||
                      job.isApplied
                    }
                    className='button button-lg button-primary-outline button-anorak mb-5'
                    onClick={() => handleApply(job._id)}
                  >
                    {postApplications.postApplicationsLoading
                      ? 'Loading...'
                      : job.isApplied
                        ? 'Already Applied'
                        : 'Apply'}
                  </button>
                )}
              </div>


            </div>







            <div className='col-md-12 col-sm-12 col-xs-12 col-lg-12 col-xl-12'>
              <hr />
            </div>

            <div className='row'>


              <div className='col-md-6 col-sm-12 col-xs-6 col-lg-6 col-xl-6 my-2'>
                <div className='form-wrap'>
                  <div ><span className="text-muted">Category:</span> <span style={{ color: '#000000' }}>{jobAdData.VacancyCategory}</span></div>
                </div>
              </div>


              <div className='col-md-6 col-sm-12 col-xs-6 col-lg-6 my-2'>
                <div className='form-wrap'>
                  <div style={{ color: '#151515' }}  > <span className="text-muted">Type:</span> <span style={{ color: '#000000' }}>{jobAdData.VacancyType}</span> </div>
                </div>
              </div>

              <div className='col-md-6 col-sm-12 col-xs-6 col-lg-6 col-xl-6 my-2'>
                <div className='form-wrap'>
                  <div ><span className="text-muted">Preferable Qualification:</span> <span style={{ color: '#000000' }}>{getQualification(jobAdData.PreferableQualification)}</span></div>
                </div>
              </div>


              <div className='col-md-6 col-sm-12 col-xs-6 col-lg-6 my-2'>
                <div className='form-wrap'>
                  <div style={{ color: '#151515' }}  > <span className="text-muted">Post Date:</span> <span style={{ color: '#000000' }}>{formatDate(jobAdData.createdAt)}</span> </div>
                </div>
              </div>


              <div className='col-md-12 col-sm-12 col-xs-12 col-lg-12 mt-2'>
                <div className='form-wrap'>
                  {/* <div style={{ color: '#151515' }}  className="text-muted">Description</div> */}
                  <div style={{ color: '#000000' }}>
                    <p className='text-style-1'>{jobAdData.JobDescription}</p>
                  </div>
                </div>
              </div>
            </div>













            <div><div className='mt-2' >
              <h4>Company Contact Person Details</h4>
              <hr />
              {jobAdData.SetDefaultCompanyDetails ? <div className='row'>
                <div className='col-md-6 col-sm-12 col-xs-6 col-lg-6 col-xl-6 my-1'>
                  <div className='form-wrap'>
                    <div ><span className="text-muted">Company Name:</span> <span style={{ color: '#000000' }}>{jobAdData.CompanyDetails.OrganizationName}</span></div>
                  </div>
                </div>


                <div className='col-md-6 col-sm-12 col-xs-6 col-lg-6 my-1'>
                  <div className='form-wrap'>
                    <div style={{ color: '#151515' }}  > <span className="text-muted">Person Name:</span> <span style={{ color: '#000000' }}>{`${jobAdData?.CompanyDetails?.contactPerson?.FirstName} ${jobAdData?.CompanyDetails?.contactPerson?.LastName}`}</span> </div>
                  </div>
                </div>

                <div className='col-md-6 col-sm-12 col-xs-6 col-lg-6 col-xl-6 my-1'>
                  <div className='form-wrap'>
                    <div ><span className="text-muted">Phone Number:</span> <span style={{ color: '#000000' }}>{jobAdData?.CompanyDetails?.contactPerson?.PhoneNumber}</span></div>
                  </div>
                </div>


                <div className='col-md-6 col-sm-12 col-xs-6 col-lg-6 my-1'>
                  <div className='form-wrap'>
                    <div style={{ color: '#151515' }}  > <span className="text-muted">Email:</span> <span style={{ color: '#000000' }}>{jobAdData?.CompanyDetails?.contactPerson?.Email}</span> </div>
                  </div>
                </div>
                
              </div> : <div className='row'>
                <div className='col-md-6 col-sm-12 col-xs-6 col-lg-6 col-xl-6 my-1'>
                  <div className='form-wrap'>
                    <div ><span className="text-muted">Company Name:</span> <span style={{ color: '#000000' }}>{jobAdData.CompanyDetails.CompanyName}</span></div>
                  </div>
                </div>


                <div className='col-md-6 col-sm-12 col-xs-6 col-lg-6 my-1'>
                  <div className='form-wrap'>
                    <div style={{ color: '#151515' }}  > <span className="text-muted">Person Name:</span> <span style={{ color: '#000000' }}>{`${jobAdData?.CompanyDetails?.ContactPersonName}`}</span> </div>
                  </div>
                </div>

                <div className='col-md-6 col-sm-12 col-xs-6 col-lg-6 col-xl-6 my-1'>
                  <div className='form-wrap'>
                    <div ><span className="text-muted">Phone Number:</span> <span style={{ color: '#000000' }}>{jobAdData?.CompanyDetails?.ContactPersonFirstPhoneNumber}</span></div>
                  </div>
                </div>


                <div className='col-md-6 col-sm-12 col-xs-6 col-lg-6 my-1'>
                  <div className='form-wrap'>
                    <div style={{ color: '#151515' }}  > <span className="text-muted">Email:</span> <span style={{ color: '#000000' }}>{jobAdData?.CompanyDetails?.ContactPersonFirstEmail}</span> </div>
                  </div>
                </div>

              </div>}
            </div>

            </div>
            {/* ) : null
            } */}
            {/* Add Files */}
            {/* <div className="block-form">
                <h4>Add Files</h4>
                <hr />
                <span className={logo?.name ? 'file-preview' : ''}>
                  <p>{logo?.name ? logo.name : ''}</p>
                </span>
                <label
                  className="button button-sm button-primary-outline button-icon button-icon-left"
                  htmlFor="Logo"
                >
                  <input
                    type="file"
                    id="Logo"
                    name="Logo"
                    form="create-job-form"
                    onChange={(e) => setLogo(e.target.files[0])}
                    required={!getJob?.getJobSuccess?.JobAd?.Logo?.type}
                  />
                  <span className="icon mdi mdi-account-box" />
                  Add Your logo*
                </label>
               
              </div>

              <div className="block-form">
                <hr />
                {loading ? <button disabled form="create-job-form" className="button button-muted">
                  {jobId ? 'Updating Job...' : 'Posting Job...'}
                </button> : <button type="submit" form="create-job-form" className="button button-secondary">
                  {jobId ? 'Update Job' : 'Post Job'}
                </button>}
              </div> */}
          </div>
        </>
      )}
    </>
  )
}
