export const minRangeDate = () => {
    const today = new Date()
today.setDate(today.getDate() + 1)
const month = (today.getMonth()+1).toString()
const formatDate = `${today.getFullYear()}-${month.length === 1 ? '0'+month:month }-${today.getDate()}`
    return formatDate
}


export const formatDate =(inputDate) =>{
    const date = new Date(inputDate)
const month = (date.getMonth()+1).toString()
const formatDate = `${date.getDate()}-${month.length === 1 ? '0'+month:month }-${date.getFullYear()}`
    return formatDate
}