import { Buffer } from 'buffer'
import { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import { getResumeAction } from '../store/actions/resumeAction'
import { getProfileAction } from '../store/actions/profileAction'
import { getSkillsAction } from '../store/actions/skillsActions'
import { getMyResumeFileCall, getResumeFileByApplicationIdCall, getResumeFileByCandidateIdCall } from './network/axios'
import { errorHandler } from './errorHandler/errorHandler'
import { getCandidateByIdAction } from '../store/actions/candidate'
import {
  getApplicationsByIdAction,
  updateApplicationsAction,
} from '../store/actions/applicationsAction'
import { ProcessLoader } from './loaders/ProcessLoader'

export default function ResumePage () {
  const { candidateId, jobId, applicationId } = useParams()
  const dispatch = useDispatch()
  const { auth, getResume, getSkills, getProfile, getApplicationsByJobId } = useSelector(
    state => state
  )

  const [profileImage, setProfileImage] = useState()


  const openInNewTab = (url) => {
    
    let win = window.open();
    win.document.write('<iframe src="' + url  + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>')
  }

  const handleDwonloadResume = async () => {
      if (auth.userRole === 'employer') {
        getResumeFileByCandidateIdCall({ params: { id: candidateId } }).then((res)=>{
          const buffer = res.data?.Resume?.data
          const base64 = Buffer.from(buffer).toString('base64')
          const resumeLink = 'data:application/pdf;base64,' + base64
          openInNewTab(resumeLink)
        }).catch ((error) => {
          errorHandler({ error })
        })
      } else {
        getMyResumeFileCall().then((res)=>{
          const buffer = res.data?.Resume?.data
          const base64 = Buffer.from(buffer).toString('base64')
          const resumeLink = 'data:application/pdf;base64,' + base64
          openInNewTab(resumeLink)
        }).catch ((error) => {
          errorHandler({ error })
        })
      }

     
     

      // window.open('data:application/pdf;base64,' + base64)
      
     
    
  }

  const handleApplicationStatusChange = (value, id) => {
    dispatch(updateApplicationsAction({ Status: { Now: value } }, id, jobId))
  }

  useEffect(() => {
    if (auth.userRole === 'candidate') {
      dispatch(getResumeAction())
      dispatch(getProfileAction())
    }

    if (auth.userRole === 'employer') {
      if (applicationId) {
        dispatch(getApplicationsByIdAction({ id: applicationId }))
      } else if (auth.subscriptionType === 'premium') {
        dispatch(getCandidateByIdAction({ id: candidateId }))
      }
    }
    dispatch(getSkillsAction())
  }, [dispatch, auth.userRole])



  useEffect(
    () =>
      getResume.getResumeSuccess.Resume?.ImageURL?.data &&
      setProfileImage(
        Buffer.from(getResume.getResumeSuccess.Resume.ImageURL.data).toString('base64')
      ),
    [getResume]
  )


  return (
    <div>
      <section
        id='resumebg'
        className='section parallax-container section-sm bg-gray-700 bg-overlay-4'
      >

        <div className='parallax-content'>
          <div className='container'>
            {getResume.getResumeLoading ? (
              'Loading resume'
              
            ) : (
              <div className='layout-2'>
                <div className='layout-2-item layout-2-item-main'>
                  {auth?.userRole === 'candidate' || !getApplicationsByJobId?.getApplicationsByJobIdSuccess?.JobApplication ?<article className='profile-light'>
                    {profileImage && (
                      <img
                        className='profile-light-image'
                        src={`data:jpg;base64,${profileImage}`}
                        alt=''
                      />
                    )}

                     <div className='profile-light-main'>
                      <h4 className='profile-light-name'>
                        {getProfile.getProfileSuccess?.Profile?.FirstName}{' '}
                        {getProfile.getProfileSuccess?.Profile?.LastName}
                      </h4>
                      <h6 className='profile-light-position'>
                        {getResume.getResumeSuccess.Resume?.GeneralInformation?.Profession ? getResume.getResumeSuccess.Resume?.GeneralInformation?.Profession : 'Profession: N/A'}
                      </h6>
                      <div className='profile-light-divider' />
                      <ul className='ps-0'>
                        <li>
                          <span>
                            {getResume.getResumeSuccess.Resume?.GeneralInformation?.Gender ? getResume.getResumeSuccess.Resume?.GeneralInformation?.Gender : 'Gender: N/A'}
                          </span>
                        </li>
                        <li>
                          <span>{getResume.getResumeSuccess.Resume?.GeneralInformation?.City ? getResume.getResumeSuccess.Resume?.GeneralInformation?.City : 'City: N/A'}</span>
                        </li>
                        <li>
                          <span>{getResume.getResumeSuccess.Resume?.GeneralInformation?.CNIC ?  getResume.getResumeSuccess.Resume?.GeneralInformation?.CNIC : 'CNIC: N/A'}</span>
                        </li>
                      </ul>
                      {!candidateId && (
                        <Link className='profile-light-position' to='/submit-resume'>
                          Update Resume
                        </Link>
                      )}
                    </div> </article> : <article >
                      <div className='d-flex'>
                      {profileImage && (
                     <div className='me-4'>
                        <img
                        className='profile-light-image'
                        src={`data:jpg;base64,${profileImage}`}
                        alt=''
                      />
                     </div>
                    )} {getApplicationsByJobId?.getApplicationsByJobIdSuccess?.JobApplication?.map((element,ind)=>element._id === applicationId && (<div key={ind} className='profile-light-main'>
                      <h4 className='profile-light-name'>
                        {element?.Candidate[0]?.FirstName}{' '}
                        {element?.Candidate[0]?.LastName}
                      </h4>
                      <h6 className='profile-light-position'>
                        {getResume.getResumeSuccess.Resume?.GeneralInformation?.Profession ? getResume.getResumeSuccess.Resume?.GeneralInformation?.Profession : 'Profession: N/A' }
                      </h6>
                      <div className='profile-light-divider' />
                      <ul className='ps-0'>
                        <li>
                          <span>
                            {getResume.getResumeSuccess.Resume?.GeneralInformation?.Gender ? getResume.getResumeSuccess.Resume?.GeneralInformation?.Gender : 'Gender: N/A'}
                          </span>
                        </li>
                        <li>
                          <span>{getResume.getResumeSuccess.Resume?.GeneralInformation?.City ? getResume.getResumeSuccess.Resume?.GeneralInformation?.City: "City: N/A"}</span>
                        </li>
                        <li>
                          <span>{getResume.getResumeSuccess.Resume?.GeneralInformation?.CNIC ? getResume.getResumeSuccess.Resume?.GeneralInformation?.CNIC : 'CNIC: N/A'}</span>
                        </li>
                      </ul>
                    </div>))
}
                      </div>
                    
                  </article>}
                </div>
                <div className='layout-2-item text-center text-lg-left'>
                  <div>
                    <div className='group group-xl group-middle'>
                    <div>
                      {!getApplicationsByJobId.getApplicationsLoading ? (
                        <div>
                          {!getResume?.getResumeLoading && applicationId && (
                            <div>
                              {auth?.subscriptionType === 'premium' && (
                                <div>
                                  {getApplicationsByJobId?.getApplicationsByJobIdSuccess?.JobApplication?.map(
                                    (element,ind) => (
                                      <select
                                      key={ind}
                                        className='badge'
                                        defaultValue={
                                          element.Status?.Now
                                        }
                                        onChange={e => {
                                          handleApplicationStatusChange(
                                            e.target.value,
                                            element._id
                                          )
                                        }}
                                      >
                                        <option value='short-list'>Short List</option>
                                        <option value='best-fit'>Best Fit</option>
                                        <option value='not-fit'>Not Fit</option>
                                        <option value='pending'>Pending</option>
                                        <option value='rejected'>Reject</option>
                                      </select>
                                    )
                                  )}
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      ) : null}
                    </div>
                      {getResume.getResumeSuccess.Resume?.ResumeURL && (
                        <button
                          onClick={handleDwonloadResume}
                          className='button button-lg button-primary-outline button-anorak'
                        >
                          Download CV
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
      <section className='section section-md'>
        <div className='container'>
          <div className='row row-50'>
            <div className='col-lg-12'>
              {getResume.getResumeLoading ? (
                'Loading resume'
              ) : (
                <div className='row row-50'>
                  <div className='col-12'>
                    <p className='heading-9'>About me</p>
                    <hr />
                    <ul className='ps-0'>
                      <li>
                        <span>
                          CNIC: {getResume.getResumeSuccess.Resume?.GeneralInformation?.CNIC ? getResume.getResumeSuccess.Resume?.GeneralInformation?.CNIC : 'N/A'}
                        </span>
                      </li>
                      <li>
                        <span>
                          Religion:{' '}
                          {getResume.getResumeSuccess.Resume?.GeneralInformation?.Religion ? getResume.getResumeSuccess.Resume?.GeneralInformation?.Religion : 'N/A'}
                        </span>
                      </li>
                      <li>
                        <span>
                          Address:{' '}
                          {
                            getResume.getResumeSuccess.Resume?.GeneralInformation
                              ?.communicationAddress ? getResume.getResumeSuccess.Resume?.GeneralInformation
                              ?.communicationAddress : 'N/A'
                          }
                        </span>
                      </li>
                      <li>
                        <span>
                          City: {getResume.getResumeSuccess.Resume?.GeneralInformation?.City ? getResume.getResumeSuccess.Resume?.GeneralInformation?.City : 'N/A'}
                        </span>
                      </li>
                    </ul>
                  </div>

                  {/* skills */}
                  <div className='col-12'>
                    <p className='heading-9'>Professional skills</p>
                    <hr />
                    <div className='d-flex  align-items-center gap-3'>
                      {getResume?.getResumeSuccess?.Resume?.GeneralInformation?.Skills?.length > 0 ? getResume.getResumeSuccess?.Resume?.GeneralInformation?.Skills?.map(
                        (skill, index) => (
                          <div key={index}>
                            <p className='rounded-pill border px-3 py-1 border-success text-success'>
                              {
                                getSkills.getSkillsSuccess.keySkills?.filter(
                                  item => skill.skill === item._id && item.KeySkill
                                )[0]?.KeySkill
                              }
                            </p>
                          </div>
                        )
                      ) : (
                        <p>N/A</p>
                      )} 
                    </div>
                  </div>

                  {/* Experience */}
                  <div className='col-12'>
                    <p className='heading-9'>Work Experience</p>
                    <hr />
                    <div className='timeline-classic'>
                      {getResume.getResumeSuccess.Resume?.Experience?.length > 0 ? (
                        getResume.getResumeSuccess.Resume?.Experience?.map((experience, index) => (
                          <div key={index} className='timeline-classic-item'>
                            <div className='timeline-classic-period'>
                              <span>
                                {experience?.StartDate?.substring(0, 4)}-
                                {experience?.EndDate?.substring(0, 4)}
                              </span>
                            </div>
                            <div className='timeline-classic-main'>
                              <h5 className='timeline-classic-title'>{experience?.JobTitle}</h5>
                              <p>{experience?.Notes}</p>
                            </div>
                          </div>
                        ))
                      ) : (
                        <p>N/A</p>
                      )}
                    </div>
                  </div>

                  {/* Education */}
                  <div className='col-12'>
                    <p className='heading-9'>Education</p>
                    <hr />
                    <div className='timeline-classic'>
                      {getResume.getResumeSuccess.Resume?.Education.length > 0 ? (
                        getResume.getResumeSuccess.Resume?.Education?.map((education, index) => {
                          return <div key={index} className='timeline-classic-item'>
                            <div className='timeline-classic-period'>
                              <span>
                                {education?.StartDate?.substring(0, 4)}-
                                {education?.EndDate?.substring(0, 4)}
                              </span>
                            </div>
                            <div className='timeline-classic-main'>
                              <h5 className='timeline-classic-title'>{education?.SchoolName}</h5>
                              <p>{education?.Notes}</p>
                            </div>
                          </div>
                        })
                      ) : (
                        <p>N/A</p>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
