import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getSkillsAction } from '../store/actions/skillsActions'
import { getQualificationsAction } from '../store/actions/qualificationsAction'
import { getResumeAction, updateResumeAction } from '../store/actions/resumeAction'
import { cities } from './helper/citiesList'

export default function SubmitResume() {
  const dispatch = useDispatch()
  const { getResume, getSkills, getQualifications, updateResume } = useSelector((state) => state)

  const [CNIC, setCNIC] = useState('')
  const [City, setCity] = useState('')
  const [Gender, setGender] = useState('')
  const [Profession, setProfession] = useState('')
  const [Religion, setReligion] = useState('')
  const [Relocation, setRelocation] = useState('')
  const [Skills, setSkills] = useState([])
  const [communicationAddress, setcommunicationAddress] = useState('')
  const [Education, setEducation] = useState([])
  const [Experience, setExperience] = useState([])
  const [ImageURL, setImageURL] = useState('')
  const [ResumeURL, setResumeURL] = useState()

  const EductionObject = {
    SchoolName: '',
    Qualification: '',
    StartDate: '',
    EndDate: '',
    Notes: '',
  }

  const ExperienceObject = {
    Employer: '',
    JobTitle: '',
    StartDate: '',
    EndDate: '',
    Notes: '',
  }

  const setArray = (e, i, array, setField, field) => {
    const update = array.map((item, index) => {
      if (index === i) item[field] = e.target.value
      return item
    })
    setField(update)
  }

  const toFormData = ((f) => f(f))((h) => (f) => f((x) => h(h)(f)(x)))(
    (f) => (fd) => (pk) => (d) => {
      if (d instanceof Object) {
        Object.keys(d).forEach((k) => {
          const v = d[k]
          if (pk) k = `${pk}[${k}]`
          if (v instanceof Object && !(v instanceof Date) && !(v instanceof File))
            return f(fd)(k)(v)

          fd.append(k, v)
        })
      }
      return fd
    }
  )(new FormData())()

  const handleSubmit = (e) => {
    const formDataObj = {}
    e.preventDefault()
    if (!getResume.getResumeLoading) {
      formDataObj.GeneralInformation = {
        CNIC,
        City,
        Gender,
        Profession,
        Religion,
        Relocation,
        Skills,
        communicationAddress,
      }

      formDataObj.Education = [...Education]
      formDataObj.Experience = [...Experience]
      if (ImageURL) formDataObj.ImageURL = ImageURL
      if (ResumeURL) formDataObj.ResumeURL = ResumeURL

      dispatch(updateResumeAction(toFormData(formDataObj)))
    }
  }

  useEffect(() => {
    dispatch(getResumeAction())
    dispatch(getQualificationsAction())
    dispatch(getSkillsAction())
  }, [dispatch])

  useEffect(() => {
    if (!getResume.getResumeLoading) {
      setCNIC(getResume.getResumeSuccess.Resume.GeneralInformation?.CNIC)
      setCity(getResume.getResumeSuccess.Resume.GeneralInformation?.City)
      setGender(getResume.getResumeSuccess.Resume.GeneralInformation?.Gender)
      setProfession(getResume.getResumeSuccess.Resume.GeneralInformation?.Profession)
      setReligion(getResume.getResumeSuccess.Resume.GeneralInformation?.Religion)
      setRelocation(getResume.getResumeSuccess.Resume.GeneralInformation?.Relocation)
      setcommunicationAddress(
        getResume.getResumeSuccess.Resume.GeneralInformation?.communicationAddress
      )
      setSkills(
        getResume.getResumeSuccess.Resume.GeneralInformation?.Skills
          ? getResume.getResumeSuccess.Resume.GeneralInformation?.Skills
          : []
      )
      setEducation(
        getResume.getResumeSuccess.Resume?.Education
          ? getResume.getResumeSuccess.Resume?.Education
          : []
      )
      setExperience(
        getResume.getResumeSuccess.Resume?.Experience
          ? getResume.getResumeSuccess.Resume?.Experience
          : []
      )
    }
  }, [getResume])

  return (
    <div>
      <section className="section breadcrumbs-custom breadcrumbs-custom-overlay-2">
        <div className="breadcrumbs-custom-main bg-image bg-gray-700">
          <div className="container">
            <h3 className="breadcrumbs-custom-title">Submit Resume</h3>
          </div>
        </div>
      </section>
      <section className="section section-md">
        <div className="container">
          {/* General Information */}
          <form className="rd-mailform" id="submit-resume-form" onSubmit={handleSubmit} />
          <div className="block-form">
            <h4>General Information</h4>
            <hr />
            <div className="rd-form rd-mailform form-lg">
              <div className="row row-40">
                <div className="col-md-6">
                  <div className="form-wrap">
                    <label className="form-label-outside">CNIC*</label>
                    <div className="form-wrap-inner">
                      <input
                        className="form-input"
                        form="submit-resume-form"
                        type="text"
                        required
                        name="CNIC"
                        value={CNIC}
                        onChange={(e) => setCNIC(e.target.value)}
                      />
                      <p className="small">Ex: 12345-1234567-1</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-wrap">
                    <label className="form-label-outside">City*</label>
                    <div className="form-wrap-inner">
                      <select
                        className="form-input"
                        required
                        name="City"
                        value={City}
                        onChange={(e) => setCity(e.target.value)}
                      >
                        <option value="" selected disabled>
                          select City
                        </option>
                        {cities.map((e)=><option value={e.value}>{e.label}</option>)}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-wrap">
                    <label className="form-label-outside">Religion*</label>
                    <div className="form-wrap-inner">
                      <select
                        className="form-input"
                        form="submit-resume-form"
                        type="text"
                        required
                        name="Religion"
                        value={Religion}
                        onChange={(e) => setReligion(e.target.value)}
                      >
                        <option disabled value="">
                          Select Religion
                        </option>
                        <option value="muslim">Muslim</option>
                        <option value="non-muslim">Non Muslim</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-wrap">
                    <label className="form-label-outside">Relocation*</label>
                    <div className="form-wrap-inner">
                      <select
                        className="form-input"
                        form="submit-resume-form"
                        type="text"
                        required
                        name="Relocation"
                        value={Relocation}
                        onChange={(e) => setRelocation(e.target.value)}
                      >
                        <option disabled value="">
                          Select Value
                        </option>
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-wrap">
                    <label className="form-label-outside">Profession*</label>
                    <div className="form-wrap-inner">
                      <input
                        className="form-input"
                        form="submit-resume-form"
                        type="text"
                        required
                        name="Profession"
                        value={Profession}
                        onChange={(e) => setProfession(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-wrap">
                    <label className="form-label-outside">Gender*</label>
                    <div className="form-wrap-inner">
                      <select
                        className="form-input"
                        form="submit-resume-form"
                        type="text"
                        required
                        name="Gender"
                        value={Gender}
                        onChange={(e) => setGender(e.target.value)}
                      >
                        <option disabled value="">
                          Select Gender
                        </option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="Other">Other</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-wrap">
                    <label className="form-label-outside">Communication Address*</label>
                    <div className="form-wrap-inner">
                      <input
                        className="form-input"
                        form="submit-resume-form"
                        type="text"
                        required
                        name="communicationAddress"
                        value={communicationAddress}
                        onChange={(e) => setcommunicationAddress(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* General Information Skills */}
          <div className="block-form">
            <h4>Skills</h4>
            <hr />
            <div className="rd-form rd-mailform form-lg">
              <div className="row row-40">
                {Skills?.map((skill, index) => (
                  <div key={index} className="col-md-6">
                    <div className="form-wrap">
                      <label className="form-label-outside">Skill</label>
                      <div
                        className="form-wrap-inner d-grid"
                        style={{ gridTemplateColumns: 'auto 50px' }}
                      >
                        <input
                          className="form-input"
                          form="submit-resume-form"
                          type="text"
                          required
                          name="skill"
                          defaultValue={
                            getSkills.getSkillsSuccess.keySkills?.filter(
                              (item) => skill.skill === item._id && item.KeySkill
                            )[0]?.KeySkill
                          }
                        />
                        <button
                          className="border-0 button-secondary"
                          onClick={() => setSkills(Skills.filter((item, i) => i !== index && item))}
                        >
                          -
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
                <div className="col-md-12">
                  <div className="form-wrap">
                    <label className="form-label-outside">Add Skill</label>
                    <div className="form-wrap-inner">
                      <select
                        className="form-input"
                        form="submit-resume-form"
                        type="text"
                        required
                        name="skill"
                        defaultValue="selected"
                        onChange={(e) => {
                          setSkills([...Skills, { skill: e.target.value }])
                          e.target.value = 'selected'
                        }}
                      >
                        <option value="selected" disabled>
                          Select skill to add
                        </option>
                        {getSkills.getSkillsSuccess.keySkills?.map((skills, index) => (
                          <option key={index} value={skills._id}>
                            {skills.KeySkill}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Education */}
          <div className="block-form">
            <h4>Education</h4>
            <hr />
            {Education.map((education, index) => (
              <div key={index} className="rd-form rd-mailform form-lg form-corporate">
                <div className="form-wrap">
                  <label className="form-label-outside">School Name*</label>
                  <div className="form-wrap-inner">
                    <input
                      className="form-input"
                      form="submit-resume-form"
                      type="text"
                      required
                      name={`SchoolName-${index}`}
                      value={education?.SchoolName}
                      onChange={(e) => setArray(e, index, Education, setEducation, 'SchoolName')}
                    />
                  </div>
                </div>
                <div className="form-wrap">
                  <label className="form-label-outside">Qualification*</label>
                  <div className="form-wrap-inner">
                    <select
                      className="form-input"
                      form="submit-resume-form"
                      type=""
                      required
                      name={`Qualification-${index}`}
                      value={education?.Qualification}
                      onChange={(e) => setArray(e, index, Education, setEducation, 'Qualification')}
                    >
                      {getQualifications.getQualificationsSuccess?.map((qualification, index) => (
                        <option
                          key={index}
                          value={qualification._id}
                          disabled={qualification.disabled}
                        >
                          {qualification.Qualification}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="form-wrap">
                  <label className="form-label-outside">StartDate*</label>
                  <div className="form-wrap-inner">
                    <input
                      className="form-input"
                      form="submit-resume-form"
                      type="date"
                      required
                      name={`StartDate-${index}`}
                      value={education?.StartDate?.split('T')[0]}
                      onChange={(e) => setArray(e, index, Education, setEducation, 'StartDate')}
                    />
                  </div>
                </div>
                <div className="form-wrap">
                  <label className="form-label-outside">EndDate*</label>
                  <div className="form-wrap-inner">
                    <input
                      className="form-input"
                      form="submit-resume-form"
                      type="date"
                      required
                      name={`EndDate-${index}`}
                      value={education?.EndDate?.split('T')[0]}
                      onChange={(e) => setArray(e, index, Education, setEducation, 'EndDate')}
                    />
                  </div>
                </div>
                <div className="form-wrap">
                  <label className="form-label-outside">Notes (optional)</label>
                  <div className="form-wrap-inner">
                    <textarea
                      className="form-input"
                      form="submit-resume-form"
                      name={`Notes-${index}`}
                      value={education?.Notes}
                      onChange={(e) => setArray(e, index, Education, setEducation, 'Notes')}
                    />
                  </div>
                </div>
                <button
                  className="button button-sm button-secondary"
                  onClick={() => {
                    setEducation(Education.filter((item, i) => i !== index && item))
                  }}
                >
                  <span className="icon mdi mdi-plus" />
                  Remove Education
                </button>
              </div>
            ))}
            <button
              className="button button-sm button-primary"
              onClick={() => {
                setEducation([...Education, EductionObject])
              }}
            >
              <span className="icon mdi mdi-plus" />
              Add Education
            </button>
          </div>

          {/* Experience */}
          <div className="block-form">
            <h4>Experience</h4>
            <hr />
            {Experience.map((experience, index) => (
              <div key={index} className="rd-form rd-mailform form-lg form-corporate">
                <div className="form-wrap">
                  <label className="form-label-outside">Employer*</label>
                  <div className="form-wrap-inner">
                    <input
                      className="form-input"
                      form="submit-resume-form"
                      type="text"
                      name={`Employer-${index}`}
                      required
                      value={experience?.Employer}
                      onChange={(e) => setArray(e, index, Experience, setExperience, 'Employer')}
                    />
                  </div>
                </div>
                <div className="form-wrap">
                  <label className="form-label-outside">Job Title*</label>
                  <div className="form-wrap-inner">
                    <input
                      className="form-input"
                      form="submit-resume-form"
                      type="text"
                      name={`JobTitle-${index}`}
                      required
                      value={experience?.JobTitle}
                      onChange={(e) => setArray(e, index, Experience, setExperience, 'JobTitle')}
                    />
                  </div>
                </div>
                <div className="form-wrap">
                  <label className="form-label-outside">Start Date*</label>
                  <div className="form-wrap-inner">
                    <input
                      className="form-input"
                      form="submit-resume-form"
                      type="date"
                      name={`StartDate-${index}`}
                      required
                      value={experience?.StartDate?.split('T')[0]}
                      onChange={(e) => setArray(e, index, Experience, setExperience, 'StartDate')}
                    />
                  </div>
                </div>
                <div className="form-wrap">
                  <label className="form-label-outside">End Date*</label>
                  <div className="form-wrap-inner">
                    <input
                      className="form-input"
                      form="submit-resume-form"
                      type="date"
                      name={`EndDate-${index}`}
                      required
                      value={experience?.EndDate?.split('T')[0]}
                      onChange={(e) => setArray(e, index, Experience, setExperience, 'EndDate')}
                    />
                  </div>
                </div>
                <div className="form-wrap">
                  <label className="form-label-outside">Notes (optional)</label>
                  <div className="form-wrap-inner">
                    <textarea
                      className="form-input"
                      form="submit-resume-form"
                      name={`Notes-${index}`}
                      value={experience?.Notes}
                      onChange={(e) => setArray(e, index, Experience, setExperience, 'Notes')}
                    />
                  </div>
                </div>
                <button
                  className="button button-sm button-secondary"
                  onClick={() => {
                    setExperience(Experience.filter((item, i) => i !== index && item))
                  }}
                >
                  <span className="icon mdi mdi-plus" />
                  Remove experience
                </button>
              </div>
            ))}
            <button
              className="button button-sm button-primary"
              onClick={() => setExperience([...Experience, ExperienceObject])}
            >
              <span className="icon mdi mdi-plus" />
              Add experience
            </button>
          </div>

          {/* Add Files */}
          <div className="block-form">
            <h4>Add Files</h4>
            <hr />
            <div className="group">
              <label className="button button-sm button-primary-outline button-icon button-icon-left">
                <input
                  type="file"
                  name="photo"
                  form="submit-resume-form"
                  onChange={(e) => setImageURL(e.target.files[0])}
                />
                <span className="icon mdi mdi-account-box" />
                Add Your Photo
              </label>
              <label className="button button-sm button-primary button-icon button-icon-left">
                <input
                  type="file"
                  name="resume-file"
                  form="submit-resume-form"
                  onChange={(e) => setResumeURL(e.target.files[0])}
                />
                <span className="icon mdi mdi-attachment" />
                Add Resume File
              </label>
            </div>

            <div className="group">
              <label className={ImageURL?.name? 'button button-sm button-primary-outline button-icon button-icon-left' : ""}>
      
                <p>{ImageURL?.name ? ImageURL.name : ''}</p>
              
               </label>
             

              <label className={ResumeURL?.name? 'button button-sm button-primary button-icon button-icon-left' : ''}>
                <p>{ResumeURL?.name ? ResumeURL.name : ''}</p>
              </label>
            </div>
            <hr />
            <button
              className={`button button-lg ${updateResume.updateResumeLoading ? 'button-muted':'button-secondary'}`}
              type="submit"
              form="submit-resume-form"
              disabled={updateResume.updateResumeLoading}
            >
              {updateResume.updateResumeLoading ? 'Loading...' : 'Submit Resume'}
            </button>
          </div>
        </div>
      </section>
    </div>
  )
}
