import * as types from '../types'

const initialState = {
  accessToken: null,
  refreshToken: null,
  isAuthenticated: false,
  subscriptionType: '',
  userRole: '',
  isLoading: false,
  user: null,
}

export const authReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.AUTH_LOADING_START:
      return {
        ...state,
        isLoading: true,
      }
    case types.AUTH_LOADING_STOP:
      return {
        ...state,
        isLoading: false,
      }
    case types.SET_ACCESS_TOKEN:
      return {
        ...state,
        accessToken: payload.accessToken,
        refreshToken: payload.refreshToken,
        userRole: payload.userRole,
        subscriptionType: payload.subscriptionType,
        isAuthenticated: true,
        isLoading: false,
      }
    case types.AUTH_LOGOUT_SUCCESS:
      return {
        ...state,
        accessToken: payload.accessToken,
        refreshToken: payload.refreshToken,
        userRole: payload.userRole,
        isAuthenticated: false,
        isLoading: false,
      }
    default:
      return {
        ...state,
      }
  }
}
