import { useState } from "react"
import { minRangeDate } from "../helper/getDateAndFormat"
import { ContactPersonEmailField } from "./ContactPersonEmailField"

export default function FormInput({ field, form, onChange, showLable = true, minRange }) {

  if (field.type === 'select') {
    if (form === 'Register-Employer' || field.profile) {
      if (field.name === 'SubscriptionPeriod') {
        if (!field.typeBasic) {
          return (
            <>
              {showLable && (
                <label className="form-label-outside mb-2" htmlFor={field.name}>
                  {field.label}
                </label>
              )}
              <select
                name={field.name}
                id={field.id || field.name}
                disabled={field.readOnly || field.name === 'subscriptionType' && !field.readOnly && field.profile}

                value={field.value || field?.options?.[0]?.value}
                form={form}
                className={`${field.readOnly ? 'border-0 w-100' : 'form-input select selectMenu'} `}
                onChange={onChange}
                required={field.required}
              >
                {field.options.map((option, index) => (
                  <option
                    key={index}
                    value={option.value}
                    disabled={(option.disabled && option.disabled) || false}
                  >
                    {option.label}
                  </option>
                ))}
              </select>
              {field.hint && <div>Hint: {field.hint}</div>}
            </>
          )
        }
        else {
          return (
            <>
              {showLable && (
                <label className="form-label-outside mb-2 text-muted">
                  Subscription Validity
                </label>
              )}
              <select
                defaultValue='Select Subscription Validity'
                disabled
                className={`${field.readOnly ? 'border-0 w-100' : 'form-input select selectMenu'}`}
              >
                <option
                  value='Select Subscription Validity'
                >
                  Select Subscription Validity</option>
              </select>
            </>
          )
        }
      }
      else {
        return (
          <>
            {showLable && (
              <label className="form-label-outside mb-2" htmlFor={field.name}>
                {field.label}
              </label>
            )}
            <select
              name={field.name}
              id={field.id || field.name}
              disabled={field.readOnly || field.name === 'subscriptionType' && !field.readOnly && field.profile}

              value={field.value || field?.options?.[0]?.value}
              form={form}
              className={`${field.readOnly ? 'border-0 w-100' : 'form-input select selectMenu'} `}
              onChange={onChange}
              required={field.required}
            >
              {field.options.map((option, index) => (
                <option
                  key={index}
                  value={option.value}
                  disabled={(option.disabled && option.disabled) || false}
                >
                  {option.label}
                </option>
              ))}
            </select>
            {field.hint && <div>Hint: {field.hint}</div>}
          </>
        )
      }
    }

    else if (form === 'submit-profile-form') {
      if (field.name === 'subscriptionType') {
        if (field.value === 'basic') {
          return (
            <>
              {showLable && (
                <label className="form-label-outside mb-2 text-muted">
                  Subscription Validity
                </label>
              )}
              <select
                defaultValue='Not Applicable'
                disabled
                className={`${field.readOnly ? 'border-0 w-100' : 'form-input select selectMenu text-muted'}`}
              >
                <option
                  value='Not Applicable'
                >Not Applicable</option>
              </select>
            </>
          )

        }

      }
    }







    else {
      return (
        <>
          {showLable && (
            <label className="form-label-outside mb-2" htmlFor={field.name}>
              {field.label}
            </label>
          )}
          <select
            name={field.name}
            id={field.id || field.name}
            disabled={field.readOnly || field.name === 'subscriptionType' && !field.readOnly && field.profile}

            value={field.value || field?.options?.[0]?.value}
            form={form}
            className={`${field.readOnly ? 'border-0 w-100' : 'form-input select selectMenu'} `}
            onChange={onChange}
            required={field.required}
          >
            {field.options.map((option, index) => (
              <option
                key={index}
                value={option.value}
                disabled={(option.disabled && option.disabled) || false}
              >
                {option.label}
              </option>
            ))}
          </select>
          {field.hint && <div>Hint: {field.hint}</div>}
        </>
      )

    }
  }
  if (field.type === 'textarea') {
    return (
      <>
        <label className="form-label-outside mb-2" htmlFor={field.name}>
          {field.label}
        </label>
        <textarea
          className="form-input"
          type={field.type}
          form={form}
          name={field.name}
          id={field.id || field.name}
          required={field.required}
          value={field.value}
          placeholder={field.placeholder}
          onChange={onChange}
        />
        {field.hint && <div>Hint: {field.hint}</div>}
      </>
    )
  }
  if (field.name === 'contactPerson.Email' && !field.readOnly && !field.profile) {
    return (

      <ContactPersonEmailField allFields={{ field, form, onChange, showLable: true }} />

    )
  }

  if (field.name !== 'UseOrganizationEmail') {
    if(field.name === 'contactPerson.Email'){
      return (
        <>
          {showLable && (
            <label className="form-label-outside mb-2" htmlFor={field.name}>
              {field.label}
            </label>
          )}
          <input
            min={field.type === 'date' ? minRangeDate() : field.label === 'Salary Range To*' ? minRange : ''}
            //  min={formatDate}
            className={`${field.readOnly ? 'border-0 w-100' : 'form-input'}`}
            type={field.type}
            form={form}
            disabled={true}
            name={field.name}
            id={field.id || field.name}
            readOnly={field.readOnly}
            // required={field.required || !field.readOnly && field.profile ? false : true}
            value={field.value}
            placeholder={field.placeholder}
            onChange={onChange}
          // disabled={field.label === 'Salary Range To*' && minRange === null ? true : false }
          />
          {field.hint && <div>Hint: {field.hint}</div>}
        </>
      )
    }
    else{
      return (
        <>
          {showLable && (
            <label className="form-label-outside mb-2" htmlFor={field.name}>
              {field.label}
            </label>
          )}
          <input
            min={field.type === 'date' ? minRangeDate() : field.label === 'Salary Range To*' ? minRange : ''}
            //  min={formatDate}
            className={`${field.readOnly ? 'border-0 w-100' : 'form-input'}`}
            type={field.type}
            form={form}
            disabled={field.name === 'Username' && !field.readOnly && field.profile}
            name={field.name}
            id={field.id || field.name}
            readOnly={field.readOnly}
            required={field.required || !field.readOnly && field.profile ? false : true}
            value={field.value}
            placeholder={field.placeholder}
            onChange={onChange}
          // disabled={field.label === 'Salary Range To*' && minRange === null ? true : false }
          />
          {field.hint && <div>Hint: {field.hint}</div>}
        </>
      )
    }
    
  }
}



