import { checkTokenExpiration } from './authAction'
import { getJobAddCall, getJobsAddCall } from '../../component/network/axios'
import { errorHandler } from '../../component/errorHandler/errorHandler'
import {
  GET_JOBS_LOADING,
  GET_JOBS_SUCCESS,
  GET_JOBS_ERROR,
  GET_JOB_LOADING,
  GET_JOB_SUCCESS,
  GET_JOB_ERROR,
} from '../types'
import { isNoData, JOB } from '../../component/utility'

export const getJobAction = (formData) => {
  return async (dispatch) => {
    dispatch({ type: GET_JOB_LOADING })
    try {
      const data = await getJobAddCall({ params: formData })
      dispatch({ type: GET_JOB_SUCCESS, payload: data.data })
    } catch (error) {
      errorHandler({ error })
      dispatch({ type: GET_JOB_ERROR, payload: error })

      checkTokenExpiration({ dispatch, parentFunction: getJobAction, error })
    }
  }
}

export const getJobsAction = (params) => {
  return async (dispatch) => {
    dispatch({ type: GET_JOBS_LOADING })
    try {
      const data = await getJobsAddCall({ params })
      isNoData(data, JOB)
      dispatch({ type: GET_JOBS_SUCCESS, payload: data.data })
    } catch (error) {
      errorHandler({ error })
      dispatch({ type: GET_JOBS_ERROR, payload: error })

      checkTokenExpiration({ dispatch, parentFunction: getJobsAction, error })
    }
  }
}
