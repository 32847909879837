import react, { useState } from 'react'

const PrivacyPolicy = () => {
  const [data, setData] = useState([
    {
      heading: 'Privacy Policy',
      text: [
        <>
          We provide an online platform for connecting the candidates (job seekers) and employers
          through our job portal site at{' '}
          <a href=" https://www.apex-financecareers.com"> https://www.apex-financecareers.com</a>{' '}
          and any other related websites, applications or other distribution channels we may, from
          time to time, operate (collectively, the "Services"). Your privacy and protection of
          information you share with us while using our Services is very important to us.
          <br />
          <br />
          This "Privacy Policy" explains what information we gather from our users and how we use
          it. By using or accessing our Services, you are accepting the practices described in this
          Privacy Policy. (Capitalized terms not defined herein have the meaning set forth in our
          Terms of Service).
        </>,
      ],
    },

    {
      heading: 'What information does Apexway gather?',
      text: [
        <>
          Apexway gathers and stores three types of information about users that are subject to our
          Privacy Policy:
        </>,
      ],
    },

    {
      heading: 'Information users provide to us:',
      text: [
        <>
          These are voluntary submissions made when creating an account on Apexway or through your
          use of the Services, such as name and email address provided during registration and form
          submissions, or payment information provided during purchases. Please understand that when
          you sign into Apexway or post a job, your information is not anonymous to us.
        </>,
      ],
    },

    {
      heading: 'Information we collect when users interact with Apexway:',
      text: [
        <>
          We keep track of user interactions with our Services, such as visiting a web page,
          clicking a button, and choosing a form. Also, when users access our Services, we may
          collect information about their access method (such as hardware and software attributes),
          location, IP address, and pages they visit. In addition, we store certain information from
          their browser using “cookies”. (For more on cookies, please see the section "What are
          cookies?")
        </>,
      ],
    },

    {
      heading: 'Information we receive from third parties',
      text: [
        <>
          We do not own or operate any third-party services offered that you may use or interact
          with through our Services (collectively, “Third Party Services”). Whenever you visit, use
          or interact with a Third Party Service, we may receive information from them, including
          information about actions you take
        </>,
      ],
    },

    {
      heading: 'Why does Apexway gather information about me? ',
      text: [
        <>
          Apexway collects information in order to improve the content and quality of our Services.
          Any personally identifiable information we collect (including email addresses, physical
          addresses or phone numbers) is not shared with or sold to other organizations for
          commercial purposes, except to provide products or services you've requested, when we have
          your permission, or under special circumstances outlined in this Privacy Policy.
        </>,
      ],
    },

    {
      heading: 'What are cookies? ',
      text: [
        <>
          Apexway may place a text file called a "cookie" in the browser files of your computer.
          These cookies help us make Apexway easier to use, to make our advertising better, and to
          protect both you and Apexway. For example, a cookie may be used to store or "remember"
          your Member login information (but not your password) so that you are not required to
          manually log into the site at every visit. You can remove or block cookies using the
          settings in your browser, but in some cases that may impact your ability to use some of
          our Services
        </>,
      ],
    },

    {
      heading: 'How secure is my information? ',
      text: [
        <>
          Apexway uses commercially reasonable physical, electronic, and procedural measures to
          safeguard any information in our possession against loss, theft and unauthorized use,
          disclosure or modification. We limit access to personal information about you to employees
          whom we believe reasonably need that information to provide support, products, or services
          to you or to fulfill their roles within our organization
          <br />
          <br />
          Although we have established and maintain security procedures to protect your personally
          identifiable information, please be aware that no security measures are perfect or
          impenetrable. You can reduce the risk of unauthorized access to your information by using
          common sense security practices such as choosing a strong password, using different
          passwords for different services, and using up-to-date antivirus software.
          <br />
          <br />
          Please report any security violations to us on this automated form.
        </>,
      ],
    },

    {
      heading: 'What control do I have over my information? ',
      text: [
        <>
          As a registered Member, you may modify or update your personal account information at any
          time by contacting our support team. Should you desire to do so, you may also delete and
          close your Apexway account at any time by contacting our support team. Note that removed
          or deleted information may persist in backup copies for a reasonable period of time.
        </>,
      ],
    },

    {
      heading: 'How is information shared? ',
      text: [
        <>
          Apexway will not share your personally identifiable information with others except as
          described herein and in limited circumstances where we believe such sharing is reasonably
          necessary to offer the Services, legally required, or permitted by you. For example, we
          may provide information to Service Providers to help us bring you the services we offer.
          <br />
          <br />
          Where we utilize third parties for the processing of any personal information, we
          implement reasonable contractual and technical protections limiting the use of that
          information to the Apexway-specified purposes. We may store personal information in
          locations outside the direct control of Apexway (for instance, on servers or databases
          co-located with hosting providers).
          <br />
          <br />
          Except as otherwise described in this Privacy Policy, we will not disclose personal
          information to any third party unless we believe that disclosure is necessary: (1) to
          conform to legal requirements or to respond to a subpoena, search warrant or other legal
          process received by Apexway, whether or not a response is required by applicable law; (2)
          to enforce the Apexway Terms of Service or to protect our rights; or (3) to protect the
          safety or rights of members of the public or users of the Services. Apexway reserves the
          right to transfer personal information to a successor in interest that acquires rights to
          that information as a result of the sale of Apexway or substantially all of its assets to
          that successor in interest. We may also transfer such information in the course of
          corporate divestitures, mergers, or dissolution.
        </>,
      ],
    },

    {
      heading: 'How am I notified of changes to this privacy policy? ',
      text: [
        <>
          We may change this Privacy Policy pursuant to the procedures outlined in our Terms of
          Service. Unless stated otherwise, our current Privacy Policy applies to all information we
          have about you and your account. If we make changes to this Privacy Policy we will notify
          you by publication here
        </>,
      ],
    },

    {
      heading: 'Who can I contact about this privacy policy?',
      text: [
        <>
         To submit an inquiry about our Privacy Policy, please contact our support team. 
        </>,
      ],
    },
  ])
  return (
    <>
      <section className="section breadcrumbs-custom breadcrumbs-custom-overlay-3">
        <div id="Privacybg" className="breadcrumbs-custom-main bg-image bg-gray-700">
          <div className="container">
            <h3 className="breadcrumbs-custom-title">Privacy Policy</h3>
          </div>
        </div>
      </section>

      <section className="container">
        <div className="row mt-5">
          <div className="col-lg-9 col-xl-8">
            {data && data.length !== 0
              ? data.map((val, ind) => {
                  return (
                    <>
                      <h4 className="mt-5">{val.heading}</h4>
                      <p className="mt-3">{val.text}</p>
                    </>
                  )
                })
              : null}
          </div>
        </div>
      </section>
    </>
  )
}

export default PrivacyPolicy
