import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import FormInput from './formFields/FormInput'
import { updatePasswordAction } from '../store/actions/updatePasswordAction'
import { getProfileAction, updateProfileAction } from '../store/actions/profileAction'
import {
  CANDIDATE_PROFILE_FIELDS,
  CHANGE_PASSWORD_FIELDS,
  EMPLOYER_PROFILE_FIELDS,
} from './helper/inputConstants'
import showPasswordIcon from './assets/images/show-password-icon.svg'

export default function Profile() {
  const dispatch = useDispatch()
  const emplProfile = EMPLOYER_PROFILE_FIELDS
  const candProfile = CANDIDATE_PROFILE_FIELDS
  const [myProfile, setMyProfile] = useState([])
  const [readOnly, setReadOnly] = useState(true)
  const [showPassword, setShowPassword] = useState(false)
  const [changePassword, setChangePassword] = useState(CHANGE_PASSWORD_FIELDS)
  const { getProfile, updateProfile, updatePassword } = useSelector((state) => state)

  const setInitialData = () => {
    if (getProfile.getProfileSuccess?.Profile) {
      setMyProfile(
        candProfile.map((field) => {
          return {
            ...field,
            value: getProfile.getProfileSuccess?.Profile[field.name],
          }
        })
      )
    } else if (getProfile.getProfileSuccess?.Employer) {
      setMyProfile(
        emplProfile.map((field) => {
          return {
            ...field,
            value:
              getProfile.getProfileSuccess?.Employer &&
              getProfile.getProfileSuccess?.Employer[field.name]
                ? getProfile.getProfileSuccess?.Employer[field.name]
                : getProfile.getProfileSuccess?.Employer?.contactPerson[field.name.split('.')[1]],
          }
        })
      )
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const formData = {}
    myProfile.filter((item) => (formData[item.name] = item.value))
    dispatch(updateProfileAction(formData))
  }

  const handleSubmitChangePassword = (e) => {
    e.preventDefault()
    const formData = {}
    changePassword.filter((item) => (formData[item.name] = item.value))
    dispatch(updatePasswordAction(formData))
  }

  useEffect(() => dispatch(getProfileAction()), [dispatch])
  useEffect(() => !getProfile.getProfileLoading && setInitialData(), [getProfile, readOnly])

  const handleForgetPasswordInputChange = (e) => {
    setMyProfile([
      ...myProfile.map((item) =>
        item.name === e.target.name ? { ...item, value: e.target.value } : item
      ),
    ])
  }

  return (
    <div>
      <section className="section breadcrumbs-custom breadcrumbs-custom-overlay-2">
        <div className="breadcrumbs-custom-main bg-image bg-gray-700">
          <div className="container">
            <h3 className="breadcrumbs-custom-title">Submit Profile</h3>
          </div>
        </div>
      </section>
      <section className="section section-md">
        <div className="container">
          <form className="rd-mailform" id="submit-profile-form" onSubmit={handleSubmit} />
          <div>
            <h4>General Information</h4>
            <div className="form-check form-switch mt-2">
              <label className="form-check-label">
                <input
                  onChange={() => setReadOnly(!readOnly)}
                  className="form-check-input mt-1"
                  type="checkbox"
                />
                Enable edit mode
              </label>
            </div>
            <hr />
            <div className="rd-form rd-mailform form-lg">
              <div className="row row-40">
                {myProfile?.map((field, index) => (
                  <div key={index} className="col-md-6">
                    <div className="form-wrap">
                      {field && <div className="form-wrap-inner">
                        {FormInput({
                          field: { ...field, readOnly,profile:true },
                          form: 'submit-profile-form',
                          onChange: handleForgetPasswordInputChange,
                        })}
                      </div>}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="block-form">
            <button
              className="button button-primary"
              form="submit-profile-form"
              disabled={updateProfile.updateProfileLoading || readOnly}
            >
              {updateProfile.updateProfileLoading ? 'Loading...' : 'Submit Profile'}
            </button>
          </div>

          <form
            className="rd-mailform"
            id="SubmitChangePassword"
            onSubmit={handleSubmitChangePassword}
          />
          <div className="block-form">
            <h4>Change Password</h4>
            <hr />
            <div className="rd-form rd-mailform form-lg">
              <div className="row row-40">
                {changePassword.map((field, index) => (
                  <div key={index} className="col-md-6">
                    <div className="form-wrap">
                      <label className="form-label-outside" htmlFor={field.name}>
                        {field.label}
                      </label>
                      <div className="position-relative">
                        <input
                          className="form-input"
                          form="SubmitChangePassword"
                          type={showPassword ? 'text' : field.type}
                          name={field.name}
                          value={field.value}
                          required={field.required}
                          onChange={(e) =>
                            setChangePassword([
                              ...changePassword.map((item) =>
                                item.name === field.name ? { ...item, value: e.target.value } : item
                              ),
                            ])
                          }
                        />
                        <button
                          onClick={() => setShowPassword(!showPassword)}
                          className="position-absolute top-0 end-0 bottom-0 border-0 bg-transparent "
                          type="button"
                        >
                          <img width={20} src={showPasswordIcon} alt="" />
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="block-form">
            <button
              className="button button-primary"
              form="SubmitChangePassword"
              disabled={updatePassword.updatePasswordLoading}
            >
              {updatePassword.updatePasswordLoading ? 'loading...' : 'Change Password'}
            </button>
          </div>
        </div>
      </section>
    </div>
  )
}
