import { GET_SKILLS_LOADING, GET_SKILLS_SUCCESS, GET_SKILLS_ERROR } from '../types'

const getInitialState = {
  getSkillsLoading: true,
  getSkillsSuccess: [],
  getSkillsError: '',
}

export const getSkillsReducer = (state = getInitialState, { type, payload }) => {
  switch (type) {
    case GET_SKILLS_LOADING:
      return {
        ...state,
        getSkillsLoading: true,
      }
    case GET_SKILLS_SUCCESS:
      return {
        ...state,
        getSkillsLoading: false,
        getSkillsSuccess: payload,
      }
    case GET_SKILLS_ERROR:
      return {
        ...state,
        getSkillsLoading: false,
        getSkillsError: payload,
      }

    default:
      return state
  }
}
