import { checkTokenExpiration } from './authAction'
import { errorHandler } from '../../component/errorHandler/errorHandler'
import {
  GET_QUALIFICATIONS_LOADING,
  GET_QUALIFICATIONS_SUCCESS,
  GET_QUALIFICATIONS_ERROR,
} from '../types'
import { getQualificationsCall } from '../../component/network/axios'

export const getQualificationsAction = () => {
  return async (dispatch) => {
    dispatch({ type: GET_QUALIFICATIONS_LOADING })
    try {
      const data = await getQualificationsCall()
      dispatch({
        type: GET_QUALIFICATIONS_SUCCESS,
        payload: [
          { _id: '', Qualification: 'Select Qualification', disabled: true },
          ...data.data.Qualifications,
        ],
      })
    } catch (error) {
      errorHandler({ error })
      dispatch({ type: GET_QUALIFICATIONS_ERROR, payload: error })

      checkTokenExpiration({
        dispatch,
        parentFunction: getQualificationsAction,
        error,
      })
    }
  }
}
