import { Buffer } from 'buffer'
import { Link } from 'react-router-dom'
import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import SideFilters from '../SideFilters'
import Pagination from '../Pagination'
import SearchFilter from '../SearchFilter'
import { getJobsAction } from '../../store/actions/jobsAction'
import { getMyJobsAction } from '../../store/actions/myJobAction'
import { JOB_FILTER_FIELDS } from '../helper/inputConstants'
import { getQualificationsAction } from '../../store/actions/qualificationsAction'
import { qualificationToSelect } from '../helper/parse'
import { ProcessLoader } from '../loaders/ProcessLoader'

export default function JobListing() {
  const dispatch = useDispatch()
  const { auth, getJobs, getMyJobs, getQualifications } = useSelector((state) => state)

  const [filterList, setFilterList] = useState(JOB_FILTER_FIELDS)

  useEffect(() => {
    if (auth.userRole === 'candidate') dispatch(getJobsAction())
    if (auth.userRole === 'employer') dispatch(getMyJobsAction())

    dispatch(getQualificationsAction())
  }, [dispatch, auth.userRole])

  const filterChange = (e) => {
    const { name, value } = e.target
    setFilterList((currentFilterList) =>
      currentFilterList.map((field) => (name === field.name ? { ...field, value } : field))
    )
  }

  useEffect(() => {
    setFilterList((filter) =>
      filter.map((field) => {
        if (field.name === 'PreferableQualification') {
          return {
            ...field,
            options: qualificationToSelect(getQualifications),
          }
        }
        return field
      })
    )
  }, [getQualifications])

  const handleFilterSubmit = (e) => {
    e.preventDefault()
    const params = {}
    filterList.map((field) => {
      if (field.value) params[field.name] = field.value
    })

    if (auth.userRole === 'candidate') dispatch(getJobsAction(params))
    if (auth.userRole === 'employer') dispatch(getMyJobsAction())
  }

  const list = (job, index) => {

    return (
      <tr key={index}>
        <td className="table-job-listing-main">
          <Link
            to={
              auth.userRole === 'candidate'
                ? `/job-details/${job._id}`
                : `/job-applications/${job._id}`
            }
          >
            <article className="company-minimal">
              <figure className="company-minimal-figure">
                <img
                  className="company-minimal-image"
                  src={`data:jpg;base64,${Buffer.from(
                    job?.Logo?.data ? job?.Logo?.data : ''
                  ).toString('base64')}`}
                  alt=""
                />
              </figure>
              <div className="company-minimal-main">
                <h5 className="company-minimal-title">{job.VacancyTitle}</h5>
                <p className="text-dark">{job.Location}</p>
              </div>
            </article>
          </Link>
        </td>
        <td>
          {auth.userRole === 'employer' && <div className='d-flex align-items-center justify-content-end'>

            {job?.isSuspended && <div className='d-flex align-items-center my-2'>
              <div className='rounded-pill border border-2 border-warning px-3 text-warning' >Suspended</div>
            </div>}

            {job?.isDeleted && <div className='ms-3 d-flex align-items-center my-2'>
              <div className='rounded-pill border border-2 border-danger px-3 text-danger' >Deleted</div>
            </div>}

          </div>}
        </td>
        <td className="table-job-listing-date">
          <span>
            {job.createdAt.substring(0, 10)} - {job.ClosingDate.substring(0, 10)}
          </span>
        </td>
       
        <td className="table-job-listing-badge">
          <span className="badge">{job.VacancyType}</span>
        </td>
      </tr>
    )
  }

  const jobsList = () => {
    if (auth.userRole === 'candidate') {
      return getJobs.getJobsLoading ? (
        // <p>Loading jobs...</p>
        <ProcessLoader height='50' />
      ) : getJobs?.getJobsSuccess?.message?.[0] === 'no data found' ? (
        <h5 className="pv-5 form-label-outside text-center d-block">No job posted yet</h5>
      ) : (
        <table className="table-job-listing table-responsive">
          <tbody>{getJobs?.getJobsSuccess?.ads?.map((job, index) => list(job, index))}</tbody>
        </table>
      )
    }
    if (auth.userRole === 'employer') {
      return getMyJobs.getMyJobLoading ? (
        // <p>Loading jobs...</p>
        <ProcessLoader height='50' />
      ) : getMyJobs?.getMyJobSuccess?.message?.[0] === 'no data found' ? (
        <h5 className="pv-5 form-label-outside text-center d-block">No job posted yet</h5>
      ) : (
        <table className="table-job-listing table-responsive">
          <tbody>{getMyJobs?.getMyJobSuccess?.JobAds?.map((job, index) => list(job, index))}</tbody>
        </table>
      )
    }
  }

  return (
    <div>
      <section className="section breadcrumbs-custom breadcrumbs-custom-overlay-4">
        <div id="jobListing-bg" className="breadcrumbs-custom-main bg-image bg-gray-700">
          <div className="container">
            <h3 className="breadcrumbs-custom-title">Job Listing</h3>
          </div>
        </div>
      </section>
      <section className="section section-md">
        <div className="container">
          {/* <SearchFilter /> */}
          <div className="row row-50 flex-lg-row-reverse">
            {auth.userRole === 'candidate' && (
              <SideFilters
                filterList={filterList}
                onChange={filterChange}
                onSubmit={handleFilterSubmit}
              />
            )}
            <div className={`${auth.userRole === 'candidate' ? 'col-lg-8 col-xl-9' : 'col-12'}`}>
              <div className='sm-Table-overflow'>

                {jobsList()}
              </div>
              {/* {(!getJobs.getJobLoading || !getJobs.getMyJobLoading) && <Pagination />} */}
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
