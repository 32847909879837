import { useSelector } from 'react-redux'
import { Routes, Route } from 'react-router-dom'

import Home from '../pages/Home'
import About from '../pages/About'
import Login from '../component/login'
import SignUp from '../component/SignUp'
import PostAJob from '../component/postAJob'
import SubmitProfile from '../component/Profile'
import ResumePage from '../component/resumePage'
import Nav from '../component/header_footer/menu'
import SubmitResume from '../component/submitResume'
import JobDetails from '../component/jobs/JobDetails'
import JobListing from '../component/jobs/JobListing'
import Footer from '../component/header_footer/footer'
import ApplicationsByJobId from '../component/ApplicationsByJobId'
import CandidatesListing from '../component/CandidatesList'
import Contact from '../component/contact'
import { useEffect } from 'react'
import { checkTokenExpiration } from '../store/actions/authAction'
import { ErrorPage } from '../component/ErrorPage'
import Pricing from '../component/pricing'
import Blog from '../component/Blog'
import PrivacyPolicy from '../component/PrivacyPolicy'
import TermsOfServices from '../component/TermsOfServices'

export default function Routing() {
  const auth = useSelector((state) => state.auth)



  

  return (
    <>
      <Nav />
      <Routes>
        <Route index element={<Home />} />
        <Route path="about" element={<About />} />
        <Route path="contact" element={<Contact />} />
        <Route path='pricing' element={<Pricing />} />
        <Route path='blog' element={<Blog />} />
        <Route path='blog' element={<Blog />} />
        <Route path='privacyPolicy' element={<PrivacyPolicy />} />
        <Route path='termsofservices' element={<TermsOfServices />} />



        <Route path="*" element={<ErrorPage />} />

        <Route path="auth">
          <Route path="signup" element={<SignUp />} />
          <Route path="login" element={<Login />} />
        </Route>
        {auth.isAuthenticated && auth.userRole === 'employer' && (
          <>
            <Route path="jobs" element={<JobListing />} />
            <Route path="job-details" element={<PostAJob />} />
            <Route path="post-job" element={<PostAJob />} />
            <Route path="job-applications/:jobId" element={<ApplicationsByJobId />} />
            <Route path="job-update/:jobId" element={<PostAJob />} />
            <Route path="candidates" element={<CandidatesListing />} />
            <Route path="profile" element={<SubmitProfile />} />
            <Route path="resume/:candidateId/:jobId/:applicationId" element={<ResumePage />} />
            <Route path="resume/:candidateId" element={<ResumePage />} />
          </>
        )}
        {auth.isAuthenticated && auth.userRole === 'candidate' && (
          <>
            <Route path="jobs" element={<JobListing />} />
            <Route path="job-details/:jobId" element={<JobDetails />} />
            <Route path="resume" element={<ResumePage />} />
            <Route path="submit-resume" element={<SubmitResume />} />
            <Route path="profile" element={<SubmitProfile />} />
          </>
        )}
      </Routes>
      <Footer />
    </>
  )
}
