import { Link } from "react-router-dom";

export default function pricing() {
  return (
    <section className="section section-md" id="pricing">
      <div className="container">
        <h3 className="text-center mb-5">Pricing</h3>
        <div className="row row-50 justify-content-center">


          <div className="col-md-6 col-lg-4">
            <article className="pt pt-classic">
              <div className="pt-classic-header">
                <div className="pt-classic-item">
                  <p className="pt-title">Candidates</p>
                  {/* <span className="badge">7 Days Free</span> */}
                </div>

                <p className="pt-price d-flex justify-content-center align-items-center">
                  {/* <span className="pt-price-small pt-price-currency">&nbsp;&nbsp;</span> */}
                  <span className="text-start">FREE</span>

                  {/* <div className="ms-2">
                    <div className="pt-price-small text-start my-1" style={{ fontSize: '20px' }}>PKR</div>
                  </div> */}
                </p>



                {/* <p className="pt-price">
                  <span className="pt-price-small pt-price-currency">&nbsp;&nbsp;</span>
                  <span>00</span>
                  <span className="pt-price-small" style={{ fontSize: '20px' }}>PKR</span>
                </p> */}
                <div className="pt-price-per-annum">&nbsp;&nbsp;</div>
              </div>
              <div className="pt-classic-main heightEqual">
                <ul className="pt-classic-features ">
                  <li><b>FREE!</b></li>
                  <li>No joining, monthly or annual fee</li>
                  {/* <li>Browse Your Job Listings</li>
                    <li>Access to Job Posting Stats</li>
                    <li>Email Support</li>
                    <li>Jobs Expire In 90 Days</li> */}
                </ul>
                <Link className="button2 button button-primary-outline" to='/auth/signup'>
                  Get Started
                </Link>
              </div>
            </article>
          </div>


          <div className="col-md-6 col-lg-4">
            <article className="pt pt-classic">
              <div className="pt-classic-header">
                <div className="pt-classic-item">
                  <p className="pt-title text-tertiary">Employer – Basic</p>
                </div>

                <p className="pt-price d-flex justify-content-center align-items-center">
                  <span className="pt-price-small pt-price-currency">&nbsp;&nbsp;</span>
                  <span className="text-start">300</span>

                  <div className="ms-2">
                    <div className="pt-price-small text-start my-1" style={{ fontSize: '20px' }}>PKR</div>
                    <div className="pt-price-small text-start my-1" style={{ fontSize: '20px' }}>per month</div>
                  </div>
                </p>
                <div className="pt-price-per-annum"> <b>3,600 PKR</b> per annum</div>
              </div>
              <div className="pt-classic-main heightEqual">
                <ul className="pt-classic-features">
                  {/* <li>PKR 300 per month</li>
                    <li>PKR 3,600 annually</li> */}
                  <li>Unlimited job postings</li>
                  <li>Add posting valid for <b>14 days only</b></li>
                  <li><b>No access </b>to the candidates database</li>
                  <li>No search or filter option</li>
                </ul>
                <Link className="button2 button button-primary-outline" to='/auth/signup'>
                  Get Started
                </Link>
              </div>
            </article>
          </div>



          <div className="col-md-6 col-lg-4">
            <article className="pt pt-classic">
              <div className="pt-classic-header">
                <div className="pt-classic-item">
                  <p className="pt-title text-secondary">Employer - Premium</p>
                </div>
                <p className="pt-price d-flex justify-content-center align-items-center">
                  <span className="pt-price-small pt-price-currency">&nbsp;&nbsp;</span>
                  <span className="text-start">500</span>

                  <div className="ms-2">
                    <div className="pt-price-small text-start my-1" style={{ fontSize: '20px' }}>PKR</div>
                    <div className="pt-price-small text-start my-1" style={{ fontSize: '20px' }}>per month</div>
                  </div>
                </p>
                <div className="pt-price-per-annum"> <b>6,000 PKR</b> per annum</div>


              </div>
              <div className="pt-classic-main heightEqual">
                <ul className="pt-classic-features">
                  {/* <li>PKR 500 per month</li>
                    <li>1PKR 6,000 annually </li> */}
                  <li>Unlimited job posting</li>
                  <li><b>No restrictions</b> on add visibility days</li>
                  <li><b>Unrestricted access </b> to  the candidates database </li>
                  <li>Easy search and filter option for selecting right candidate</li>
                </ul>
                <Link className="button2 button button-primary-outline" to='/auth/signup'>
                  Get Started
                </Link>
              </div>
            </article>
          </div>
        </div>
      </div>
    </section>
  )
}
