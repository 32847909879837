import { GET_CANDIDATE_LOADING, GET_CANDIDATE_SUCCESS, GET_CANDIDATE_ERROR } from '../types'

const getCandidateReducerInitialState = {
  getCandidateLoading: true,
  getCandidateSuccess: [],
  getCandidateError: '',
}

export const getCandidateReducer = (state = getCandidateReducerInitialState, { type, payload }) => {
  switch (type) {
    case GET_CANDIDATE_LOADING:
      return {
        ...state,
        getCandidateLoading: true,
      }
    case GET_CANDIDATE_SUCCESS:
      return {
        ...state,
        getCandidateLoading: false,
        getCandidateSuccess: payload,
      }
    case GET_CANDIDATE_ERROR:
      return {
        ...state,
        getCandidateLoading: false,
        getCandidateError: payload,
      }

    default:
      return state
  }
}
