// import { Input } from 'reactstrap'
import FormInput from './formFields/FormInput'

export default function SideFilters({ filterList, onChange, onSubmit }) {
  return (
    <div className="col-lg-4 col-xl-3">
      <form id="ApplyFilter" onSubmit={onSubmit}></form>
      <div className="row row-30">
        {filterList?.map((field, index) => (
          <div key={index} className="col-sm-6 col-lg-12">
            <p className="heading-8">{field.label}</p>
            <hr />
            <ul className="list list-xs p-0">
              <li>
                {FormInput({
                  field:{...field,required:false},
                  showLable: false,
                  form: 'ApplyFilter',
                  onChange,
                })}
              </li>
            </ul>
          </div>
        ))}
        <div className="col-sm-6 col-lg-12">
          <button form="ApplyFilter" className="button button-primary w-100">
            Apply Filter
          </button>
        </div>
        {/* <div className="col-sm-6 col-lg-12">
          <p className="heading-8">Date posted</p>
          <hr />
          <ul className="list list-xs">
            <li>
              <label>
                <Input name="date-posted-checkbox-1" value="checkbox-1" type="checkbox" /> Last Hour
              </label>
            </li>
            <li>
              <label>
                <Input name="date-posted-checkbox-2" value="checkbox-2" type="checkbox" /> Last 24
                hours
              </label>
            </li>
            <li>
              <label>
                <Input name="date-posted-checkbox-3" value="checkbox-3" type="checkbox" /> Last 7
                days
              </label>
            </li>
            <li>
              <label>
                <Input name="date-posted-checkbox-4" value="checkbox-4" type="checkbox" /> Last 30
                days
              </label>
            </li>
          </ul>
        </div>
        <div className="col-sm-6 col-lg-12">
          <p className="heading-8">Vacancy Type</p>
          <hr />
          <ul className="list list-xs">
            <li>
              <label>
                <Input name="vacancy-type-checkbox-1" value="checkbox-1" type="checkbox" />{' '}
                Freelance<span className="checkbox-meta">35</span>
              </label>
            </li>
            <li>
              <label>
                <Input name="vacancy-type-checkbox-2" value="checkbox-2" type="checkbox" /> Full
                Time<span className="checkbox-meta">25</span>
              </label>
            </li>
            <li>
              <label>
                <Input name="vacancy-type-checkbox-3" value="checkbox-3" type="checkbox" /> Part
                Time<span className="checkbox-meta">55</span>
              </label>
            </li>
            <li>
              <label>
                <Input name="vacancy-type-checkbox-4" value="checkbox-4" type="checkbox" />{' '}
                Internship<span className="checkbox-meta">55</span>
              </label>
            </li>
          </ul>
        </div>
        <div className="col-sm-6 col-lg-12">
          <p className="heading-8">Speciality</p>
          <hr />
          <div className="form-wrap form-wrap-select form-filled">
            <select
              className="form-input select"
              name="speciality"
              // data-minimum-results-for-search="Infinity"
              // data-constraints="@Selected"
              // data-placeholder="Select a speciality"
              // data-dropdown-className="select2-dropdown-filled"
            >
              <option label="Select a speciality" />
              <option value="1">Web Design</option>
              <option value="2">Development</option>
              <option value="3">Marketing</option>
              <option value="4">Illustration</option>
            </select>
          </div>
          <ul className="list list-xs">
            <li>
              <label>
                <Input name="category-checkbox-1" value="checkbox-1" type="checkbox" /> Web Design
                <span className="checkbox-meta">23</span>
              </label>
            </li>
            <li>
              <label>
                <Input name="category-checkbox-2" value="checkbox-2" type="checkbox" /> Illustrator
                <span className="checkbox-meta">47</span>
              </label>
            </li>
            <li>
              <label>
                <Input name="category-checkbox-3" value="checkbox-3" type="checkbox" /> Art Director
                <span className="checkbox-meta">67</span>
              </label>
            </li>
            <li>
              <label>
                <Input name="category-checkbox-4" value="checkbox-4" type="checkbox" /> UX Design
                <span className="checkbox-meta">35</span>
              </label>
            </li>
          </ul>
        </div>
        <div className="col-sm-6 col-lg-12">
          <p className="heading-8">Filter by Salary</p>
          <hr />
          <ul className="list list-xs">
            <li>
              <label>
                <Input name="salary-checkbox-1" value="checkbox-1" type="checkbox" /> $0 - $1000
                <span className="checkbox-meta">100</span>
              </label>
            </li>
            <li>
              <label>
                <Input name="salary-checkbox-2" value="checkbox-2" type="checkbox" /> $1000 - $5000
                <span className="checkbox-meta">73</span>
              </label>
            </li>
            <li>
              <label>
                <Input name="salary-checkbox-3" value="checkbox-3" type="checkbox" /> $5000 - $20000
                <span className="checkbox-meta">55</span>
              </label>
            </li>
            <li>
              <label>
                <Input name="salary-checkbox-4" value="checkbox-4" type="checkbox" /> $2000 - $50000
                <span className="checkbox-meta">35</span>
              </label>
            </li>
            <li>
              <label>
                <Input name="salary-checkbox-5" value="checkbox-5" type="checkbox" /> $50000+
                <span className="checkbox-meta">15</span>
              </label>
            </li>
          </ul>
        </div> */}
      </div>
    </div>
  )
}
