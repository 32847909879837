export const qualificationToSelect = (Qualifications) =>
  Qualifications.getQualificationsSuccess
    ? Qualifications.getQualificationsSuccess.map((qualification) => ({
        value: qualification._id,
        label: qualification.Qualification,
        disabled: qualification.disabled,
      }))
    : []

export const skillsToSelect = (Skills) =>
  Skills.getSkillsSuccess.keySkills
    ? Skills.getSkillsSuccess.keySkills.map((skills) => ({
        value: skills._id,
        label: skills.KeySkill,
      }))
    : []
