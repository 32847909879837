import { Carousel } from 'react-bootstrap'

import 'react-responsive-carousel/lib/styles/carousel.min.css'

export default function slider() {
  return (
    <div className="slider-div" style={{ marginTop: '6rem' }}>
      <div className="container-fluid p-0">
        <div className="banner-text">
          <Carousel className="slider-mian" interval={1500}>
            <Carousel.Item>
              <div id="bg-slider-img" className="row">
                <div className="col-lg-6 col-sm-12">
                  <h1 className="main-div text-capitalize">
                    Find Your Dream Job with Pakistan’s First Ever Dedicated Finance Job Site
                  </h1>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div id="bg-slider-img2" className="row">
                <div className="col-lg-6 col-sm-12">
                  <h1 className="main-div text-capitalize">
                    Find New Talents in Accounting, Finance, Audit, & Taxation, in a Couple of
                    Clicks
                  </h1>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div id="bg-slider-img3" className="row">
                <div className="col-lg-6 col-sm-12">
                  <h1 className="main-div text-capitalize">Perfect Employment Opportunities</h1>
                </div>
              </div>
            </Carousel.Item>
          </Carousel>

{/* 
          <div className="searchdiv col-lg-6 col-sm-12">
            <form className="form-layout-search form-lg">
              <div className="form-wrap form-wrap-icon">
                <input
                  className="form-input"
                  id="form-keywords"
                  type="text"
                  name="keywords"
                  data-constraints="@Required"
                />
                <label className="form-label" htmlFor="form-keywords">
                  Keywords{' '}
                </label>
                <span className="icon fl-bigmug-line-circular224"> </span>
              </div>
              <div className="form-wrap form-wrap-icon form-wrap-select">
                <select
                  className="form-input select"
                  id="form-region"
                  data-placeholder="All Regions"
                  name="region"
                  data-minimum-results-for-search="Infinity"
                  data-constraints="@Selected"
                >
                  <option label="All Regions" />
                  <option value="1">Seattle </option>
                  <option value="2">Miami</option>
                  <option value="3">Chicago</option>
                </select>
                <span className="icon fl-bigmug-line-big104" />
              </div>
              <div className="form-wrap form-wrap-button form-wrap-button-icon-only">
                <button
                  className="button button-lg button-primary button-anorak button-icon-only"
                  type="submit"
                  aria-label="search"
                >
                  <span className="icon icon-4 fl-bigmug-line-search74" />
                </button>
              </div>
            </form>
            <p className="big">
              Or browse job offers by&nbsp;
              <a href="job-listing.html">category</a>
            </p>
          </div> */}


        </div>
      </div>
    </div>
  )
}
