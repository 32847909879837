import { checkTokenExpiration } from './authAction'
import { errorHandler } from '../../component/errorHandler/errorHandler'
import { GET_SKILLS_LOADING, GET_SKILLS_SUCCESS, GET_SKILLS_ERROR } from '../types'
import { getSkillsCall } from '../../component/network/axios'

export const getSkillsAction = () => {
  return async (dispatch) => {
    dispatch({ type: GET_SKILLS_LOADING })
    try {
      const data = await getSkillsCall()
      dispatch({ type: GET_SKILLS_SUCCESS, payload: data.data })
    } catch (error) {
      errorHandler({ error })
      dispatch({ type: GET_SKILLS_ERROR, payload: error.message })

      checkTokenExpiration(dispatch, getSkillsAction, error)
    }
  }
}
