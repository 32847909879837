import axios from 'axios'

// const baseUrl = "https://apex-career-back.herokuapp.com"
const baseUrl = process.env.REACT_APP_BASE_URL

const getUeserRole = () => localStorage.getItem('userRole')

const getHeader = () => ({
  headers: { 
    Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
})

const getRefreshToken = () => ({
  refreshToken: localStorage.getItem('refreshToken'),
})

// console.log(getHeader().headers.Authorization)
// console.log(getRefreshToken().refreshToken)

export const loginCall = ({ userRole, body }) => axios.post(`${baseUrl}/${userRole}/login`, body)

export const logoutCall = () =>
  axios.post(`${baseUrl}/${getUeserRole()}/logout`, { ...getRefreshToken() }, { ...getHeader() })

export const renewAccessTokenCall = () =>
  axios.post(`${baseUrl}/${getUeserRole()}/renewAccessToken`, { ...getRefreshToken() })

export const forgetPasswordCall = ({ body }) => axios.post(`${baseUrl}/public/forgetPassword`, body)

export const getJobAddCall = ({ params }) =>
  axios.get(`${baseUrl}/${getUeserRole()}/jobAd`, { params, ...getHeader() })

export const getJobsAddCall = ({ params }) =>
  axios.get(`${baseUrl}/${getUeserRole()}/jobAds`, { params, ...getHeader() })

export const getMyJobsAddCall = async ({ params }) =>
  axios.get(`${baseUrl}/${getUeserRole()}/myJobAds`, { params, ...getHeader() })

export const postJobsAddCall = async ({ body }) =>
  axios.post(`${baseUrl}/${getUeserRole()}/postJobAd`, body, { ...getHeader() })

export const updateJobsAddCall = async ({ body, params }) =>
  axios.put(`${baseUrl}/${getUeserRole()}/updateJob`, body, { params, ...getHeader() })

export const updatePasswordCall = async ({ body }) =>
  axios.put(`${baseUrl}/${getUeserRole()}/changePassword`, body, { ...getHeader() })

export const getJobApplicationsCall = async () =>
  axios.get(`${baseUrl}/${getUeserRole()}/getJobApplications`, { ...getHeader() })


export const getJobApplicationsByJobIdCall = async ({ params }) =>
  axios.get(`${baseUrl}/${getUeserRole()}/getJobApplicationByJobId`, { params, ...getHeader() })


export const deleteJobAdByIdCall = async ({params}) => {

  return axios.create({
    baseURL: baseUrl,
    headers: {
      ...axios.defaults.headers,
      Authorization: `Bearer ${
        localStorage.getItem('accessToken')
      }`
    }
  }).put(`${getUeserRole()}/deleteJobAd?id=${params.id}`)

}




export const postJobApplicationsCall = async ({ body }) =>
  axios.post(`${baseUrl}/${getUeserRole()}/applyOnAJob`, body, { ...getHeader() })
export const updateJobApplicationsCall = async ({ body, params }) =>
  axios.put(`${baseUrl}/${getUeserRole()}/updateJobApplication`, body, { params, ...getHeader() })
export const getJobApplicationsByIdCall = async ({ params }) =>
  axios.get(`${baseUrl}/${getUeserRole()}/getJobApplicationById`, { params, ...getHeader() })

export const getQualificationsCall = async () =>
  axios.get(`${baseUrl}/${getUeserRole()}/qualifications`, { ...getHeader() })

export const getSkillsCall = async () =>
  axios.get(`${baseUrl}/${getUeserRole()}/keySkills`, { ...getHeader() })

export const getProfileCall = async () =>
  axios.get(`${baseUrl}/${getUeserRole()}/profile`, { ...getHeader() })

export const updateProfileCall = async ({ body }) =>
  axios.put(`${baseUrl}/${getUeserRole()}/updateProfile`, body, { ...getHeader() })

export const getResumeCall = async () =>
  axios.get(`${baseUrl}/${getUeserRole()}/myResume`, { ...getHeader() })
export const getMyResumeFileCall = async () =>
  axios.get(`${baseUrl}/${getUeserRole()}/getResumeFile`, { ...getHeader() })

export const getResumeFileByApplicationIdCall = async ({ params }) =>{
  axios.get(`${baseUrl}/${getUeserRole()}/getResumeFileByJobApplicationId`, {
    params,
    ...getHeader(),
  })}

export const getResumeFileByCandidateIdCall = async ({ params }) =>{
  return axios.get(`${baseUrl}/${getUeserRole()}/getResumeFileByCandidateId`, {
    params,
    ...getHeader(),
  })}

export const updateResumeCall = async ({ body }) =>
  axios.put(`${baseUrl}/${getUeserRole()}/updateResume`, body, { ...getHeader() })

export const getCandidateByIdCall = async ({ params }) =>
  axios.get(`${baseUrl}/${getUeserRole()}/getCandidateById`, { params, ...getHeader() })
export const getCandidates = async ({ params }) =>
  axios.get(`${baseUrl}/${getUeserRole()}/candidates`, { params, ...getHeader() })
