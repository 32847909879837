import { Provider } from 'react-redux'
import { BrowserRouter, Outlet } from 'react-router-dom'

import './App.css'
import { store } from './store'
import 'react-toastify/dist/ReactToastify.css'
import Toaster from './component/toaster/Toaste'
import Routing from './routes/Routing'
import Modal from './component/moadal/Modal'

export default function App() {
  return (
    <Provider store={store}>
      <Toaster />
      <Modal />
      <Outlet />
      <BrowserRouter>
        <Routing />
      </BrowserRouter>
    </Provider>
  )
}
