import React from 'react'
import ReactDOM from 'react-dom'

import './index.css'
import App from './App'
import 'bootstrap/dist/css/bootstrap.min.css'

ReactDOM.render(<App />, document.getElementById('root'))

// https://www.templatemonsterpreview.com/demo/47127.html?_gl=1*p9ihb1*_ga*NTIyOTY2OC4xNjQwNDI1OTI3*_ga_FTPYEGT5LY*MTY0MTA0Mjg3NS40LjAuMTY0MTA0Mjg3Ny41OA..&_ga=2.14886901.2103168564.1641042877-5229668.1640425927
