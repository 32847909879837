import { Buffer } from 'buffer'
import { useEffect, useLayoutEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { getJobAction, getJobsAction } from '../../store/actions/jobsAction'
import { postApplicationsAction } from '../../store/actions/applicationsAction'
import { formatDate } from '../helper/getDateAndFormat'
import { FaRegMoneyBillAlt } from 'react-icons/fa'
import { IoLocationSharp } from 'react-icons/io5'
import { BiTimeFive } from 'react-icons/bi'
import { BsGlobe } from 'react-icons/bs'
import { getQualificationsAction } from '../../store/actions/qualificationsAction'
import { ProcessLoader } from '../loaders/ProcessLoader'
import { POST_APPLICATIONS_ERROR, POST_APPLICATIONS_SUCCESS } from '../../store/types'
import { SUCCESS_NOTIFIER, toastNotifier } from '../toaster/Toaste'
import { errorHandler } from '../errorHandler/errorHandler'
import { checkTokenExpiration } from '../../store/actions/authAction'
import { postJobApplicationsCall } from '../network/axios'

export default function JobDetails() {
  const { jobId } = useParams()
  const dispatch = useDispatch()
  const [applicationInProgress, setApplicationInProgress] = useState(false)
  const [isApplied, setIsApplied] = useState(false)

  const { postApplications, auth, getJob, getJobs, getQualifications } = useSelector(state => state)

  const handleApply = id => {

    setApplicationInProgress(true)
    postJobApplicationsCall({ body: { JobId: id }}).then((res)=>{
      dispatch({ type: POST_APPLICATIONS_SUCCESS, payload: res.data })
      toastNotifier({ type: SUCCESS_NOTIFIER, message: 'Job Applied' })
      setApplicationInProgress(false)
      setIsApplied(true)
    }).catch((error)=>{
      errorHandler({ error })
      dispatch({ type: POST_APPLICATIONS_ERROR, payload: error.message })
      setApplicationInProgress(false)
      setIsApplied(false)
      checkTokenExpiration({
        dispatch,
        parentFunction: postApplicationsAction,
        error,
      })

    })


    // dispatch(postApplicationsAction({ JobId: id }))
  }

  // console.log(postApplications.postApplicationsLoading);


  const getQualification = qualificationId => {
    if (qualificationId) {
      if (getQualifications?.getQualificationsSuccess) {
        const findInd = getQualifications.getQualificationsSuccess.find(
          e => e._id === qualificationId
        )
        return findInd?.Qualification ? findInd?.Qualification : 'an error occurred!'
      } else {
        return 'an error occurred!'
      }
    } else {
      return 'an error occurred!'
    }
  }

  useEffect(() => {
    setApplicationInProgress(false)
    setIsApplied(false)
  }, [jobId])



  useEffect(() => {


    if (
      getQualifications?.getQualificationsLoading &&
      getQualifications?.getQualificationsSuccess?.length === 0
    ) {
      dispatch(getQualificationsAction())
    }

    if (auth.userRole === 'candidate') dispatch(getJobAction({ id: jobId }))
    dispatch(getJobsAction())
    setApplicationInProgress(false)



  }, [jobId])

  return (
    <div>
      {/* <section className='section breadcrumbs-custom breadcrumbs-custom-overlay-2'>
        <div id='bg-jobdetails' className='breadcrumbs-custom-main bg-image bg-gray-700'>
          <div className='container'>
            <h3 className='breadcrumbs-custom-title'>Social Media Specialist</h3>
          </div>
        </div>
      </section> */}
      <section className='section section-md'>
        <div className='container'>
          <div className='row row-50'>
            {/* <div className='col-lg-12'> */}
            {getJob.getJobLoading ? (
              <div className='layout-details'>Loading job</div>
            ) : (
              getJob.getJobSuccess?.Job?.map((job, index) => (
                <div key={index} >
                  <div className='mb-4 col-md-12 col-sm-12 col-xs-12 col-lg-12 col-xl-12'>
                    <h3>{job.VacancyTitle}</h3>
                  </div>
                  {/* <div className='layout-details'> */}

                  <div className='col-md-12 col-sm-12 col-xs-12 col-lg-12 col-xl-12'>
                    <article className='company-light company-light_1'>

                      <figure className='company-light-logo'>
                        <img
                          className='company-light-logo-image'
                          src={`data:jpg;base64,${Buffer.from(
                            job?.Logo?.data ? job?.Logo?.data : ''
                          ).toString('base64')}`}
                          alt=''
                        />
                      </figure>
                      {/* <div className="company-light-main">
                          <h5 className="company-light-title">{job.VacancyTitle}</h5>
                          <div className="company-light-info">
                            <div className="row row-15 row-bordered">
                              <div className="col-sm-6">
                                <ul className="list list-xs">
                                  <li>
                                    <p className="object-inline object-inline_sm">
                                      <span className="icon icon-1 text-primary mdi mdi-map-marker" />
                                      <span>{job.Location}</span>
                                    </p>
                                  </li>
                                  <li>
                                    <p className="object-inline object-inline_sm">
                                      <span className="icon icon-default text-primary mdi mdi-clock" />
                                      <span>Post Date: {formatDate(job.createdAt)}</span>
                                    </p>
                                  </li>
                                </ul>
                              </div>
                              <div className="col-sm-6">
                                <ul className="list list-xs">
                                  <li>
                                    <p className="object-inline object-inline_sm">
                                      <span className="icon icon-sm text-primary mdi mdi-cash" />
                                      <span>
                                        Salary:
                                        {` 
                                      ${getJob.getJobSuccess.Job?.[0].SalaryRange?.From}${getJob.getJobSuccess.Job?.[0].SalaryRange?.Currency} - 
                                      ${getJob.getJobSuccess.Job?.[0].SalaryRange?.To}${getJob.getJobSuccess.Job?.[0].SalaryRange?.Currency}`}
                                      </span>
                                    </p>
                                  </li>
                                  <li>
                                    <p className="object-inline object-inline_sm">
                                      <span className="icon icon-1 text-primary mdi mdi-web" />
                                      <span>
                                        {getJob.getJobSuccess.Job.CompanyDetails?.Website}
                                      </span>
                                    </p>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div> */}

                      <div className='company-light-main'>
                        <h5 className='company-light-title'>
                          {getJob.getJobSuccess?.Job[0]?.CompanyDetails?.OrganizationName ? getJob.getJobSuccess?.Job[0]?.CompanyDetails?.OrganizationName : 'N/A'}
                        </h5>
                        <div className='company-light-info'>
                          <div className='row row-15 row-bordered my-0'>
                            <div className='col-md-6 col-sm-12 col-xs-12 col-lg-6 col-xl-6'>
                              <ul className='list list-xs list-unstyled'>
                                <li>
                                  <p className='object-inline object-inline_sm'>
                                    <span className='icon icon-1 text-success me-2 mdi mdi-map-marker'>
                                      <IoLocationSharp fontSize={17} color='#52be71' />
                                    </span>
                                    <span className='text-muted' style={{ fontSize: '14px' }}>
                                      {job?.Location ? job?.Location : 'N/A'}
                                    </span>
                                  </p>
                                </li>
                                <li>
                                  <p className='object-inline object-inline_sm'>
                                    <span className='icon icon-default text-success me-2 mdi mdi-clock'>
                                      <BiTimeFive fontSize={17} color='#52be71' />
                                    </span>
                                    <span className='text-muted' style={{ fontSize: '14px' }}>
                                      Closing Date: {formatDate(job.ClosingDate)}
                                    </span>
                                  </p>
                                </li>
                                <li className='d-md-none d-block'>
                                  <p className='object-inline object-inline_sm'>
                                    <span className='icon icon-sm text-success me-2 mdi mdi-cash' >
                                      <FaRegMoneyBillAlt fontSize={17} color='#52be71' />
                                    </span>
                                    <span className='text-muted' style={{ fontSize: '14px' }}>
                                      Salary:{' '}
                                      <span style={{ fontSize: '14px' }}>
                                        {` 
                                      ${getJob.getJobSuccess.Job?.[0].SalaryRange?.From}${getJob.getJobSuccess.Job?.[0].SalaryRange?.Currency} - ${getJob.getJobSuccess.Job?.[0].SalaryRange?.To}${getJob.getJobSuccess.Job?.[0].SalaryRange?.Currency}`}
                                      </span>
                                    </span>
                                  </p>
                                </li>
                                <li className='d-md-none d-block'>
                                  <p className='object-inline object-inline_sm'>
                                    <span className='icon icon-1 text-success me-2 mdi mdi-web'>
                                      <BsGlobe fontSize={17} color='#52be71' />
                                    </span>
                                    <span className='text-muted' style={{ fontSize: '14px' }}>
                                      {getJob.getJobSuccess?.Job[0]?.CompanyDetails.Website ? <a href={`${getJob.getJobSuccess?.Job[0]?.CompanyDetails?.Website}`}>
                                        {getJob.getJobSuccess?.Job[0]?.CompanyDetails?.Website}
                                      </a> : 'N/A'}
                                    </span>
                                  </p>
                                </li>
                              </ul>
                            </div>
                            <div className='col-md-6 col-sm-12 col-xs-12 col-lg-6 col-xl-6 d-none d-md-block border-start'>
                              <ul className='list list-xs list-unstyled mb-0'>
                                <li>
                                  <p className='object-inline object-inline_sm'>
                                    <span className='icon icon-sm text-success me-2 mdi mdi-cash' >
                                      <FaRegMoneyBillAlt fontSize={17} color='#52be71' />
                                    </span>
                                    <span className='text-muted' style={{ fontSize: '14px' }}>
                                      Salary:{' '}
                                      <span style={{ fontSize: '14px' }}>
                                        {` 
                                      ${getJob.getJobSuccess.Job?.[0].SalaryRange?.From}${getJob.getJobSuccess.Job?.[0].SalaryRange?.Currency} - ${getJob.getJobSuccess.Job?.[0].SalaryRange?.To}${getJob.getJobSuccess.Job?.[0].SalaryRange?.Currency}`}
                                      </span>
                                    </span>
                                  </p>
                                </li>
                                <li>
                                  <p className='object-inline object-inline_sm'>
                                    <span className='icon icon-1 text-success me-2 mdi mdi-web'>
                                      <BsGlobe fontSize={17} color='#52be71' />
                                    </span>
                                    <span className='text-muted' style={{ fontSize: '14px' }} >

                                      {getJob.getJobSuccess?.Job[0]?.CompanyDetails.Website ? <a target='_blank' href={`${getJob.getJobSuccess?.Job[0]?.CompanyDetails.Website}`}>
                                        {getJob.getJobSuccess?.Job[0]?.CompanyDetails.Website} </a> : 'N/A'}
                                    </span>
                                    {/* <span className='text-muted'  style={{fontSize:'14px'}}>
                                        {getJob.getJobSuccess?.Job[0]?.CompanyDetails
                                            .ContactPersonFirstEmail ? <a href={`${getJob.getJobSuccess?.Job[0]?.CompanyDetails?.ContactPersonFirstEmail}`}>
                                          {getJob.getJobSuccess?.Job[0]?.CompanyDetails?.ContactPersonFirstEmail}
                                        </a> : 'not available'}
                                      </span> */}
                                  </p>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </article>
                  </div>

                  <div className='col-md-12 col-sm-12 col-xs-12 col-lg-12 col-xl-12'>
                    <hr />
                  </div>

                  <div className='row'>


                    <div className='col-md-6 col-sm-12 col-xs-6 col-lg-6 col-xl-6 my-2'>
                      <div className='form-wrap'>
                        <div ><span className="text-muted">Category:</span> <span style={{ color: '#000000' }}>{job.VacancyCategory}</span></div>
                      </div>
                    </div>


                    <div className='col-md-6 col-sm-12 col-xs-6 col-lg-6 my-2'>
                      <div className='form-wrap'>
                        <div style={{ color: '#151515' }}  > <span className="text-muted">Type:</span> <span style={{ color: '#000000' }}>{job.VacancyType}</span> </div>
                      </div>
                    </div>

                    <div className='col-md-6 col-sm-12 col-xs-6 col-lg-6 col-xl-6 my-2'>
                      <div className='form-wrap'>
                        <div ><span className="text-muted">Preferable Qualification:</span> <span style={{ color: '#000000' }}>{getQualification(job.PreferableQualification)}</span></div>
                      </div>
                    </div>


                    <div className='col-md-6 col-sm-12 col-xs-6 col-lg-6 my-2'>
                      <div className='form-wrap'>
                        <div style={{ color: '#151515' }}  > <span className="text-muted">Post Date:</span> <span style={{ color: '#000000' }}>{formatDate(job.createdAt)}</span> </div>
                      </div>
                    </div>


                    <div className='col-md-12 col-sm-12 col-xs-12 col-lg-12 mt-3'>
                      <div className='form-wrap'>
                        {/* <div style={{ color: '#151515' }}  className="text-muted">Description</div> */}
                        <div style={{ color: '#000000' }}>
                          <p className='text-style-1'>{job.JobDescription}</p>
                        </div>
                      </div>
                    </div>
                  </div>

                  {getJob?.getJobSuccess?.Job[0]?.CompanyDetails && (
                    <>
                    {getJob?.getJobSuccess?.Job?.[0]?.SetDefaultCompanyDetails === true ?
                      <div className='mt-2'>
                      <h4>Company Contact Person Details</h4>
                      <hr />
                      <div className='row'>


                        <div className='col-md-6 col-sm-12 col-xs-6 col-lg-6 col-xl-6 my-1'>
                          <div className='form-wrap'>
                            <div ><span className="text-muted">Company Name:</span> <span style={{ color: '#000000' }}>{getJob?.getJobSuccess?.Job[0]?.CompanyDetails?.OrganizationName}</span></div>
                          </div>
                        </div>


                        <div className='col-md-6 col-sm-12 col-xs-6 col-lg-6 my-1'>
                          <div className='form-wrap'>
                            <div style={{ color: '#151515' }}  > <span className="text-muted">Person Name:</span> <span style={{ color: '#000000' }}>{getJob?.getJobSuccess?.Job[0]?.CompanyDetails?.contactPerson?.FirstName} {getJob?.getJobSuccess?.Job[0]?.CompanyDetails?.contactPerson?.LastName}</span> </div>
                          </div>
                        </div>

                        <div className='col-md-6 col-sm-12 col-xs-6 col-lg-6 col-xl-6 my-1'>
                          <div className='form-wrap'>
                            <div ><span className="text-muted">Phone Number:</span> <span style={{ color: '#000000' }}>{getJob?.getJobSuccess?.Job[0]?.CompanyDetails?.contactPerson?.PhoneNumber}</span></div>
                          </div>
                        </div>


                        <div className='col-md-6 col-sm-12 col-xs-6 col-lg-6 my-1'>
                          <div className='form-wrap'>
                            <div style={{ color: '#151515' }}  > <span className="text-muted">Email:</span> <span style={{ color: '#000000' }}>{getJob?.getJobSuccess?.Job[0]?.CompanyDetails?.contactPerson?.Email}</span> </div>
                          </div>
                        </div>

                      </div>
                    </div> : <div className='mt-2'>
                      <h4>Company Contact Person Details</h4>
                      <hr />
                      <div className='row'>


                        <div className='col-md-6 col-sm-12 col-xs-6 col-lg-6 col-xl-6 my-1'>
                          <div className='form-wrap'>
                            <div ><span className="text-muted">Company Name:</span> <span style={{ color: '#000000' }}>{getJob?.getJobSuccess?.Job[0]?.CompanyDetails?.CompanyName}</span></div>
                          </div>
                        </div>


                        <div className='col-md-6 col-sm-12 col-xs-6 col-lg-6 my-1'>
                          <div className='form-wrap'>
                            <div style={{ color: '#151515' }}  > <span className="text-muted">Person Name:</span> <span style={{ color: '#000000' }}>{getJob?.getJobSuccess?.Job[0]?.CompanyDetails?.ContactPersonName}</span> </div>
                          </div>
                        </div>

                        <div className='col-md-6 col-sm-12 col-xs-6 col-lg-6 col-xl-6 my-1'>
                          <div className='form-wrap'>
                            <div ><span className="text-muted">Phone Number:</span> <span style={{ color: '#000000' }}>{getJob?.getJobSuccess?.Job[0]?.CompanyDetails?.ContactPersonFirstPhoneNumber}</span></div>
                          </div>
                        </div>


                        <div className='col-md-6 col-sm-12 col-xs-6 col-lg-6 my-1'>
                          <div className='form-wrap'>
                            <div style={{ color: '#151515' }}  > <span className="text-muted">Email:</span> <span style={{ color: '#000000' }}>{getJob?.getJobSuccess?.Job[0]?.CompanyDetails?.ContactPersonFirstEmail}</span> </div>
                          </div>
                        </div>

                      </div>
                    </div>
                    }</>
                    
                  )}

                  {/* </div> */}
                  {auth.userRole === 'candidate' && (
                    <div className='mt-3'>

                      {isApplied ? <button
                        disabled
                        className='button button-lg button-primary-outline button-anorak mb-5'
                      >
                        Job Applied
                      </button> : 
                      <button
                        disabled={
                          applicationInProgress ||
                          postApplications.postApplicationsLoading ||
                          job.isApplied
                        }
                        className='button button-lg button-primary-outline button-anorak mb-5'
                        onClick={() => handleApply(job._id)}
                      >
                        {applicationInProgress
                          ? 'Loading...'
                          : job.isApplied
                            ? 'Already Applied'
                            : 'Apply'}
                      </button>}



                    </div>

                  )}
                </div>
              ))
            )}

            {/* Related Jobs */}
            <div className='block offset-top-2'>
              <h4>Related Jobs</h4>

              {getJobs.getJobsLoading ? (
                'Loading Jobs List'
              ) : (
                <table className='table-job-listing table-responsive'>
                  <tbody>
                    {getJobs.getJobsSuccess?.ads?.map((job, index) => (
                      <tr key={index}>
                        <td className='table-job-listing-main'>
                          <article className='company-minimal'>
                            <figure className='company-minimal-figure'>
                              <img
                                className='company-minimal-image'
                                src={`data:jpg;base64,${Buffer.from(
                                  job?.Logo?.data ? job?.Logo?.data : ''
                                ).toString('base64')}`}
                                alt=''
                              />
                            </figure>
                            <div className='company-minimal-main'>
                              <h5 className='company-minimal-name'>
                                <Link to={`/job-details/${job._id}`}>{job.VacancyTitle}</Link>
                              </h5>
                              <p>{job.City}</p>
                            </div>
                          </article>
                        </td>
                        <td className='table-job-listing-badge'>
                          <span className='badge badge-success'>{job.VacancyType}</span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
