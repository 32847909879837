import { checkTokenExpiration } from './authAction'
import { SUCCESS_NOTIFIER, toastNotifier } from '../../component/toaster/Toaste'
import { errorHandler } from '../../component/errorHandler/errorHandler'
import { getMyJobsAddCall, postJobsAddCall, updateJobsAddCall } from '../../component/network/axios'
import {
  GET_MY_JOB_LOADING,
  GET_MY_JOB_SUCCESS,
  GET_MY_JOB_ERROR,
  POST_JOB_LOADING,
  POST_JOB_SUCCESS,
  POST_JOB_ERROR,
  UPDATE_JOB_LOADING,
  UPDATE_JOB_SUCCESS,
  UPDATE_JOB_ERROR,
} from '../types'
import { isNoData, JOB } from '../../component/utility'

export const getMyJobsAction = () => {
  return async (dispatch) => {
    dispatch({ type: GET_MY_JOB_LOADING })
    try {
      const data = await getMyJobsAddCall({})
      isNoData(data, JOB)
      dispatch({ type: GET_MY_JOB_SUCCESS, payload: data.data })
    } catch (error) {
      errorHandler({ error })
      dispatch({ type: GET_MY_JOB_ERROR, payload: error })

      checkTokenExpiration({
        dispatch,
        parentFunction: getMyJobsAction,
        error,
      })
    }
  }
}

export const postJobAction = (formData, onSuccess) => {
  return async (dispatch) => {
    // setLoading(true)
    dispatch({
      type: POST_JOB_LOADING,
    })
    try {
      const data = await postJobsAddCall({ body: formData })
      dispatch({ type: POST_JOB_SUCCESS, payload: data.data })
      toastNotifier({ type: SUCCESS_NOTIFIER, message: 'Job created' })
      onSuccess()
    } catch (error) {
      errorHandler({ error })
      dispatch({ type: POST_JOB_ERROR, payload: error.message })

      checkTokenExpiration({
        dispatch,
        parentFunction: postJobAction,
        error,
      })
    }

    // setLoading(false)

  }
}




// export const postJobAction = (formData, onSuccess,setLoading) => {
//   return async (dispatch) => {
//     setLoading(true)
//     dispatch({
//       type: POST_JOB_LOADING,
//     })
//       postJobsAddCall({ body: formData }).then((e)=>{
//         dispatch({ type: POST_JOB_SUCCESS, payload: e.data })
//         toastNotifier({ type: SUCCESS_NOTIFIER, message: 'Job created' })
//         onSuccess()
//       console.log(formData);
//       })

//     .catch((error)=>{
//       errorHandler({ error })
//       dispatch({ type: POST_JOB_ERROR, payload: error.message })
//       checkTokenExpiration({
//         dispatch,
//         parentFunction: postJobAction,
//         error,
//       })
//     })
//     setLoading(false)

//   }
// }













export const updateJobAction = (formData, id) => {
  return async (dispatch) => {
    // setLoading(true)
    dispatch({
      type: UPDATE_JOB_LOADING,
    })

    try {
      const data = await updateJobsAddCall({ body: formData, params: id })
      dispatch({ type: UPDATE_JOB_SUCCESS, payload: data.data })
      toastNotifier({ type: SUCCESS_NOTIFIER, message: 'Job updated' })
    } catch (error) {
      errorHandler({ error })
      dispatch({ type: UPDATE_JOB_ERROR, payload: error.message })

      checkTokenExpiration({
        dispatch,
        parentFunction: updateJobAction,
        error,
      })
    }

    // setLoading(false)
  }
}
