import {
  GET_APPLICATIONS_SUCCESS,
  GET_APPLICATIONS_LOADING,
  GET_APPLICATIONS_ERROR,
  GET_APPLICATIONS_BY_JOB_ID_SUCCESS,
  GET_APPLICATIONS_BY_JOB_ID_LOADING,
  GET_APPLICATIONS_BY_JOB_ID_ERROR,
  POST_APPLICATIONS_LOADING,
  POST_APPLICATIONS_SUCCESS,
  POST_APPLICATIONS_ERROR,
} from '../types'

const getInitialState = {
  getApplicationsLoading: true,
  getApplicationsSuccess: [],
  getApplicationsError: '',
}

export const getApplicationsReducer = (state = getInitialState, { type, payload }) => {
  switch (type) {
    case GET_APPLICATIONS_LOADING:
      return {
        ...state,
        getApplicationsLoading: true,
      }
    case GET_APPLICATIONS_SUCCESS:
      return {
        ...state,
        getApplicationsLoading: false,
        getApplicationsSuccess: payload,
      }
    case GET_APPLICATIONS_ERROR:
      return {
        ...state,
        getApplicationsLoading: false,
        getApplicationsError: payload,
      }

    default:
      return state
  }
}

const getSingleInitialState = {
  getApplicationsByJobIdLoading: true,
  getApplicationsByJobIdSuccess: [],
  getApplicationsByJobIdError: '',
}

export const getApplicationsByJobIdReducer = (state = getSingleInitialState, { type, payload }) => {
  switch (type) {
    case GET_APPLICATIONS_BY_JOB_ID_LOADING:
      return {
        ...state,
        getApplicationsByJobIdLoading: true,
      }
    case GET_APPLICATIONS_BY_JOB_ID_SUCCESS:
      return {
        ...state,
        getApplicationsByJobIdLoading: false,
        getApplicationsByJobIdSuccess: payload,
      }
    case GET_APPLICATIONS_BY_JOB_ID_ERROR:
      return {
        ...state,
        getApplicationsByJobIdLoading: false,
        getApplicationsByJobIdError: payload,
      }

    default:
      return state
  }
}

const postInitialState = {
  postApplicationsLoading: false,
  postApplicationsSuccess: [],
  postApplicationsError: '',
}

export const postApplicationsReducer = (state = postInitialState, { type, payload }) => {
  switch (type) {
    case POST_APPLICATIONS_LOADING:
      return {
        ...state,
        postApplicationsLoading: true,
      }
    case POST_APPLICATIONS_SUCCESS:
      return {
        ...state,
        postApplicationsLoading: false,
        postApplicationsSuccess: payload,
      }
    case POST_APPLICATIONS_ERROR:
      return {
        ...state,
        postApplicationsLoading: false,
        postApplicationsError: payload,
      }

    default:
      return state
  }
}
