import { combineReducers } from 'redux'

import { modalReducer } from '../ui'
import { authReducer } from './authReducer'
import { getJobReducer, getJobsReducer } from './jobsReducer'
import { getMyJobsReducer, postJobsReducer, updateJobsReducer } from './myJobsReducer'
import { updatePasswordReducer } from './updatePasswordReducer'
import { getSkillsReducer } from './skillsReducer'
import { getQualificationsReducer } from './getqualificationsReducer'
import { getResumeReducer, updateResumeReducer } from './resumeReducer'
import { getProfileReducer, updateProfileReducer } from './profileReducer'
import {
  getApplicationsReducer,
  getApplicationsByJobIdReducer,
  postApplicationsReducer,
} from './applicationsReducer'
import { getCandidateReducer } from './candidateReducer'

export default combineReducers({
  auth: authReducer,

  updatePassword: updatePasswordReducer,
  getQualifications: getQualificationsReducer,
  getResume: getResumeReducer,
  updateResume: updateResumeReducer,

  getJobs: getJobsReducer,
  getJob: getJobReducer,

  getMyJobs: getMyJobsReducer,
  postJob: postJobsReducer,
  updateJob: updateJobsReducer,

  getSkills: getSkillsReducer,

  getProfile: getProfileReducer,
  updateProfile: updateProfileReducer,

  getApplications: getApplicationsReducer,
  getApplicationsByJobId: getApplicationsByJobIdReducer,
  postApplications: postApplicationsReducer,

  getCandidate: getCandidateReducer,

  modal: modalReducer,
})
