import { checkTokenExpiration } from './authAction'
import { SUCCESS_NOTIFIER, toastNotifier } from '../../component/toaster/Toaste'
import {
  GET_PROFILE_LOADING,
  GET_PROFILE_SUCCESS,
  GET_PROFILE_ERROR,
  UPDATE_PROFILE_LOADING,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_ERROR,
} from '../types'
import { errorHandler } from '../../component/errorHandler/errorHandler'
import { getProfileCall, updateProfileCall } from '../../component/network/axios'

export const getProfileAction = () => {
  return async (dispatch) => {
    dispatch({ type: GET_PROFILE_LOADING })
    try {
      const data = await getProfileCall()
      dispatch({ type: GET_PROFILE_SUCCESS, payload: data.data })
    } catch (error) {
      errorHandler({ error })
      dispatch({ type: GET_PROFILE_ERROR, payload: error.message })

      checkTokenExpiration({
        dispatch,
        parentFunction: getProfileAction,
        error,
      })
    }
  }
}

export const updateProfileAction = (formData) => {
  return async (dispatch) => {
    dispatch({ type: UPDATE_PROFILE_LOADING })
    try {
      const data = await updateProfileCall({ body: formData })
      dispatch({ type: UPDATE_PROFILE_SUCCESS, payload: data.data })
      dispatch(getProfileAction())
      toastNotifier({ type: SUCCESS_NOTIFIER, message: 'Profile Updated' })
    } catch (error) {
      errorHandler({ error })
      dispatch({ type: UPDATE_PROFILE_ERROR, payload: error.message })

      checkTokenExpiration({
        dispatch,
        parentFunction: updateProfileAction,
        error,
      })
    }
  }
}
