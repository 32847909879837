const SHOW_MODAL = 'SHOW_MODAL'
const HIDE_MODAL = 'HIDE_MODAL'

export const MODAL_CONTENT_ON_EMPLOYER_REGISTRATION = [
  'Registration form has been successfully submitted. Thank you!',
  'We are very pleased that you are interested in beginning your journey with us.',
  <span>You will shortly receive an email from <strong>info@apex-financecareers.com</strong> that will guide you to the account activation process. If you do not receive an email, check your “junk mail/spam” folder. For technical assistance, please do not hesitate to write to us at <strong>info@apex-financecareers.com</strong>. We shall be glad to get back to you.</span>,
  <h3 >The Finance Career Team</h3>

]

export const MODAL_CONTENT_ON_EMPLOYEE_REGISTRATION = [
  'Registration form has been successfully submitted. Thank you!',
  'We are very pleased that you are interested in beginning your journey with us.',
  <span>You will shortly receive an email from <strong>info@apex-financecareers.com</strong> that will guide you to the account activation process. If you do not receive an email, check your “junk mail/spam” folder. For technical assistance, please do not hesitate to write to us at <strong>info@apex-financecareers.com</strong>. We shall be glad to get back to you.</span>,
  <h3 >The Finance Career Team</h3>

]

export const MODAL_CONTENT_ON_FORGOT_PASSWORD = [
  'If your account exists, a password reset link was sent to your registered email address.',
  'If you do not receive an email, it may be because you are suspended, your account is not activated, you have requested multiple password resets within a short time frame, or you are not allowed to change your password.',
  'Contact site administrator if any of those reasons apply. Also, please check your spam filter.',
]

const initialState = {
  showModal: false,
  content: MODAL_CONTENT_ON_EMPLOYER_REGISTRATION,
}

export const modalReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SHOW_MODAL:
      return {
        ...state,
        showModal: true,
        content: payload,
      }
    case HIDE_MODAL:
      return {
        ...state,
        showModal: false,
        content: [],
      }

    default:
      return state
  }
}

export const hideModal = () => (dispatch) => dispatch({ type: HIDE_MODAL })
export const showModal = (content) => {
  return (dispatch) => {
    dispatch({
      type: SHOW_MODAL,
      payload: content,
    })
  }
}
