import Steps from '../component/steps'
import Slider from '../component/slider'
import Pricing from '../component/pricing'
import Companies from '../component/companies'
import Catagories from '../component/catagories'
// import RecentJobs from '../component/recentJobs'
import NewCandidates from '../component/workPlace'

export default function Home() {
  return (
    <>
      <Slider />
      <Catagories />
      {/* <RecentJobs /> */}
      <NewCandidates />
     {/* <Pricing /> */}
      {/* <Companies /> */}
      <Steps />
    </>
  )
}
