import {
  GET_MY_JOB_LOADING,
  GET_MY_JOB_SUCCESS,
  GET_MY_JOB_ERROR,
  POST_JOB_LOADING,
  POST_JOB_SUCCESS,
  POST_JOB_ERROR,
  UPDATE_JOB_LOADING,
  UPDATE_JOB_SUCCESS,
  UPDATE_JOB_ERROR,
} from '../types'

const getMyJobsInitialState = {
  getMyJobLoading: true,
  getMyJobSuccess: [],
  getMyJobError: '',
}

export const getMyJobsReducer = (state = getMyJobsInitialState, { type, payload }) => {
  switch (type) {
    case GET_MY_JOB_LOADING:
      return {
        ...state,
        getMyJobLoading: true,
      }
    case GET_MY_JOB_SUCCESS:
      return {
        ...state,
        getMyJobLoading: false,
        getMyJobSuccess: payload,
      }
    case GET_MY_JOB_ERROR:
      return {
        ...state,
        getMyJobLoading: false,
        getMyJobError: payload,
      }

    default:
      return state
  }
}

const postJobsInitialState = {
  postJobLoading: true,
  postJobSuccess: [],
  postJobError: '',
}

export const postJobsReducer = (state = postJobsInitialState, { type, payload }) => {
  switch (type) {
    case POST_JOB_LOADING:
      return {
        ...state,
        postJobLoading: true,
      }
    case POST_JOB_SUCCESS:
      return {
        ...state,
        postJobLoading: false,
        postJobSuccess: payload,
      }
    case POST_JOB_ERROR:
      return {
        ...state,
        postJobLoading: false,
        postJobError: payload,
      }

    default:
      return state
  }
}

const updateJobsInitialState = {
  updateJobLoading: true,
  updateJobSuccess: [],
  updateJobError: '',
}

export const updateJobsReducer = (state = updateJobsInitialState, { type, payload }) => {
  switch (type) {
    case UPDATE_JOB_LOADING:
      return {
        ...state,
        updateJobLoading: true,
      }
    case UPDATE_JOB_SUCCESS:
      return {
        ...state,
        updateJobLoading: false,
        updateJobSuccess: payload,
      }
    case UPDATE_JOB_ERROR:
      return {
        ...state,
        updateJobLoading: false,
        updateJobError: payload,
      }

    default:
      return state
  }
}
