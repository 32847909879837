import InfiniteCarousel from 'react-leaf-carousel'
import { ReactComponent as Logo } from '../component/assets/images/user.svg'

export default function Catagories() {
  return (
    <div className="catasilder">
      <div className="textcata">
        <h2>Job Categories</h2>
      </div>
       
       <div className='categorySlider container'>

      <InfiniteCarousel
        breakpoints={[
          {
            breakpoint: 500,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
            },
          },
        ]}
        dots={true}
        sidesOpacity={0.5}
        slidesToScroll={5}
        slidesToShow={5}
        animationDuration={1000}
        lazyLoad={true}
        arrows={false}
        scrollOnDevice={true}
      >
        
        <div >
          <div className="box-creative" >
            <div className="box-creative-inner">
              <div>
                <Logo className="newcataicon" />
              </div>
              <p className="box-creative-title">Accounting & Finance</p>
              <p className="pbtm">2590 open positions</p>
            </div>
            <div className="box-creative-dummy" />
          </div>
        </div>
        <div>
          <div className="box-creative">
            <div className="box-creative-inner">
              <div>
                <Logo className="newcataicon" />
              </div>
              <p className="box-creative-title">⦁	Audit and assurance</p>
              <p className="pbtm">2590 open positions</p>
            </div>
            <div className="box-creative-dummy" />
          </div>
        </div>
        <div>
          <div className="box-creative">
            <div className="box-creative-inner">
              <div>
                <Logo className="newcataicon" />
              </div>
              <p className="box-creative-title">⦁	Taxation and compliance</p>
              <p className="pbtm">2590 open positions</p>
            </div>
            <div className="box-creative-dummy" />
          </div>
        </div>
        
        {/* <div>
          <div className="box-creative">
            <div className="box-creative-inner">
              <div>
                <Logo className="newcataicon" />
              </div>
              <p className="box-creative-title">Accounting & Finance</p>
              <p className="pbtm">2590 open positions</p>
            </div>
            <div className="box-creative-dummy" />
          </div>
        </div>
        <div>
          <div className="box-creative">
            <div className="box-creative-inner">
              <div>
                <Logo className="newcataicon" />
              </div>
              <p className="box-creative-title">Accounting & Finance</p>
              <p className="pbtm">2590 open positions</p>
            </div>
            <div className="box-creative-dummy" />
          </div>
        </div>
        <div>
          <div className="box-creative">
            <div className="box-creative-inner">
              <div>
                <Logo className="newcataicon" />
              </div>
              <p className="box-creative-title">Accounting & Finance</p>
              <p className="pbtm">2590 open positions</p>
            </div>
            <div className="box-creative-dummy" />
          </div>
        </div>
        <div>
          <div className="box-creative">
            <div className="box-creative-inner">
              <div>
                <Logo className="newcataicon" />
              </div>
              <p className="box-creative-title">Accounting & Finance</p>
              <p className="pbtm">2590 open positions</p>
            </div>
            <div className="box-creative-dummy" />
          </div>
        </div>
        <div>
          <div className="box-creative">
            <div className="box-creative-inner">
              <div>
                <Logo className="newcataicon" />
              </div>
              <p className="box-creative-title">Accounting & Finance</p>
              <p className="pbtm">2590 open positions</p>
            </div>
            <div className="box-creative-dummy" />
          </div>
        </div>
        <div>
          <div className="box-creative">
            <div className="box-creative-inner">
              <div>
                <Logo className="newcataicon" />
              </div>
              <p className="box-creative-title">Accounting & Finance</p>
              <p className="pbtm">2590 open positions</p>
            </div>
            <div className="box-creative-dummy" />
          </div>
        </div>
        <div>
          <div className="box-creative">
            <div className="box-creative-inner">
              <div>
                <Logo className="newcataicon" />
              </div>
              <p className="box-creative-title">Accounting & Finance</p>
              <p className="pbtm">2590 open positions</p>
            </div>
            <div className="box-creative-dummy" />
          </div>
        </div>
        <div>
          <div className="box-creative">
            <div className="box-creative-inner">
              <div>
                <Logo className="newcataicon" />
              </div>
              <p className="box-creative-title">Accounting & Finance</p>
              <p className="pbtm">2590 open positions</p>
            </div>
            <div className="box-creative-dummy" />
          </div>
        </div>
        <div>
          <div className="box-creative">
            <div className="box-creative-inner">
              <div>
                <Logo className="newcataicon" />
              </div>
              <p className="box-creative-title">Accounting & Finance</p>
              <p className="pbtm">2590 open positions</p>
            </div>
            <div className="box-creative-dummy" />
          </div>
        </div>
        <div>
          <div className="box-creative">
            <div className="box-creative-inner">
              <div>
                <Logo className="newcataicon" />
              </div>
              <p className="box-creative-title">Accounting & Finance</p>
              <p className="pbtm">2590 open positions</p>
            </div>
            <div className="box-creative-dummy" />
          </div>
        </div> */}
      </InfiniteCarousel>
      </div>

    </div>
  )
}
