import { UPDATE_PASSWORD_LOADING, UPDATE_PASSWORD_SUCCESS, UPDATE_PASSWORD_ERROR } from '../types'

const submitInitialState = {
  updatePasswordLoading: false,
  updatePasswordSuccess: [],
  updatePasswordError: '',
}

export const updatePasswordReducer = (state = submitInitialState, { type, payload }) => {
  switch (type) {
    case UPDATE_PASSWORD_LOADING:
      return {
        ...state,
        updatePasswordLoading: true,
      }
    case UPDATE_PASSWORD_SUCCESS:
      return {
        ...state,
        updatePasswordLoading: false,
        updatePasswordSuccess: payload,
      }
    case UPDATE_PASSWORD_ERROR:
      return {
        ...state,
        updatePasswordLoading: false,
        updatePasswordError: payload,
      }

    default:
      return state
  }
}
