import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import SideFilters from './SideFilters'
import dummy from './assets/images/company.png'
import { getCandidateAction } from '../store/actions/candidate'
import { CANDIDATE_FILTER_FIELDS } from './helper/inputConstants'
import { getSkillsAction } from '../store/actions/skillsActions'
import { qualificationToSelect, skillsToSelect } from './helper/parse'
import { getQualificationsAction } from '../store/actions/qualificationsAction'
import { Spinner } from 'react-bootstrap'
import { ProcessLoader } from './loaders/ProcessLoader'

export default function CandidatesListing() {
  const [filterList, setFilterList] = useState(CANDIDATE_FILTER_FIELDS)
  const { getSkills, getQualifications } = useSelector((state) => state)

  const dispatch = useDispatch()
  const { getCandidate } = useSelector((state) => state)

  // console.log(getCandidate.getCandidateLoading);

  useEffect(() => {
    dispatch(getCandidateAction())
    dispatch(getQualificationsAction())
    dispatch(getSkillsAction())
  }, [dispatch])

  useEffect(() => {
    setFilterList((filter) =>
      filter.map((field) => {
        if (field.name === 'qualifications') {
          return {
            ...field,
            options: qualificationToSelect(getQualifications),
          }
        } else return field
      })
    )
  }, [getQualifications])
  useEffect(() => {
    setFilterList((filter) =>
      filter.map((field) => {
        if (field.name === 'skills') {
          return {
            ...field,
            options: [{ value: '', label: 'Select' }, ...skillsToSelect(getSkills)],
          }
        } else return field
      })
    )
  }, [getSkills])

  const filterChange = (e) => {
    const name = e.target.name
    const value = e.target.value
    setFilterList((filterList) =>
      filterList.map((field) => (name === field.name ? { ...field, value } : field))
    )
  }

  const handleFilterSubmit = (e) => {
    e.preventDefault()
    const params = {}
    filterList.map((field) => {
      if (field.value) params[field.name] = field.value
    })

    dispatch(getCandidateAction(params))
  }

  return (
    <div>
      <section className="section breadcrumbs-custom breadcrumbs-custom-overlay-4">
        <div id="jobListing-bg" className="breadcrumbs-custom-main bg-image bg-gray-700">
          <div className="container">
            <h3 className="breadcrumbs-custom-title">Candidate list</h3>
          </div>
        </div>
      </section>
      <section className="section section-md">
        <div className="container">
          <div className="row row-50 flex-lg-row-reverse">
            <SideFilters
              filterList={filterList}
              onChange={filterChange}
              onSubmit={handleFilterSubmit}
            />
            <div className="col-lg-8 col-xl-9">
              {getCandidate.getCandidateLoading ? <ProcessLoader height='40' /> : <table className="table-job-listing table-responsive">
                <tbody>
                  {getCandidate?.getCandidateSuccess?.candidates?.map((candidate, index) => (
                    <tr key={index}>
                      <td className="table-job-listing-main">
                        <Link to={`/resume/${candidate.Resume[0].CandidateId}`}>
                          <article className="company-minimal">
                            <figure className="company-minimal-figure">
                              <img className="company-minimal-image" src={dummy} alt="" />
                            </figure>
                            <div className="company-minimal-main">
                              <h5 className="company-minimal-title cursor-pointer">
                                {candidate.FirstName + ' ' + candidate.LastName}
                              </h5>
                              <p>{candidate.Email}</p>
                            </div>
                          </article>
                        </Link>
                      </td>
                      <td className="table-job-listing-date" />
                    </tr>
                  ))}
                </tbody>
              </table>}
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
