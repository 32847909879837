import {
  GET_QUALIFICATIONS_LOADING,
  GET_QUALIFICATIONS_SUCCESS,
  GET_QUALIFICATIONS_ERROR,
} from '../types'

const initialState = {
  getQualificationsLoading: true,
  getQualificationsSuccess: [],
  getQualificationsError: '',
}

export const getQualificationsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_QUALIFICATIONS_LOADING:
      return {
        ...state,
        getQualificationsLoading: true,
      }
    case GET_QUALIFICATIONS_SUCCESS:
      return {
        ...state,
        getQualificationsLoading: false,
        getQualificationsSuccess: payload,
      }
    case GET_QUALIFICATIONS_ERROR:
      return {
        ...state,
        getQualificationsLoading: false,
        getQualificationsError: payload,
      }

    default:
      return state
  }
}
