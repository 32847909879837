export const AUTH_LOADING_START = 'AUTH_LOADING_START'
export const AUTH_LOADING_STOP = 'AUTH_LOADING_STOP'
export const GET_ACCESS_TOKEN = 'GET_ACCESS_TOKEN'
export const SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN'

export const AUTH_LOGOUT_SUCCESS = 'AUTH_LOGOUT_SUCCESS'

export const GET_CANDIDATE_RESUME = 'GET_CANDIDATE_RESUME'
export const SET_CANDIDATE_RESUME = 'SET_CANDIDATE_RESUME'

export const GET_QUALIFICATIONS_LOADING = 'GET_QUALIFICATIONS_LOADING'
export const GET_QUALIFICATIONS_SUCCESS = 'GET_QUALIFICATIONS_SUCCESS'
export const GET_QUALIFICATIONS_ERROR = 'GET_QUALIFICATIONS_ERROR'

// jobs
export const GET_JOB_LOADING = 'GET_JOB_LOADING'
export const GET_JOB_SUCCESS = 'GET_JOB_SUCCESS'
export const GET_JOB_ERROR = 'GET_JOB_ERROR'

export const POST_JOB_LOADING = 'POST_JOB_LOADING'
export const POST_JOB_SUCCESS = 'POST_JOB_SUCCESS'
export const POST_JOB_ERROR = 'POST_JOB_ERROR'

export const UPDATE_JOB_LOADING = 'POST_JOB_LOADING'
export const UPDATE_JOB_SUCCESS = 'POST_JOB_SUCCESS'
export const UPDATE_JOB_ERROR = 'POST_JOB_ERROR'

export const GET_JOBS_LOADING = 'GET_JOBs_LOADING'
export const GET_JOBS_SUCCESS = 'GET_JOBs_SUCCESS'
export const GET_JOBS_ERROR = 'GET_JOBs_ERROR'

export const GET_MY_JOB_LOADING = 'GET_MY_JOB_LOADING'
export const GET_MY_JOB_SUCCESS = 'GET_MY_JOB_SUCCESS'
export const GET_MY_JOB_ERROR = 'GET_MY_JOB_ERROR'

// resume
export const GET_RESUME_LOADING = 'GET_RESUME_LOADING'
export const GET_RESUME_SUCCESS = 'GET_RESUME_SUCCESS'
export const GET_RESUME_ERROR = 'GET_RESUME_ERROR'

export const UPDATE_RESUME_LOADING = 'UPDATE_RESUME_LOADING'
export const UPDATE_RESUME_SUCCESS = 'UPDATE_RESUME_SUCCESS'
export const UPDATE_RESUME_ERROR = 'UPDATE_RESUME_ERROR'

// skills
export const GET_SKILLS_LOADING = 'GET_SKILLS_LOADING'
export const GET_SKILLS_SUCCESS = 'GET_SKILLS_SUCCESS'
export const GET_SKILLS_ERROR = 'GET_SKILLS_ERROR'

// profile
export const GET_PROFILE_LOADING = 'GET_PROFILE_LOADING'
export const GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS'
export const GET_PROFILE_ERROR = 'GET_PROFILE_ERROR'

export const UPDATE_PROFILE_LOADING = 'UPDATE_PROFILE_LOADING'
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS'
export const UPDATE_PROFILE_ERROR = 'UPDATE_PROFILE_ERROR'

// password
export const UPDATE_PASSWORD_LOADING = 'UPDATE_PASSWORD_LOADING'
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS'
export const UPDATE_PASSWORD_ERROR = 'UPDATE_PASSWORD_ERROR'

// Applications
export const GET_APPLICATIONS_LOADING = 'GET_APPLICATIONS_LOADING'
export const GET_APPLICATIONS_SUCCESS = 'GET_APPLICATIONS_SUCCESS'
export const GET_APPLICATIONS_ERROR = 'GET_APPLICATIONS_ERROR'

export const GET_APPLICATIONS_BY_JOB_ID_SUCCESS = 'GET_APPLICATIONS_BY_JOB_ID_SUCCESS'
export const GET_APPLICATIONS_BY_JOB_ID_LOADING = 'GET_APPLICATIONS_BY_JOB_ID_LOADING'
export const GET_APPLICATIONS_BY_JOB_ID_ERROR = 'GET_APPLICATIONS_BY_JOB_ID_ERROR'

export const POST_APPLICATIONS_LOADING = 'POST_APPLICATIONS_LOADING'
export const POST_APPLICATIONS_SUCCESS = 'POST_APPLICATIONS_SUCCESS'
export const POST_APPLICATIONS_ERROR = 'POST_APPLICATIONS_ERROR'

// Candidate
export const GET_CANDIDATE_LOADING = 'GET_CANDIDATE_LOADING'
export const GET_CANDIDATE_SUCCESS = 'GET_CANDIDATE_SUCCESS'
export const GET_CANDIDATE_ERROR = 'GET_CANDIDATE_ERROR'
