import { useDispatch, useSelector } from 'react-redux'

import { hideModal } from '../../store/ui'
import S from './Modal.module.scss'

export default function Modal() {
  const dispatch = useDispatch()
  const { showModal, content } = useSelector((state) => state.modal)

  if (showModal)
    return (
      <div className={S.modal}>
        <div className={S.container}>
          <div className={S.head}>
            <button onClick={() => dispatch(hideModal())}>X</button>
          </div>
          <div className={S.body}>
            {content?.map((item, index) => (
              <p key={index}>{item}</p>
            ))}
          </div>
        </div>
      </div>
    )
  return <></>
}
