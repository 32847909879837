import {
  GET_PROFILE_ERROR,
  GET_PROFILE_LOADING,
  GET_PROFILE_SUCCESS,
  UPDATE_PROFILE_LOADING,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_ERROR,
} from '../types'

const getInitialState = {
  getProfileLoading: true,
  getProfileSuccess: [],
  getProfileError: '',
}

export const getProfileReducer = (state = getInitialState, { type, payload }) => {
  switch (type) {
    case GET_PROFILE_LOADING:
      return {
        ...state,
        getProfileLoading: true,
      }
    case GET_PROFILE_SUCCESS:
      return {
        ...state,
        getProfileLoading: false,
        getProfileSuccess: payload,
      }
    case GET_PROFILE_ERROR:
      return {
        ...state,
        getProfileLoading: false,
        getProfileError: payload,
      }

    default:
      return state
  }
}

const updateInitialState = {
  updateProfileLoading: false,
  updateProfileSuccess: [],
  updateProfileError: '',
}

export const updateProfileReducer = (state = updateInitialState, { type, payload }) => {
  switch (type) {
    case UPDATE_PROFILE_LOADING:
      return {
        ...state,
        updateProfileLoading: true,
      }
    case UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        updateProfileLoading: false,
        updateProfileSuccess: payload,
      }
    case UPDATE_PROFILE_ERROR:
      return {
        ...state,
        updateProfileLoading: false,
        updateProfileError: payload,
      }

    default:
      return state
  }
}
