import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap'

import '../assets/css/style.css'
import mainlogo from '../assets/images/logo.png'
import { ReactComponent as Logo } from '../assets/images/user.svg'
import { checkAuth, checkTokens, logoutAction } from '../../store/actions/authAction'
import { ReactComponent as Logout } from '../assets/images/logout.svg'
import { ERROR_NOTIFIER, toastNotifier } from '../toaster/Toaste'

export default function menu() {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { auth } = useSelector((state) => state)
  const [offset, setOffset] = useState(0)
  const [isOpen, setIsOpen] = useState(false)
  const toggle = () => setIsOpen(!isOpen)

  const handleLogout = () => {
    dispatch(logoutAction())
    navigate('/')
  }

  useEffect(() => {
    if (checkTokens()) {
      dispatch(logoutAction())
      navigate('/')
      toastNotifier({ type: ERROR_NOTIFIER, message: 'Tokens Expired, Please Login Again' })
    }
  }, [])

  useEffect(() => (window.onscroll = () => setOffset(window.pageYOffset)), [])
  useEffect(() => dispatch(checkAuth()), [])

  const loginSignupButtons = () => {
    return (
      <>
        <Link to="/auth/signup">
          <button className="button button-xs button-block button-default-outline button-anorak">
            <span>
              <Logo className="iconimgs" />
            </span>
            Sign up
          </button>
        </Link>
        <Link to="/auth/login">
          <button className="button button-xs button-block button-primary button-anorak">
            <span>
              <Logout className="iconimgs2" />
            </span>
            Login
          </button>
        </Link>
      </>
    )
  }
  const logoutButtons = () => {
    return (
      <a>
        <button
          className="button button-xs button-block button-default-outline button-anorak"
          onClick={handleLogout}
          disabled={auth.isLoading}
        >
          <span>
            <Logo className="iconimgs" />
          </span>
          Log out
        </button>
      </a>
    )
  }

  useEffect(() => {
    dispatch(checkAuth())
  }, [])
  return (
    <div>
      <Navbar className={offset ? 'navfixed' : 'navbar-styling'} light expand="xl">
        <NavbarBrand className="navbrand" href="#">
          <img width={200} src={mainlogo} alt="logo" />
        </NavbarBrand>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <NavbarBrand className="navbrand2" href="#">
            <img width={300} src={mainlogo} alt="logo" />
          </NavbarBrand>
          <Nav className="mr-auto" navbar>
            <NavItem>
              <NavLink tag={Link} to="/">
                home
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink tag={Link} to="/about">
                about us
              </NavLink>
            </NavItem>
            <NavItem onClick={toggle}>
              <NavLink tag={Link} to="/pricing">
               Pricing
              </NavLink>
            </NavItem>

            {/* <NavItem onClick={toggle}>
              <NavLink tag={Link} to="/blog">
               Blog
              </NavLink>
            </NavItem> */}
            {auth.userRole === 'candidate' && (
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                  For Candidates
                </DropdownToggle>
                <DropdownMenu right>
                  <Link to="/jobs">
                    <DropdownItem>Jobs</DropdownItem>
                  </Link>
                  <Link to="/resume">
                    <DropdownItem>My Resume</DropdownItem>
                  </Link>
                  <Link to="/profile">
                    <DropdownItem>My Profile</DropdownItem>
                  </Link>
                </DropdownMenu>
              </UncontrolledDropdown>
            )}
            {auth.userRole === 'employer' && (
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                  For Employers
                </DropdownToggle>
                <DropdownMenu right>
                  <Link to="/jobs">
                    <DropdownItem>My Jobs</DropdownItem>
                  </Link>
                  <Link to="/post-job">
                    <DropdownItem>Create New Jobs</DropdownItem>
                  </Link>
                  <Link to="/profile">
                    <DropdownItem>My Profile</DropdownItem>
                  </Link>
                  {auth.subscriptionType === 'premium' && (
                    <Link to="/candidates">
                      <DropdownItem>Candidates</DropdownItem>
                    </Link>
                  )}
                </DropdownMenu>
              </UncontrolledDropdown>
            )}
            <NavItem>
              <NavLink tag={Link} to="/contact">
                Contact us
              </NavLink>
            </NavItem>
          </Nav>
          <div className="newspaper">
            {!auth.userRole
              ? loginSignupButtons()
              : auth.isAuthenticated
              ? logoutButtons()
              : loginSignupButtons()}
          </div>
        </Collapse>
      </Navbar>
      {offset ? <div className="empty-div" /> : <div />}
    </div>
  )
}
