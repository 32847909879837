import { cities } from "./citiesList"

const city_list = [{ label: 'Select City', value: '', disabled: true },
...cities
]

const Gender = {
  label: 'Gender',
  name: 'gender',
  value: '',
  type: 'select',
  options: [
    { label: 'Select Gender', value: '', disabled: true },
    { label: 'Male', value: 'male' },
    { label: 'Female', value: 'female' },
    { label: 'Other', value: 'Other' },
  ],
}

const location = {
  label: 'City',
  name: 'City',
  value: '',
  required: false,
  type: 'select',
  options: city_list,
}

const Currency = {
  label: 'Currency*',
  name: 'SalaryRange.Currency',
  value: '',
  required: true,
  type: 'select',
  options: [
    { value: '', label: 'Currency', disabled: true },
    { value: 'PKR', label: 'PKR' },
    { value: 'USD', label: 'USD' },
    { value: 'EUR', label: 'EUR' },
  ],
}

const VACANCY_TYPES = [
  {
    label: 'Select Vacancy Type',
    value: '',
    disabled: true,
  },
  {
    label: 'Freelance',
    value: 'freelance',
  },
  {
    label: 'Full Time',
    value: 'full time',
  },
  {
    label: 'Part Time',
    value: 'part time',
  },
  {
    label: 'Intern',
    value: 'intern',
  },
  {
    label: 'Beginner',
    value: 'beginner',
  },
  {
    label: 'Intermediate',
    value: 'intermediate',
  },
  {
    label: 'Expert',
    value: 'expert',
  },
]

const VACANCY_CATEGORY = [
  {
    label: 'Select Vacancy Category',
    value: '',
    disabled: true,
  },
  { label: 'Accounting and Finance', value: 'accounting and finance' },
  { label: 'Audit and assurance', value: 'audit and assurance' },
  { label: 'Taxation and compliances', value: 'taxation and compliances' },
]

const MEMBER_SHIP = [
  {
    label: 'Select Subscription Type',
    value: '',
    disabled: true,
  },
  {
    value: 'premium',
    label: 'Premium',
  },
  {
    value: 'basic',
    label: 'Basic',
  },
]

// candidate filter
export const CANDIDATE_FILTER_FIELDS = [
  Gender,
  location,
  {
    label: 'Skills',
    name: 'skills',
    value: '',
    type: 'select',
    options: [],
  },
  {
    label: 'Qualifications',
    name: 'qualifications',
    value: '',
    type: 'select',
    options: [],
  },
]

// job filter
export const JOB_FILTER_FIELDS = [
  {
    label: 'Vacancy Type',
    name: 'VacancyType',
    value: '',
    type: 'select',
    options: VACANCY_TYPES,
  },

  {
    label: 'Vacancy Category',
    name: 'VacancyCategory',
    value: '',
    type: 'select',
    options: VACANCY_CATEGORY,
  },
  {
    label: 'Preferable Qualification',
    name: 'PreferableQualification',
    value: '',
    type: 'select',
    options: [],
  },
  {
    label: 'Salary Range From',
    name: 'SalaryRangeFrom',
    value: '',
    type: 'text',
  },
  {
    label: 'Salary Range To',
    name: 'SalaryRangeTo',
    value: '',
    type: 'text',
  },
  {
    label: 'Currency',
    name: 'SalaryRangeCurrency',
    value: '',
    type: 'text',
  },
]

// login
export const EMPLOYEE_LOGIN_FIELDS = [
  {
    label: 'Username*',
    name: 'Username',
    value: '',
    required: true,
    type: 'text',
  },
  {
    label: 'Password*',
    name: 'Password',
    value: '',
    required: true,
    type: 'password',
  },
]

export const EMPLOYER_LOGIN_FIELDS = [
  {
    label: 'Username*',
    name: 'Username',
    value: '',
    required: true,
    type: 'text',
  },
  {
    label: 'Password*',
    name: 'Password',
    value: '',
    required: true,
    type: 'password',
  },
  // {
  //   label: "Username",
  //   name: "Username",
  //   value: "adeel",
  //   required: true,
  //   type: "text",
  // },
  // {
  //   label: "Password",
  //   name: "Password",
  //   value: "adeelA1@",
  //   required: true,
  //   type: "password",
  // },
]

export const FORGET_PASSWORD_FIELDS = [
  {
    label: 'Forget Password*',
    name: 'ForgetPassword',
    required: true,
    type: 'email',
    value: '',
  },
]

// signup
export const EMPLOYEE_SIGNUP_FIELDS = [
  {
    placeholder: 'username',
    label: 'Username*',
    name: 'Username',
    id: 'CandUsername',
    value: '',
    required: true,
    type: 'text',
  },
  {
    placeholder: '*******',
    label: 'Password*',
    name: 'Password',
    id: 'CandPassword',
    value: '',
    required: true,
    type: 'password',
    hint: '(Atleast 8 Characters, 1 digit, 1 Uppercase letter and 1 special character)',
  },
  {
    placeholder: 'first Name',
    label: 'First name*',
    name: 'FirstName',
    value: '',
    required: true,
    type: 'text',
  },
  {
    placeholder: 'last Name',
    label: 'Last name*',
    name: 'LastName',
    value: '',
    required: true,
    type: 'text',
  },
  {
    placeholder: 'eg: abc@xyz.com',
    label: 'Email*',
    name: 'Email',
    value: '',
    required: true,
    type: 'email',
  },
  {
    placeholder: '+9203489845631',
    label: 'Phone Number*',
    name: 'PhoneNumber',
    value: '',
    required: true,
    type: 'text',
    hint: '+9203489845631',
  },
]

export const EMPLOYER_SIGNUP_FIELDS = [
  {
    label: 'Subscription Type*',
    name: 'subscriptionType',
    value: '',
    required: true,
    type: 'select',
    options: MEMBER_SHIP,
  },

  {
    label: 'Subscription Validity*',
    name: 'SubscriptionPeriod',
    value: '',
    required: true,
    type: 'select',
    options: [
      { value: '', label: 'Select Subscription Validity', disabled: true },
      { value: 1, label: 1 },
      { value: 2, label: 2 },
      { value: 3, label: 3 },
      { value: 4, label: 4 },
      { value: 5, label: 5 },
      { value: 6, label: 6 },
      { value: 7, label: 7 },
      { value: 8, label: 8 },
      { value: 9, label: 9 },
      { value: 10, label: 10 },
      { value: 11, label: 11 },
      { value: 12, label: 12 },
      { value: 13, label: 13 },
      { value: 14, label: 14 },
      { value: 15, label: 15 },
      { value: 16, label: 16 },
      { value: 17, label: 17 },
      { value: 18, label: 18 },
      { value: 19, label: 19 },
      { value: 20, label: 20 },
      { value: 21, label: 21 },
      { value: 22, label: 22 },
      { value: 23, label: 23 },
      { value: 24, label: 24 },
      { value: 25, label: 25 },
      { value: 26, label: 26 },
      { value: 27, label: 27 },
      { value: 28, label: 28 },
      { value: 29, label: 29 },
      { value: 30, label: 30 },
      { value: 31, label: 31 },
      { value: 32, label: 32 },
      { value: 33, label: 33 },
      { value: 34, label: 34 },
      { value: 35, label: 35 },
      { value: 36, label: 36 },
    ],
    hint: 'In Months',
  },
  {
    placeholder: 'username',
    label: 'User name*',
    name: 'Username',
    id: 'EmpUsername',
    value: '',
    required: true,
    type: 'text',
  },
  {
    placeholder: '*******',
    label: 'Password*',
    name: 'Password',
    id: 'EmpPassword',
    value: '',
    required: true,
    type: 'password',
    hint: '(Atleast 8 Characters, 1 digit, 1 Uppercase letter and 1 special character)',
  },
  {
    placeholder: 'Organization',
    label: 'Organization Name*',
    name: 'OrganizationName',
    value: '',
    required: true,
    type: 'text',
  },
  {
    placeholder: 'eg: info@apex-financecareers.com',
    label: 'Signup Email*',
    name: 'organizationEmail',
    value: '',
    required: true,
    type: 'email',
  },
  {
    placeholder: 'Firstname',
    label: 'Contact Person First Name*',
    name: 'contactPerson.FirstName',
    value: '',
    required: true,
    type: 'text',
  },
  {
    placeholder: 'Lastname',
    label: 'Contact Person Last Name*',
    name: 'contactPerson.LastName',
    value: '',
    required: true,
    type: 'text',
  },
  {
    placeholder: '+9203489845631',
    label: 'Contact Number*',
    name: 'contactPerson.PhoneNumber',
    value: '',
    required: true,
    type: 'text',
    hint: '+9203489845631',
  },
  {
    placeholder: 'eg: info@apex-financecareers.com',
    label: 'Contact Person Email',
    name: 'contactPerson.Email',
    value: '',
    required: false,
    type: 'email',
    hint: 'leave empty if same as above signup email',
  },
  {
    // placeholder: 'eg: info@apex-financecareers.com',
    label: 'Same as Organization Email',
    name: 'UseOrganizationEmail',
    value: '',
    required: false,
    type: 'checkbox',
    // hint: 'leave empty if same as above signup email',
  },
  {
    placeholder: 'Address',
    label: 'Communication Address',
    name: 'communicationAddress',
    value: '',
    required: false,
    type: 'text',
  },
  location,
  {
    placeholder: 'eg: https://www.apex-financecareers.com/',
    label: 'Website',
    name: 'Website',
    value: '',
    required: false,
    type: 'text',
  },
  {
    placeholder: 'eg: 3132433123123',
    label: 'NTN/STRN',
    name: 'STRN',
    value: '',
    required: false,
    type: 'text',
  },
  {
    placeholder: 'eg: +9203489845631',
    label: 'Organization Phone Number',
    name: 'organizationPhoneNumber',
    value: '',
    required: false,
    type: 'text',
    hint: '+9203489845631',
  },
]

// post job
export const JOB_FIELDS = [
  {
    placeholder: 'Vacancy Title',
    label: 'Vacancy Title*',
    name: 'VacancyTitle',
    value: '',
    required: true,
    type: 'text',
  },
  {
    label: 'Vacancy Type*',
    name: 'VacancyType',
    value: '',
    required: true,
    type: 'select',
    options: VACANCY_TYPES,
  },
  {
    label: 'Vacancy Category*',
    name: 'VacancyCategory',
    value: '',
    required: true,
    type: 'select',
    options: VACANCY_CATEGORY,
  },
  {...location, name :'Location'},
  {
    label: 'Closing Date*',
    name: 'ClosingDate',
    value: '',
    required: true,
    type: 'date',
  },
  {
    label: 'Preferable Qualification*',
    name: 'PreferableQualification',
    value: '',
    required: true,
    type: 'select',
    options: [],
  },
  {
    label: 'Salary Range From*',
    name: 'SalaryRange.From',
    value: '',
    required: true,
    type: 'number',
  },
  {
    label: 'Salary Range To*',
    name: 'SalaryRange.To',
    value: '',
    required: true,
    type: 'number',
  },
  Currency,
  {
    label: 'Set Default Company Details*',
    name: 'SetDefaultCompanyDetails',
    value: true,
    required: true,
    type: 'select',
    options: [
      { label: 'yes', value: true },
      { label: 'no', value: false },
    ],
  },
  {
    label: 'Job Description*',
    name: 'JobDescription',
    value: '',
    required: true,
    type: 'textarea',
  },
]

export const COMPANY_DETAIL_FIELDS = [
  {
    label: 'Company Name*',
    name: 'CompanyDetails.CompanyName',
    value: '',
    required: true,
    type: 'text',
  },
  {
    label: 'Contact Person Name*',
    name: 'CompanyDetails.ContactPersonName',
    value: '',
    required: true,
    type: 'text',
  },
  {
    label: 'Contact Person Phone Number*',
    placeholder: '+9203489845631',
    name: 'CompanyDetails.ContactPersonFirstPhoneNumber',
    value: '',
    required: true,
    type: 'text',
    hint: '+9203489845631',
  },
  {
    label: 'Contact Person Email*',
    name: 'CompanyDetails.ContactPersonFirstEmail',
    value: '',
    required: true,
    type: 'email',
  },
]

// profile
export const CANDIDATE_PROFILE_FIELDS = [
  {
    label: 'User name',
    name: 'Username',
    value: '',
    required: true,
    type: 'text',
  },
  {
    label: 'First Name',
    name: 'FirstName',
    value: '',
    required: true,
    type: 'text',
  },
  {
    label: 'Last Name',
    name: 'LastName',
    value: '',
    required: true,
    type: 'text',
  },
  {
    label: 'Phone Number',
    name: 'PhoneNumber',
    value: '',
    required: true,
    type: 'text',
  },
  {
    label: 'Email',
    name: 'Email',
    value: '',
    required: true,
    type: 'email',
  },
]

export const EMPLOYER_PROFILE_FIELDS = [
  {
    label: 'User name',
    name: 'Username',
    value: '',
    required: true,
    type: 'text',
  },
  {
    label: 'First Name',
    name: 'contactPerson.FirstName',
    value: '',
    required: true,
    type: 'text',
  },
  {
    label: 'Last Name',
    name: 'contactPerson.LastName',
    value: '',
    required: true,
    type: 'text',
  },
  {
    label: 'Phone Number',
    name: 'contactPerson.PhoneNumber',
    value: '',
    required: true,
    type: 'text',
  },
  {
    label: 'Email',
    name: 'contactPerson.Email',
    value: '',
    required: true,
    type: 'email',
  },
  location,
  {
    label: 'Organization Name',
    name: 'OrganizationName',
    value: '',
    required: true,
    type: 'text',
  },
  {
    label: 'STRN',
    name: 'STRN',
    value: '',
    required: false,
    type: 'text',
  },
  {
    label: 'Subscription Validity',
    name: 'SubscriptionValidity',
    value: '',
    required: true,
    type: 'select',
    options: [
      { value: 2, label: 2 },
      { value: 3, label: 3 },
      { value: 4, label: 4 },
      { value: 5, label: 5 },
    ],
  },
  {
    label: 'Website',
    name: 'Website',
    value: '',
    required: true,
    type: 'text',
  },
  {
    label: 'Communication Address',
    name: 'communicationAddress',
    value: '',
    required: true,
    type: 'text',
  },
  {
    label: 'Organization Email',
    name: 'organizationEmail',
    value: '',
    required: true,
    type: 'email',
  },
  {
    label: 'Organization PhoneNumber',
    name: 'organizationPhoneNumber',
    value: '',
    required: true,
    type: 'text',
  },
  {
    label: 'Subscription Type',
    name: 'subscriptionType',
    value: '',
    required: true,
    type: 'select',
    options: MEMBER_SHIP,
  },
]

export const CHANGE_PASSWORD_FIELDS = [
  {
    label: 'Old Password*',
    name: 'oldPassword',
    value: '',
    required: true,
    type: 'password',
  },
  {
    label: 'New Password*',
    name: 'newPassword',
    value: '',
    required: true,
    type: 'password',
  },
  {
    label: 'Retype New Password*',
    name: 'retypeNewPassword',
    value: '',
    required: true,
    type: 'password',
  },
]
