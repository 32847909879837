import { Buffer } from 'buffer'
import { useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import {
  getApplicationsByJobIdAction,
  updateApplicationsAction,
} from '../store/actions/applicationsAction'
import { JobDetailsById } from './JobDetailsById'

export default function JobListing() {
  const { jobId } = useParams()
  const dispatch = useDispatch()
  const { auth, getApplicationsByJobId, getJob, getQualifications } = useSelector((state) => state)


  useEffect(() => dispatch(getApplicationsByJobIdAction({ id: jobId })), [dispatch, jobId])

  const handleApplicationStatusChange = (value, id) =>{
    dispatch(updateApplicationsAction({ Status: { Now: value } }, id, jobId))

  }

  

  return (
    <div>
      <section className="section breadcrumbs-custom breadcrumbs-custom-overlay-4">
        <div id="jobListing-bg" className="breadcrumbs-custom-main bg-image bg-gray-700">
          <div className="container">
            <h3 className="breadcrumbs-custom-title">Job Details</h3>
          </div>
        </div>
      </section>
      <section className="section section-md">
        <div className="container">
          <div className="row">
            <div className="col-12">
                <JobDetailsById jobId={jobId} />
            </div>
          </div>
          <div className="block-form">
                  
          <h4>Job Applications</h4>
          <hr />
          </div>
          <div className="row row-50 flex-lg-row-reverse">
            <div className="col-lg-12 ">
              {getApplicationsByJobId.getApplicationsByJobIdLoading ? (
                'Loading applications...'
              ) : getApplicationsByJobId.getApplicationsByJobIdSuccess.message?.[0] ===
                'no data found' ? (
                <p>No application found for this job</p>
              ) : (
                <table className="table-job-listing table-responsive">
                  <tbody>
                    {getApplicationsByJobId?.getApplicationsByJobIdSuccess?.JobApplication?.map(
                      (application, index) => (
                        <tr key={index}>
                          <td className="table-job-listing-main">
                            <Link
                              to={`/resume/${application.Resume[0].CandidateId}/${jobId}/${application._id}`}
                            >
                              <article className="company-minimal">
                                <figure className="company-minimal-figure">
                                  {application?.Resume?.[0]?.ImageURL?.data && (
                                    <img
                                      className="company-minimal-image"
                                      src={`data:jpg;base64,${Buffer.from(
                                        application.Resume[0].ImageURL.data
                                      ).toString('base64')}`}
                                      alt=""
                                    />
                                  )}
                                </figure>
                                <div className="company-minimal-main">
                                  <h5 className="company-minimal-title cursor-pointer">
                                    {application.Resume[0].GeneralInformation.Profession}
                                  </h5>
                                  <p>
                                    {application.Candidate[0].FirstName +
                                      ' ' +
                                      application.Candidate[0].LastName +
                                      ' ' +
                                      `(${application.Candidate[0].Email})`}
                                  </p>
                                </div>
                              </article>
                            </Link>
                          </td>
                          <td className="table-job-listing-date">
                            {/* <span>{job.createdAt.substring(0, 10)} - {job.ClosingDate.substring(0, 10)}</span> */}
                          </td>
                          <td className="table-job-listing-badge">
                            {auth?.subscriptionType === 'premium' && (
                              <select
                                className="badge"
                                defaultValue={application.Status.Now}
                                onChange={(e) => {
                                  handleApplicationStatusChange(e.target.value, application._id)
                                }}
                              >
                                <option value="short-list">Short List</option>
                                <option value="best-fit">Best Fit</option>
                                <option value="not-fit">Not Fit</option>
                                <option value="pending">Pending</option>
                                <option value="rejected">Reject</option>
                              </select>
                            )}
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
