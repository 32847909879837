import about from './assets/images/about.jpg'
import { ReactComponent as Logo } from './assets/images/user.svg'

export default function WorkPlace() {
  return (
    <div>
      <section className="section section-md bg-gray-100 text-center">
        <div className="container">
          <div className="mb-5">
            <h3>Welcome to Career Finance </h3>
            <p>
              A place where leading employers are looking for talent and experience for their
              Organization.
            </p>
          </div>
          <div className="row row-50 justify-content-center align-items-center text-left">
            <div className="col-md-10 col-lg-6">
              <figure className="figure-responsive block-5">
                <img src={about} alt="" />
              </figure>
            </div>
            <div className="col-md-10 col-lg-6">
              <div className="row-bordered-1-outer">
                <div className="row row-bordered-1">
                  <div className="col-sm-6">
                    <article className="box-line box-line_sm">
                      <div className="box-line-inner">
                        <div className="box-line-icon icon mercury-icon-group">
                          <Logo className="wpicons" />
                        </div>
                        <h5 className="box-line-title">
                          Pakistan’s pioneer and the only dedicated job site for Finance & Commerce
                        </h5>
                      </div>
                    </article>
                  </div>
                  <div className="col-sm-6">
                    <article className="box-line box-line_sm">
                      <div className="box-line-inner">
                        <div className="box-line-icon icon mercury-icon-partners">
                          <Logo className="wpicons" />
                        </div>
                        <h5 className="box-line-title">
                        First choice for recruiters looking for new or experienced candidates
                        </h5>
                      </div>
                    </article>
                  </div>
                  <div className="col-sm-6">
                    <article className="box-line box-line_sm">
                      <div className="box-line-inner">
                        <div className="box-line-icon icon mercury-icon-chat">
                          <Logo className="wpicons" />
                        </div>
                        <h5 className="box-line-title">Free for candidates and only a nominal fee for employers </h5>
                      </div>
                    </article>
                  </div>
                  <div className="col-sm-6">
                    <article className="box-line box-line_sm">
                      <div className="box-line-inner">
                        <div className="box-line-icon icon mercury-icon-target">
                          <Logo className="wpicons" />
                        </div>
                        <h5 className="box-line-title">Professional, eye catching and easy to use interface </h5>
                      </div>
                    </article>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
