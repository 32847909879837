import { useSelector } from 'react-redux'
import { ReactComponent as Logo } from './assets/images/user.svg'

export default function steps() {
  const { auth, getJobs, getMyJobs, getQualifications } = useSelector((state) => state)
  console.log(auth);
  return (
    <div>
      <section className="section section-md text-center">
        <div className="container">
          <h3>Just 3 Easy Steps to New Capabilities</h3>
          <ul className="list-linked">
            <li className="ll-item">
              <div className="icon ll-item-icon thin-icon-email-search">
                <Logo className="iconimgs3" />
              </div>
              <div className="ll-item-main">
                <h5 className="ll-item-title">
                  <a href="job-listing.html">Browse Jobs</a>
                </h5>
                <p>Easy search by category</p>
              </div>
            </li>
            <li className="ll-item">
              <div className="icon ll-item-icon mercury-icon-target-2">
                <Logo className="iconimgs3" />
              </div>
              <div className="ll-item-main">
                <h5 className="ll-item-title">
                  <a href="job-details.html">Find Your Vacancy</a>
                </h5>
                <p>Choose a suitable job</p>
              </div>
            </li>
            <li className="ll-item">
              <div className="icon ll-item-icon ll-item-icon-sm mercury-icon-e-mail-o">
                <Logo className="iconimgs3" />
              </div>
              <div className="ll-item-main">
                <h5 className="ll-item-title">
                  <a href="submit-resume.html">Submit Resume</a>
                </h5>
                <p>Get a personal job offer</p>
              </div>
            </li>
            <li className="ll-item">
              <div className="icon ll-item-icon mercury-icon-touch">
                <Logo className="iconimgs3" />
              </div>
              <div className="ll-item-main">
                <a
                  className="button button-sm button-primary-outline button-anorak"
                  href={auth.userRole === 'candidate' ? `job.html` : '#'}
                >
                  Start Now
                </a>
              </div>
            </li>
          </ul>
        </div>
      </section>
    </div>
  )
}
