import { checkTokenExpiration } from './authAction'
import { errorHandler } from '../../component/errorHandler/errorHandler'
import {
  GET_RESUME_SUCCESS,
  GET_RESUME_ERROR,
  GET_RESUME_LOADING,
  GET_CANDIDATE_LOADING,
  GET_CANDIDATE_SUCCESS,
  GET_CANDIDATE_ERROR,
} from '../types'
import { getCandidateByIdCall, getCandidates } from '../../component/network/axios'

export const getCandidateAction = (formData) => {
  return async (dispatch) => {
    dispatch({ type: GET_CANDIDATE_LOADING })
    try {
      const data = await getCandidates({ params: formData })
      dispatch({ type: GET_CANDIDATE_SUCCESS, payload: data.data })
    } catch (error) {
      errorHandler({ error })
      dispatch({ type: GET_CANDIDATE_ERROR, payload: error.message })

      checkTokenExpiration({ dispatch, parentFunction: getCandidateAction, error })
    }
  }
}

export const getCandidateByIdAction = (formData) => {
  return async (dispatch) => {
    dispatch({ type: GET_RESUME_LOADING })
    try {
      const data = await getCandidateByIdCall({ params: formData })
      dispatch({ type: GET_RESUME_SUCCESS, payload: { Resume: data.data.Candidate[0].Resume[0] } })
    } catch (error) {
      errorHandler({ error })
      dispatch({ type: GET_RESUME_ERROR, payload: error.message })

      checkTokenExpiration({ dispatch, parentFunction: getCandidateByIdAction, error })
    }
  }
}
