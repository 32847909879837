import { checkTokenExpiration } from './authAction'
import { SUCCESS_NOTIFIER, toastNotifier } from '../../component/toaster/Toaste'
import { errorHandler } from '../../component/errorHandler/errorHandler'
import { UPDATE_PASSWORD_LOADING, UPDATE_PASSWORD_SUCCESS, UPDATE_PASSWORD_ERROR } from '../types'
import { updatePasswordCall } from '../../component/network/axios'

export const updatePasswordAction = (formData) => {
  return async (dispatch) => {
    dispatch({ type: UPDATE_PASSWORD_LOADING })
    try {
      const data = await updatePasswordCall({ body: formData })
      dispatch({ type: UPDATE_PASSWORD_SUCCESS, payload: data.data })
      toastNotifier({ type: SUCCESS_NOTIFIER, message: 'Password Changed' })
    } catch (error) {
      errorHandler({ error })
      dispatch({ type: UPDATE_PASSWORD_ERROR, payload: error.message })

      checkTokenExpiration({
        dispatch,
        parentFunction: updatePasswordAction,
        error,
      })
    }
  }
}
