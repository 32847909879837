import InfiniteCarousel from 'react-leaf-carousel'

import about from './assets/images/about.jpg'
import { ReactComponent as Logo } from '../component/assets/images/user.svg'

export default function AboutUs() {
  return (
    <div>
      <section className="section breadcrumbs-custom breadcrumbs-custom-overlay-2 margint">
        <div id="aboutbg" className="breadcrumbs-custom-main bg-image bg-gray-700">
          <div className="container">
            <h3 className="breadcrumbs-custom-title">About Us</h3>
          </div>
        </div>
      </section>
      <section className="section section-md">
        <div className="container">
          <div className="row row-30">
            <div className="col-lg-6 height-fill">
              <figure className="figure-responsive">
                <img src={about} alt="" />
              </figure>
            </div>
            <div className="col-lg-6 height-fill">
              <article className="box-info">
                <h3>Welcome to Finance Careers</h3>
                <p>
                Pakistan is blessed to have hugely talented pool of Commerce and Finance professionals. Our professional workforce enjoys remarkable reputation, not only in Pakistan but all over the world. 
                </p>
                <p>
                FinanceCareers is intensely passionate about linking the right fit candidates to the employers. We are proud to help job seekers by giving them free access to search for jobs, post resumes, and research companies. Every day, we connect candidates to exciting new job opportunities.
                </p>
                <p>
                Our industry specific and highly effective platform makes job add posting extremely simple and efficient. Employers can see the resume of interested candidates. Premium subscribers are also allowed quick and easy access to the pool of talented workforce, so they can choose the skills and experience they want.
                </p>
              </article>
            </div>
          </div>
        </div>
        <div className="container offset-top-1 text-center">
          <h3>Hundreds of Jobs in Cities All Over Pakistan</h3>
          <p id="for-spacing">With FinanceCareers you can find the job of your dreams in seconds!</p>
          <div className="row row-50 row-bordered justify-content-center">
            <div className="col-sm-6 col-md-4">
              <article className="box-line">
                <Logo className="about-icon box-line-icon icon mercury-icon-group" />
                <div className="box-line-divider" />
                <h5 className="box-line-title">Only relevant and verified vacancies 24/7</h5>
              </article>
            </div>
            <div className="col-sm-6 col-md-4">
              <article className="box-line">
                <Logo className="about-icon box-line-icon icon mercury-icon-group" />
                <div className="box-line-divider" />
                <h5 className="box-line-title">More than 3.8 million visitors every day</h5>
              </article>
            </div>
            <div className="col-sm-6 col-md-4">
              <article className="box-line">
                <Logo className="about-icon box-line-icon icon mercury-icon-group" />
                <div className="box-line-divider" />
                <h5 className="box-line-title">Leading recruiting website in the US and Europe</h5>
              </article>
            </div>
          </div>
        </div>
      </section>


      {/* <section>
        <section className="section parallax-container section-md text-center">
          <div className="parallax-content">
            <div className="container container-fullwidth">
              <div className="mb-5">
                <h3>Companies We've Helped</h3>
              </div>
              <InfiniteCarousel
                breakpoints={[
                  {
                    breakpoint: 500,
                    settings: {
                      slidesToShow: 2,
                      slidesToScroll: 2,
                    },
                  },
                  {
                    breakpoint: 768,
                    settings: {
                      slidesToShow: 3,
                      slidesToScroll: 3,
                    },
                  },
                ]}
                dots={true}
                sidesOpacity={0.5}
                slidesToScroll={5}
                slidesToShow={4}
                animationDuration={1000}
                lazyLoad={true}
                arrows={false}
                scrollOnDevice={true}
              >
                <div>
                  <div className="box-creative">
                    <div className="box-creative-inner">
                      <div>
                        <Logo className="newcataicon" />
                      </div>
                    </div>
                    <div className="box-creative-dummy" />
                  </div>
                </div>
                <div>
                  <div className="box-creative">
                    <div className="box-creative-inner">
                      <div>
                        <Logo className="newcataicon" />
                      </div>
                    </div>
                    <div className="box-creative-dummy" />
                  </div>
                </div>
                <div>
                  <div className="box-creative">
                    <div className="box-creative-inner">
                      <div>
                        <Logo className="newcataicon" />
                      </div>
                    </div>
                    <div className="box-creative-dummy" />
                  </div>
                </div>
                <div>
                  <div className="box-creative">
                    <div className="box-creative-inner">
                      <div>
                        <Logo className="newcataicon" />
                      </div>
                    </div>
                    <div className="box-creative-dummy" />
                  </div>
                </div>
                <div>
                  <div className="box-creative">
                    <div className="box-creative-inner">
                      <div>
                        <Logo className="newcataicon" />
                      </div>
                    </div>
                    <div className="box-creative-dummy" />
                  </div>
                </div>
                <div>
                  <div className="box-creative">
                    <div className="box-creative-inner">
                      <div>
                        <Logo className="newcataicon" />
                      </div>
                    </div>
                    <div className="box-creative-dummy" />
                  </div>
                </div>
                <div>
                  <div className="box-creative">
                    <div className="box-creative-inner">
                      <div>
                        <Logo className="newcataicon" />
                      </div>
                    </div>
                    <div className="box-creative-dummy" />
                  </div>
                </div>
                <div>
                  <div className="box-creative">
                    <div className="box-creative-inner">
                      <div>
                        <Logo className="newcataicon" />
                      </div>
                    </div>
                    <div className="box-creative-dummy" />
                  </div>
                </div>
                <div>
                  <div className="box-creative">
                    <div className="box-creative-inner">
                      <div>
                        <Logo className="newcataicon" />
                      </div>
                    </div>
                    <div className="box-creative-dummy" />
                  </div>
                </div>
                <div>
                  <div className="box-creative">
                    <div className="box-creative-inner">
                      <div>
                        <Logo className="newcataicon" />
                      </div>
                    </div>
                    <div className="box-creative-dummy" />
                  </div>
                </div>
                <div>
                  <div className="box-creative">
                    <div className="box-creative-inner">
                      <div>
                        <Logo className="newcataicon" />
                      </div>
                    </div>
                    <div className="box-creative-dummy" />
                  </div>
                </div>
                <div>
                  <div className="box-creative">
                    <div className="box-creative-inner">
                      <div>
                        <Logo className="newcataicon" />
                      </div>
                    </div>
                    <div className="box-creative-dummy" />
                  </div>
                </div>
                <div>
                  <div className="box-creative">
                    <div className="box-creative-inner">
                      <div>
                        <Logo className="newcataicon" />
                      </div>
                    </div>
                    <div className="box-creative-dummy" />
                  </div>
                </div>
              </InfiniteCarousel>
            </div>
          </div>
        </section>
      </section> */}
    </div>
  )
}
