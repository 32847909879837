import { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { serialize } from 'object-to-formdata'

import FormInput from './formFields/FormInput'
import { qualificationToSelect } from './helper/parse'
import { getJobAction } from '../store/actions/jobsAction'
import { getProfileAction } from '../store/actions/profileAction'
import { COMPANY_DETAIL_FIELDS, JOB_FIELDS } from './helper/inputConstants'
import { postJobAction, updateJobAction } from '../store/actions/myJobAction'
import { getQualificationsAction } from '../store/actions/qualificationsAction'
import { SUCCESS_NOTIFIER, toastNotifier } from './toaster/Toaste'
import { postJobsAddCall, updateJobsAddCall } from './network/axios'
import { checkTokenExpiration } from '../store/actions/authAction'
import { errorHandler } from './errorHandler/errorHandler'
import { POST_JOB_ERROR, POST_JOB_SUCCESS, UPDATE_JOB_ERROR, UPDATE_JOB_SUCCESS } from '../store/types'

export default function PostAJob() {

  const navigate = useNavigate()
  const { jobId } = useParams()
  const dispatch = useDispatch()
  const [jobDetails, setJobDetails] = useState(JOB_FIELDS)
  const [defaultCompanyDetails, setDefaultCompanyDetails] = useState(true)
  const [companyDetails, setCompanyDetails] = useState(COMPANY_DETAIL_FIELDS)
  const [minRange, setMinRange] = useState(null)
  const [loading, setLoading] = useState(false)
  const { getJob, getQualifications, getProfile } = useSelector((state) => state)

  const [logo, setLogo] = useState()

  const resetFields = () => setJobDetails(JOB_FIELDS)

  const handleSubmit = (e) => {
    e.preventDefault()
    const formData = {}
    setLoading(true)
    jobDetails.filter((item) => (formData[item.name] = item.value))
    !defaultCompanyDetails && companyDetails.filter((item) => (formData[item.name] = item.value))
    formData.Logo = logo
    const payload = serialize(formData)
    // if (jobId) dispatch(updateJobAction(formData, { id: jobId }))
    // else dispatch(postJobAction(payload, resetFields))
    if (jobId) {
      console.log(jobId)
      updateJobsAddCall({ body: payload, params: { id: jobId } }).then((e)=>{
        dispatch({ type: UPDATE_JOB_SUCCESS, payload: e.data })
        toastNotifier({ type: SUCCESS_NOTIFIER, message: 'Job updated' })
        setLoading(false)
        navigate(`/job-applications/${jobId}`)
        e.target.reset()
        resetFields()
      })
      .catch((error)=>{
        errorHandler({ error })
        dispatch({ type: UPDATE_JOB_ERROR, payload: error.message })
        checkTokenExpiration({
          dispatch,
          parentFunction: updateJobAction,
          error,
        })
        setLoading(false)
      })

    }
     else {
      postJobsAddCall({ body: payload }).then((e)=>{
        dispatch({ type: POST_JOB_SUCCESS, payload: e.data })
        toastNotifier({ type: SUCCESS_NOTIFIER, message: 'Job created' })
        navigate(`/jobs`)
        onSuccess()
        setLoading(false)
        e.target.reset()
        resetFields()
      })
      .catch((error)=>{
        errorHandler({ error })
      dispatch({ type: POST_JOB_ERROR, payload: error.message })

      checkTokenExpiration({
        dispatch,
        parentFunction: postJobAction,
        error,
      })
      setLoading(false)
      })

      // dispatch(postJobAction(payload, resetFields))
    }
   
  }

  useEffect(() => dispatch(getQualificationsAction()), [dispatch])
  useEffect(() => {
    setJobDetails(
      jobDetails.map((job) =>
        job.name === 'PreferableQualification'
          ? {
              ...job,
              options: qualificationToSelect(getQualifications),
            }
          : job
      )
    )
  }, [getQualifications])

  useEffect(() => {
    if (jobId) dispatch(getJobAction({ id: jobId }))
    dispatch(getProfileAction())

  }, [dispatch, jobId])
  
  const setResData = (setState, state) => {
    setState(
      state.map((item) => {
        const key1 = item.name.split('.')[0]
        const key2 = item.name.split('.')[1]
        if (item.name === 'ClosingDate') {
          return { ...item, value: getJob.getJobSuccess.JobAd[key1].split('T')[0] }
        }
        return key2
          ? { ...item, value: getJob.getJobSuccess.JobAd[key1][key2] }
          : { ...item, value: getJob.getJobSuccess.JobAd[key1] }
      })
    )
  }

  useEffect(() => {
    if (jobId && getJob.getJobSuccess.JobAd && getProfile.getProfileSuccess.Employer) {
      setResData(setJobDetails, jobDetails)
      if (getJob.getJobSuccess.JobAd.CompanyDetails) {
        setResData(setCompanyDetails, companyDetails)
      } else {
        setCompanyDetails(
          companyDetails.map((item) => {
            if (item.name === 'CompanyDetails.CompanyName')
              return { ...item, value: getProfile.getProfileSuccess.Employer.OrganizationName }
            if (item.name === 'CompanyDetails.ContactPersonName')
              return {
                ...item,
                value: getProfile.getProfileSuccess.Employer.contactPerson.FirstName,
              }
            if (item.name === 'CompanyDetails.ContactPersonFirstPhoneNumber')
              return {
                ...item,
                value: getProfile.getProfileSuccess.Employer.contactPerson.PhoneNumber,
              }
            if (item.name === 'CompanyDetails.ContactPersonFirstEmail')
              return { ...item, value: getProfile.getProfileSuccess.Employer.contactPerson.Email }
            return item
          })
        )
      }
    } else {
      setJobDetails(JOB_FIELDS)
    }
  }, [getJob, jobId])

  useEffect(
    () =>
      jobDetails.map(
        (item) =>
          item.name === 'SetDefaultCompanyDetails' &&
          item.value &&
          setDefaultCompanyDetails(JSON.parse(item.value + ''))
      ),
    [jobDetails]
  )

  const handleJobDetailsInputChange = (e) => {


    if(e.target.name === "SalaryRange.From"){
      setMinRange(e.target.value)
    }

    setJobDetails([
      ...jobDetails.map((item) =>
        item.name === e.target.name ? { ...item, value: e.target.value } : item
      ),
    ])
  }

  const handleCompanyDetailsInputChange = (e) => {
    setCompanyDetails([
      ...companyDetails.map((item) =>
        item.name === e.target.name ? { ...item, value: e.target.value } : item
      ),
    ])
  }


  

  return (
    <div>
      {/* {loading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
          }}
        >
          <h1 style={{ fontSize: '1.5em' }}>LOADING...</h1>
        </div>
      ) : ( */}
        <>
          <section className="section breadcrumbs-custom breadcrumbs-custom-overlay-2">
            <div id="postbg" className="breadcrumbs-custom-main bg-image bg-gray-700">
              <div className="container">
                <h3 className="breadcrumbs-custom-title">Post a Job</h3>
              </div>
            </div>
          </section>
          <section className="section section-md">
            <form className="rd-mailform" id="create-job-form" onSubmit={(e)=>handleSubmit(e)} />
            <div className="container">
              {/* General Information */}
              <div className="block-form">
                <h4>General Information</h4>
                <hr />
                <div className="rd-form rd-mailform form-lg">
                  <div className="row row-40">
                    {jobDetails.map((field, index) => (
                      <div
                        key={index}
                        className={`${field.type === 'textarea' ? 'col-md-12' : 'col-md-6'}`}
                      >
                        <div className="form-wrap">
                          {FormInput({
                            minRange: field.label ===  "Vacancy Title*" && field.value ? field.value.toString() : null,
                            field,
                            form: 'create-job-form',
                            onChange: handleJobDetailsInputChange,
                          })}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              {/* Company Details */}
              {!defaultCompanyDetails && (
                <div className="block-form">
                  <h4>Company Details</h4>
                  <hr />
                  <div className="rd-form rd-mailform form-lg">
                    <div className="row row-40">
                      {companyDetails.map((field, index) => (
                        <div key={index} className="col-md-6">
                          <div className="form-wrap">
                            {FormInput({
                              minRange: field.label ===  "Salary Range To*" ? minRange.toString(): null,
                              field,
                              form: 'create-job-form',
                              onChange: handleCompanyDetailsInputChange,
                            })}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}
              {/* Add Files */}
              <div className="block-form">
                <h4>Add Files</h4>
                <hr />
                <span className={logo?.name ? 'file-preview' : ''}>
                  <p>{logo?.name ? logo.name : ''}</p>
                </span>
                <label
                  className="button button-sm button-primary-outline button-icon button-icon-left"
                  htmlFor="Logo"
                >
                  <input
                    type="file"
                    id="Logo"
                    name="Logo"
                    form="create-job-form"
                    onChange={(e) => setLogo(e.target.files[0])}
                    required={!getJob?.getJobSuccess?.JobAd?.Logo?.type}
                  />
                  <span className="icon mdi mdi-account-box" />
                  Add Your logo*
                </label>
               
              </div>

              <div className="block-form">
                <hr />
                {loading ? <button disabled form="create-job-form" className="button button-muted">
                  {jobId ? 'Updating Job...' : 'Posting Job...'}
                </button> : <button type="submit" form="create-job-form" className="button button-secondary">
                  {jobId ? 'Update Job' : 'Post Job'}
                </button>}
              </div>
            </div>
          </section>
        </>
     
    </div>
  )
}
