import {
  GET_JOB_LOADING,
  GET_JOB_SUCCESS,
  GET_JOB_ERROR,
  GET_JOBS_LOADING,
  GET_JOBS_SUCCESS,
  GET_JOBS_ERROR,
} from '../types'

const getJobReducerInitialState = {
  getJobLoading: false,
  getJobSuccess: [],
  getJobError: '',
}

export const getJobReducer = (state = getJobReducerInitialState, { type, payload }) => {
  switch (type) {
    case GET_JOB_LOADING:
      return {
        ...state,
        getJobLoading: true,
      }
    case GET_JOB_SUCCESS:
      return {
        ...state,
        getJobLoading: false,
        getJobSuccess: payload,
      }
    case GET_JOB_ERROR:
      return {
        ...state,
        getJobLoading: false,
        getJobError: payload,
      }

    default:
      return state
  }
}

const getJobsReducerInitialState = {
  getJobsLoading: true,
  getJobsSuccess: [],
  getJobsError: '',
}

export const getJobsReducer = (state = getJobsReducerInitialState, { type, payload }) => {
  switch (type) {
    case GET_JOBS_LOADING:
      return {
        ...state,
        getJobsLoading: true,
      }
    case GET_JOBS_SUCCESS:
      return {
        ...state,
        getJobsLoading: false,
        getJobsSuccess: payload,
      }
    case GET_JOBS_ERROR:
      return {
        ...state,
        getJobsLoading: false,
        getJobsError: payload,
      }

    default:
      return state
  }
}
