import { Navigate, useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import ReCaptchaV2 from 'react-google-recaptcha'
import SwitchSelector from 'react-switch-selector'

import FormInput from './formFields/FormInput'
import { candidateSignUp, checkAuth, employerSignUp } from '../store/actions/authAction'
import { EMPLOYEE_SIGNUP_FIELDS, EMPLOYER_SIGNUP_FIELDS } from './helper/inputConstants'
import { INFO_NOTIFIER, toastNotifier } from './toaster/Toaste'
import './assets/css/style.css'
import img from './assets/images/loginSignup.svg'

export default function SignUp () {
  const formData = {}
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated)
  const [candidate, setCandidate] = useState(EMPLOYEE_SIGNUP_FIELDS)
  const [employer, setEmployer] = useState(EMPLOYER_SIGNUP_FIELDS)
  const [typeBasic, setTypeBasic] = useState(false)
  const [candCaptch, setCandCaptch] = useState('')
  const [empCaptch, setEmpCaptch] = useState('')
  const [sectionToggle, setSectionToggle] = useState(false)

  const handleRegisterCandidate = e => {
    e.preventDefault()
    if (!candCaptch) {
      toastNotifier({ type: INFO_NOTIFIER, message: 'Fill the reCaptcha' })
      return
    }

    candidate.filter(item => (formData[item.name] = item.value))
    dispatch(candidateSignUp({ ...formData, token: candCaptch },navigate))
  }



  

  const handleRegisterEmployer = e => {
    e.preventDefault()

    if (!empCaptch) {
      toastNotifier({ type: INFO_NOTIFIER, message: 'Fill the reCaptcha' })
      return
    }

    employer.filter(item => {
      if (item.name === 'contactPerson.Email')
        formData.contactPerson = item.value
          ? { Email: item.value }
          : { Email: formData.organizationEmail }
      else formData[item.name] = item.value
    })


    if(formData.subscriptionType === 'basic'){
      delete formData.SubscriptionPeriod
    }


  const objKeys = Object.keys(formData)

    objKeys.forEach((e)=>{
      if(formData[e] === ''){
        delete formData[e]
      }
    })


    dispatch(employerSignUp(formData,navigate))
  }

  const handleCandidateInputChange = e => {
    setCandidate([
      ...candidate.map(item =>
        item.name === e.target.name ? { ...item, value: e.target.value } : item
      ),
    ])
  }
  const handleEmployerInputChange = e => {

    if(e.target.name === 'subscriptionType'){
      if(e.target.value === 'basic'){

        setTypeBasic(true)
      }
      else{
        setTypeBasic(false)
        
      }
    }



    setEmployer([
      ...employer.map(item =>
        item.name === e.target.name ? { ...item, value: e.target.value } : item
      ),
    ])
  }

  useEffect(() => dispatch(checkAuth()), [dispatch])

  if (isAuthenticated) {
    return <Navigate to='/' />
  }

  const options = [
    {
      label: 'Sign up as Employer',
      value: {
        foo: true,
      },
      selectedBackgroundColor: '#4d71a8',
      selectedFontColor: 'white',
    },
    {
      label: 'Sign up as Job Seeker',
      value: 'bar',
      selectedBackgroundColor: '#4d71a8',
      selectedFontColor: 'white',
    },
  ]

  return (
    <div className='container'>
      <div
        className='your-required-wrapper'
        style={{ width: 300, height: 45, width: '300px', height: '45px', margin: '8rem auto 2rem' }}
      >
        <SwitchSelector
          onChange={() => setSectionToggle(!sectionToggle)}
          options={options}
          // initialSelectedIndex={initialSelectedIndex}
          backgroundColor='#dbdede'
          fontColor='black'
          border='1px solid #4d71a8  '
          selectionIndicatorMargin={3}
          wrapperBorderRadius={40}
          optionBorderRadius={40}
          fontWeight='bold'
        />
      </div>
      <div className='row'>
          <div className='login-signup-wrapper'>
            <div className='login-signup-svg'>
              <img src={img} alt='login/signup' />
            </div>
            {/* Register-Candidate */}
            {sectionToggle ? (
              <>
                <div className='col-md-6 mt-5 mb-5'>
                  <h1>Sign Up as Job Seeker</h1>
                  <br />
                  {candidate.map((field, index) => (
                    <div key={index} className='col-lg-9 col-md-12 mb-4'>
                      {FormInput({
                        field:{ ...field, typeBasic:false },
                        form: 'Register-Candidate',
                        onChange: handleCandidateInputChange,
                      })}
                    </div>
                  ))}
                  <form id='Register-Candidate' onSubmit={handleRegisterCandidate}>
                    <ReCaptchaV2
                      sitekey={process.env.REACT_APP_SITE_KEY}
                      onChange={token => setCandCaptch(token)}
                    />
                  </form>
                  <button form='Register-Candidate' className='button button-primary'>
                    Sign Up as Job Seeker
                  </button>
                </div>
              </>
            ) : (
              <>
                {/* Register-Employer */}
                <div className='col-md-6 mt-5 mb-5'>
                  <h1>Sign Up as Employer</h1>
                  <br />
                  {employer.map((field, index) => (
                    <div key={index} className='col-lg-9 col-md-12 mb-4'>
                      {FormInput({
                        field:{ ...field, typeBasic:typeBasic },
                        form: 'Register-Employer',
                        onChange: handleEmployerInputChange,
                      })}
                    </div>
                  ))}
                  <form id='Register-Employer' onSubmit={handleRegisterEmployer}>
                    <ReCaptchaV2
                      sitekey={process.env.REACT_APP_SITE_KEY}
                      onChange={token => setEmpCaptch(token)}
                    />
                  </form>
                  <button form='Register-Employer' className='button button-primary'>
                    Sign Up as Employer
                  </button>
                </div>
              </>
            )}
          </div>
      </div>
    </div>
  )
}
