import { checkTokenExpiration } from './authAction'
import { SUCCESS_NOTIFIER, toastNotifier } from '../../component/toaster/Toaste'
import { errorHandler } from '../../component/errorHandler/errorHandler'
import {
  GET_RESUME_ERROR,
  GET_RESUME_LOADING,
  GET_RESUME_SUCCESS,
  UPDATE_RESUME_LOADING,
  UPDATE_RESUME_SUCCESS,
  UPDATE_RESUME_ERROR,
} from '../types'
import { getResumeCall, updateResumeCall } from '../../component/network/axios'

export const getResumeAction = () => {
  return async (dispatch) => {
    dispatch({ type: GET_RESUME_LOADING })
    try {
      const data = await getResumeCall()
      dispatch({ type: GET_RESUME_SUCCESS, payload: data.data })
    } catch (error) {
      errorHandler({ error })
      dispatch({ type: GET_RESUME_ERROR, payload: error.message })

      checkTokenExpiration({ dispatch, parentFunction: getResumeAction, error })
    }
  }
}

export const updateResumeAction = (formData) => {
  return async (dispatch) => {
    dispatch({ type: UPDATE_RESUME_LOADING })
    try {
      const data = await updateResumeCall({ body: formData })
      dispatch(getResumeAction())
      dispatch({ type: UPDATE_RESUME_SUCCESS, payload: data.data })
      toastNotifier({ type: SUCCESS_NOTIFIER, message: 'Resume Updated' })
    } catch (error) {
      errorHandler({ error })
      dispatch({ type: UPDATE_RESUME_ERROR, payload: error.message })

      checkTokenExpiration({
        dispatch,
        parentFunction: updateResumeAction,
        error,
      })
    }
  }
}
