import { Navigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import SwitchSelector from 'react-switch-selector'
import ReCaptchaV2 from 'react-google-recaptcha'

import FormInput from './formFields/FormInput'
import { INFO_NOTIFIER, SUCCESS_NOTIFIER, toastNotifier } from './toaster/Toaste'
import { forgetPasswordCall } from './network/axios'
import { errorHandler } from './errorHandler/errorHandler'
import { checkAuth, loginAction } from '../store/actions/authAction'
import {
  EMPLOYEE_LOGIN_FIELDS,
  EMPLOYER_LOGIN_FIELDS,
  FORGET_PASSWORD_FIELDS,
} from './helper/inputConstants'
import { MODAL_CONTENT_ON_FORGOT_PASSWORD, showModal } from '../store/ui'
import './assets/css/style.css'
import img from './assets/images/loginSignup.svg'

export default function Login() {
  const formData = {}
  const dispatch = useDispatch()
  const auth = useSelector((state) => state.auth)
  const [employer, setEmployer] = useState(EMPLOYER_LOGIN_FIELDS)
  const [candidate, setCandidate] = useState(EMPLOYEE_LOGIN_FIELDS)
  const [forgetPassword, setForgetPassword] = useState(FORGET_PASSWORD_FIELDS)
  const [isForgetPasswordLoading, setIsForgetPasswordLoading] = useState(false)
  const [sectionToggle, setSectionToggle] = useState(false)
  const [candCaptch, setCandCaptch] = useState('')
  const [empCaptch, setEmpCaptch] = useState('')

  const handleSigninCandidate = (e) => {

    e.preventDefault()

    if (!candCaptch) {
      toastNotifier({ type: INFO_NOTIFIER, message: 'Fill the reCaptcha' })
      return
    }

    candidate.filter((item) => (formData[item.name] = item.value))
    dispatch(loginAction({ data: formData, userRole: 'candidate' }))
  }



  const handleSigninEmployer = (e) => {
    e.preventDefault()


    if (!empCaptch) {
      toastNotifier({ type: INFO_NOTIFIER, message: 'Fill the reCaptcha' })
      return
    }


    employer.filter((item) => (formData[item.name] = item.value))
    dispatch(loginAction({ data: formData, userRole: 'employer' }))
  }

  const handleForgetPassword = async (e) => {
    e.preventDefault()
    setIsForgetPasswordLoading(true)
    try {
      const res = await forgetPasswordCall({ body: { email: forgetPassword[0].value } })
      dispatch(showModal(MODAL_CONTENT_ON_FORGOT_PASSWORD))
      if (res.data.message[0] === 'Success') {
        toastNotifier({ type: SUCCESS_NOTIFIER, message: 'Chcek your email' })
        setForgetPassword(FORGET_PASSWORD_FIELDS)
      }
    } catch (error) {
      errorHandler({ error })
    } finally {
      setIsForgetPasswordLoading(false)
    }
  }

  const handleCandidateInputChange = (e) => {
    setCandidate([
      ...candidate.map((item) =>
        item.name === e.target.name ? { ...item, value: e.target.value } : item
      ),
    ])
  }
  const handleEmployerInputChange = (e) => {
    setEmployer([
      ...employer.map((item) =>
        item.name === e.target.name ? { ...item, value: e.target.value } : item
      ),
    ])
  }
  const handleForgetPasswordInputChange = (e) => {
    setForgetPassword([
      ...forgetPassword.map((item) =>
        item.name === e.target.name ? { ...item, value: e.target.value } : item
      ),
    ])
  }

  useEffect(() => dispatch(checkAuth()), [dispatch])

  if (auth.isAuthenticated) {
    return <Navigate to="/jobs" />
  }




  const options = [
    {
      label: 'Sign in as Employer',
      value: {
        foo: true,
      },
      selectedBackgroundColor: '#4d71a8',
      selectedFontColor: 'white',
    },
    {
      label: 'Sign in as Job Seeker',
      value: 'bar',
      selectedBackgroundColor: '#4d71a8',
      selectedFontColor: 'white',
    },
  ]
  const initialSelectedIndex = options.findIndex(({ value }) => value === 'bar')
  return (
    <div className="container ">
      <div
        className="your-required-wrapper"
        style={{ width: 300, height: 45, margin: '-1rem auto', marginTop: '8rem' }}
      >
        <SwitchSelector
          onChange={() => setSectionToggle(!sectionToggle)}
          options={options}
          initialSelectedIndex={initialSelectedIndex}
          backgroundColor="#dbdede"
          fontColor="black"
          border="1px solid #4d71a8  "
          selectionIndicatorMargin={3}
          wrapperBorderRadius={40}
          optionBorderRadius={40}
          fontWeight="bold"
        />
      </div>

      <div className="row ">
        <div className="login-signup-wrapper">
          <div className="login-signup-svg">
            <img src={img} alt="login/signup" />
          </div>
          {!sectionToggle ? (
            <>
              {/* SigninCandidate */}
              <div className="col-md-6 mt-5 mb-5">
                <div className={('wipe', sectionToggle ? 'first' : 'second')}>
                  <h1>Sign in as Job Seeker</h1>
                  <br />
                  {/* <form id="SigninCandidate" onSubmit={handleSigninCandidate} /> */}
                  {candidate.map((field, index) => (
                    <div key={index} className="col-lg-9 col-md-12 mb-4">
                      {FormInput({
                        field,
                        form: 'SigninCandidate',
                        onChange: handleCandidateInputChange,
                      })}
                    </div>
                  ))}


                  <form id='SigninCandidate' onSubmit={handleSigninCandidate}>
                    <ReCaptchaV2
                      sitekey={process.env.REACT_APP_SITE_KEY}
                      onChange={token => setCandCaptch(token)}
                    />
                  </form>





                  <button
                    disabled={auth.isLoading}
                    form="SigninCandidate"
                    className="button button-primary"
                  >
                    {auth.isLoading ? 'Loading...' : 'Login as Job Seeker'}
                  </button>
                </div>
              </div>
            </>
          ) : (
            <>
              {/* SigninEmployer */}
              <div className="col-md-6 mt-5 mb-5">
                <div className={('wipe', !sectionToggle ? 'four' : 'third')}>
                  <h1>Sign in as Employer</h1>
                  <br />
                  {/* <form id="SigninEmployer" onSubmit={handleSigninEmployer} /> */}
                  {employer.map((field, index) => (
                    <div key={index} className="col-lg-9 col-md-12 mb-4">
                      {FormInput({
                        field,
                        form: 'SigninEmployer',
                        onChange: handleEmployerInputChange,
                      })}
                    </div>
                  ))}



                  <form id='SigninEmployer' onSubmit={handleSigninEmployer}>
                    <ReCaptchaV2
                      sitekey={process.env.REACT_APP_SITE_KEY}
                      onChange={token => setEmpCaptch(token)}
                    />
                  </form>


                  <button
                    disabled={auth.isLoading}
                    form="SigninEmployer"
                    className="button button-primary"
                  >
                    {auth.isLoading ? 'Loading...' : 'Login as Employer'}
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
        {/* ForgetPassword */}
        <div className="col-md-6 mt-5 mb-5">
          <form id="ForgetPassword" onSubmit={handleForgetPassword} />
          {FormInput({
            field: forgetPassword[0],
            form: 'ForgetPassword',
            onChange: handleForgetPasswordInputChange,
          })}
          <button
            form="ForgetPassword"
            className="button button-primary"
            disabled={isForgetPasswordLoading}
          >
            {isForgetPasswordLoading ? 'loading...' : 'Forget Password'}
          </button>
        </div>
      </div>
    </div>
  )
}
