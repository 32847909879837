import React, { useState } from 'react'

const TermsOfServices = () => {
  const [data, setData] = useState([
    {
      heading: 'Terms of Service',
      text: [
        <>
          Welcome to Apex Finance Career Portal (
          <a href="https://www.apex-financecareers.com ">https://www.apex-financecareers.com </a>
          “the Site") . The following terms and conditions (the "Terms of Service" or “Agreement”)
          form a binding agreement between you and us. The terms "Apexway", “Finance Careers”, "us",
          "we" or "our" refers to IMI APEXWAY SOLUTIONS (SMC-PRIVATE) LIMITED who, in conjunction
          with its affiliated companies, offers the Services (as defined below). The term "you" or
          "your" refers to the person accessing or using the Site and Services, or the company or
          organization on whose behalf that person accesses the Site and Services.
        </>,
      ],
    },

    {
      heading: 'Our Services',
      text: [
        <>
          We provide an online platform for connecting the candidates (job seekers) and employers
          through our job portal site at{' '}
          <a href="https://www.apex-financecareers.com"> https://www.apex-financecareers.com </a>
          and any other related websites, applications or other distribution channels we may, from
          time to time, operate (collectively, the "Services"). You may use the Services for your
          personal and business use or for internal business purpose in the organization that you
          represent.
        </>,
      ],
    },

    {
      heading: 'Acceptance of the Terms',
      text: [
        <>
          By accessing or using the Services, Terms of Service, whether you are a “Member” (which
          means that you have registered for an account) or a “Visitor” (which means that you are
          visiting the Site or any of our related websites, applications or the like). The term
          “User” refers to a Visitor or a Member. These Terms of Service along with any other
          guidelines we may post from time to time, such as our Privacy Policy (collectively, the
          “Guidelines”) will govern your use of the Services. If you do not agree to these Terms of
          Service or any of our Guidelines, do not use any of our Services
        </>,
      ],
    },

    {
      heading: 'Eligibility',
      text: [
        <>
          By using the Services, you represent and warrant that (a) you are fully able and competent
          to enter into the terms, conditions, obligations, representations and warranties set forth
          in these Terms of Service; (b) all registration information you submit is truthful and
          accurate; (c) you will maintain the accuracy of such information; (d) you are 18 years of
          age and older; and (e) your use of the Services does not violate any applicable law or
          regulation. Use of the Services is void where prohibited.
        </>,
      ],
    },

    {
      heading: 'Your Account',
      text: [
        <>
          You can create an account with us by registering on the Site. You are solely responsible
          for maintaining the confidentiality of your password, and fully responsible for all
          activities that occur under your account. You agree not to use the account, username or
          password of another User at any time or to disclose your password to any third party or do
          anything else that might jeopardize the security of your account. You agree to notify us
          immediately of any unauthorized use of your account. If we have reasonable grounds to
          suspect violation of these terms or that registration information you have provided is
          untrue, inaccurate, outdated, or incomplete, we may terminate your User account and refuse
          current or future use of any or all of the Services. We are not responsible for any loss
          or damage to you or any third party that may be incurred as a result of any unauthorized
          access and/or use of your account, or otherwise.
        </>,
      ],
    },

    {
      heading: 'Fees and Subscription Plans',
      text: [
        <>
          Our services are available on monthly and yearly subscription plans (“Paid Services”).
          When you subscribe for Paid Services, the seller of these services and the party you are
          contracting with is IMI APEXWAY SOLUTIONS (SMC-PRIVATE LIMITED. Your subscription is
          billed in advance and will be automatically renewed at the end of each subscription period
          unless you inform us that you do not wish to renew the subscription. We reserve the right
          to change the subscription fee and to charge for use of Services that are currently
          available free of charge. You will not be charged for using any Service unless you have
          opted for a paid subscription plan. Information on the subscription options and charges
          for all paid Services is available at:{' '}
          <a href="http://www.apex-financecareers.com "> http://www.apex-financecareers.com </a>.
          Unless otherwise stated, all fees are quoted in Pakistan Rupees.
        </>,
      ],
    },

    {
      heading: 'Payments',
      text: [
        <>
          After submitting the form on our portal for registration you will receive an email from
          us. The email will have all necessary bank details for payment of subscription amount. You
          are responsible for paying all fees and applicable taxes associated with the Paid Services
          in a timely manner with a valid payment method. You also authorize us to collect and store
          your payment details, along with other related transaction information. You agree to
          submit any disputes regarding any charge to your account in writing to us within thirty
          (30) days of such charge, otherwise such dispute will be waived and such charge will be
          final and not subject to challenge.
        </>,
      ],
    },

    {
      heading: 'User Data Rights',
      text: [
        <>
          As a Member, you will have access to certain information explicitly submitted by other
          Users, such as contact information, submitted through our website (“User Submissions”). In
          order to provide our Services, you also allow us to collect, store and use additional data
          about the usage of our tools and information about your contacts that was not explicitly
          submitted by any User, such as IP address of a contact or statistics about the usage of
          the portal
          <br />
          <br />
          <br />, You give permission to Apexay to store copies of the data and documents you are
          required to share with us for verification and portal account creation. You acknowledge
          that we shall not be held responsible for any loss of whatever nature and type for the
          accuracy or loss of data that you share with us.
        </>,
      ],
    },

    {
      heading: 'Prohibited Activity',
      text: [
        <>
          You agree not to use the Services for illegal, harmful, misleading, fraudulent or other
          malicious purposes or to post, disseminate or communicate any unlawful, defamatory,
          obscene, lewd, excessively violent, harassing, sexually explicit or otherwise
          objectionable subject matter or for the transmission of material that contains viruses or
          other malicious code, or that which infringes or may infringe intellectual property or
          other rights of another. You agree not to use the Services for the transmission of "junk
          mail", "spam", "chain letters", “phishing” or unsolicited mass distribution of email.
          <br />
          <br />
          <br/>
          Without assuming any obligation to do so, we may delete or suspend any account or
          assignment associated with it, that we have reasonable grounds to believe violates this
          Agreement or that may be offensive or illegal, or violate the rights, harm, or threaten
          the safety of any person.
        </>,
      ],
    },

    {
      heading: 'Inactive User Accounts Policy',
      text: [
        <>
          We reserve the right to disable or deactivate unpaid User accounts that are inactive for
          more than 90 days. In the event of such termination, all data associated with such User
          account may be deleted. We will provide you prior notice of such termination by email. In
          the future, we may impose different usage restrictions than currently offered
        </>,
      ],
    },

    {
      heading: 'Service Communications',
      text: [
        <>
          By providing Apexway your email address, phone number or any other personal information as
          a Member, you consent to our using the email address or phone number to send you
          Service-related notices, including any notices required by law, in lieu of communication
          by postal mail. We may also use your email address or phone number to send you other
          messages, such as updates, user communications, newsletters, changes to features of the
          Service, or Apexway offers. If you do not want to receive certain email messages and
          communications, you may opt out by contacting our support team. Opting out may prevent you
          from receiving valuable messages regarding updates, improvements, offers, or
          communications from other Users. Apexway reserves the right to send you notices about your
          account even if you opt out of all voluntary email and phone notifications.
        </>,
      ],
    },

    {
      heading: 'Privacy Protection',
      text: [
        <>
          We care about your privacy. Your information may be stored and processed in any country in
          which Apexway and its service providers maintain facilities, including outside of the
          Pakistan. In this regard, or for purposes of sharing or disclosing data in accordance with
          this Agreement, Apexway reserves the right to transfer information outside of your
          country. By using the Services, you consent to any such transfer of information outside of
          your country.
        </>,
      ],
    },

    {
      heading: 'Candidates/Job Seekers and Employers/Organizations interactions',
      text: [
        <>
          You release Apexway, its officers, employees, agents and successors from claims, demands
          and damages of every kind or nature arising out of or related to any disputes between
          service providers and service seekers. Apexway expressly disclaims responsibility for the
          accuracy, quality, legality, nature, availability or reliability of Sites linked to by or
          through the Site or Services.
        </>,
      ],
    },

    {
      heading: 'Copyright Protection',
      text: [
        <>
          Apexway trademarks, logos, service marks, images, trade names, designs, page headers,
          button icons, scripts and other distinctive branding features used in connection with the
          Services are the trademarks or service marks of Apexway and may not be copied, imitated,
          or used, in whole or in part, without the prior written permission of Apexway.
        </>,
      ],
    },

    {
      heading: 'Disclaimer of Warranties',
      text: [
        <>
          Apexway does not warrant that (i) the Services will meet your specific requirements, (ii)
          the Services will be uninterrupted, timely, secure, or error-free, (iii) the results that
          may be obtained from the use of the Services will be accurate or reliable, (iv) the
          quality of any products, services, information, or other material purchased or obtained by
          you through the Services from service providers will meet your expectations, and (v) any
          errors in the Services will be corrected.
        </>,
      ],
    },

    {
      heading: 'Limitation of Liability',
      text: [
        <>
          You expressly understand and agree that IMI APEXWAY SOLUTIONS (SMC-PRIVATE) LIMITED, its
          subsidiaries, and affiliates, and their respective officers, directors, agents,
          co-branders or other partners, and employees (“Apexway”) shall not be liable for any
          direct, indirect, incidental, special, consequential or exemplary damages, including but
          not limited to, damages for loss of profits, goodwill, use, data or other intangible
          losses resulting from: (i) the use or the inability to use the Services; (ii) the cost of
          procurement of substitute goods and services resulting from any goods, data, information
          or services purchased or obtained or messages received or transactions entered into
          through or from the Services; (iii) unauthorized access to or alteration of your
          transmissions or data; (iv) statements or conduct of any member or third party on the
          Services; (v) or any other matter relating to the Services. Your sole and exclusive remedy
          for any dispute with Apexway related to any of the Services shall be termination of such
          Service. In no event shall Apexway’s entire liability to you in respect of any Service,
          whether direct or indirect, exceed the fees paid by you towards such Service in the
          previous three (03) months.
        </>,
      ],
    },

    {
      heading: 'Indemnification',
      text: [
        <>
          You agree to indemnify, defend, and hold harmless Apexway and its third party service
          providers from and against any claim, demand, loss, damage, cost, or liability (including
          reasonable attorneys' fees) arising out of or relating to:<br/>
          <ul>
            <li className="termsOfServiceslist">
              {' '}
              any Content you submit, post, transmit, or make available through the Site or
              Services;
            </li>
            <li className="termsOfServiceslist">your use or misuse of the Services;</li>
            <li className="termsOfServiceslist">your connection to the Services;</li>
            <li className="termsOfServiceslist">
              your breach or alleged breach of this Agreement; or
            </li>
            <li className="termsOfServiceslist">
              your violation of any rights (including intellectual property rights) of a third
              party.
            </li>
          </ul>
        </>,
      ],
    },

    {
      heading: 'Changes to Services',
      text: [
        <>
          We reserve the right at any time (and from time to time) to modify, suspend, or
          discontinue providing the Services or any part thereof with or without notice. We will not
          be liable to you or to any third party for any modification, suspension or discontinuance
          of the Services.
        </>,
      ],
    },

    {
      heading: 'Amendments',
      text: [
        <>
          We may amend, modify, change, add or remove portions of this Agreement or any Guidelines
          at any time without notice to you by posting a revised version on
          <a href="https://www.apex-way.com"> www.apex-way.com</a> or elsewhere on the Site. The revised
          version will be effective at the time we post it. Your continued use of the Services after
          posting of the changes constitutes your binding acceptance of such changes.
        </>,
      ],
    },

    {
      heading: 'Term and Termination',
      text: [
        <>
          You may terminate your account and end your use of the Services at any time and for any or
          no reason. Apexway has the right (at its sole discretion) for any reason to (i) delete,
          disable or deactivate your account, block your email or IP address, or otherwise terminate
          your access to or use of the Services, (ii) remove and discard any Content within any
          account or anywhere on the Site or (iii) shut down an account, with or without notice, and
          with no liability of any kind to you.
          <br />
          <br />
          <br/>
          If you terminate your account, we will have no obligation to refund you any fees you may
          have paid except as may be required by applicable law. Upon deactivating your account,
          this Agreement terminates and your access rights to the Site and any Services immediately
          cease to exist.
          <br />
          <br />
          <br/>
          This Agreement shall be governed by the laws of Pakistan without giving effect to any
          principles that may provide the application of the law of another jurisdiction. Any
          controversy or claim arising out of or relating to this Agreement shall be settled in
          accordance with the laws of Pakistan.
        </>,
      ],
    },

    {
      heading: 'Cancellation and refunds',
      text: [
        <>
          <br /> There is no lock-in period. You can cancel your subscription anytime!
          <br />
          Refunds are allowed for annual subscriptions and also for monthly subscriptions of more
          than 3 months. Refunds shall be made as per the following:
          <br/>
          <ul>
            <li className="termsOfServiceslist">
              60% refund (if cancelation request is made within 60 days of subscribing to annual or
              more than 3-month monthly plan)
            </li>

            <li className="termsOfServiceslist">
              40% refund (if cancelation request is made after 60 days (up to 90 days) of
              subscribing to annual or more than 3-month monthly plan){' '}
            </li>

            <li className="termsOfServiceslist">
              No refunds will be allowed after 90 days of subscribing to the annual or more than
              3-month monthly plan
            </li>
          </ul>
          No refunds will be allowed on subscriptions of less than 3-month monthly plans.
        </>,
      ],
    },

    {
      heading: 'Refund Procedure',
      text: [
        <>
          To request a refund under this policy, you must contact us within the mentioned days
          (calendar days) of your Apexway account activation. Just send an email to us with subject
          heading "Request Refund". We'll gladly refund you your subscription fees within 4-5
          working days of your refund request.
        </>,
      ],
    },

    {
      heading: 'General',
      text: [
        <>
          The failure of Apexway to exercise or enforce any right or provision of this Agreement
          does not constitute a waiver of such right or provision. If any provision of this
          Agreement is found by a court of competent jurisdiction to be invalid, the parties
          nevertheless agree that the court should try to give effect to the parties' intentions as
          reflected in the provision, and the other provisions of this Agreement remain in full
          force and effect. Apexway may assign this Agreement in whole or in part in its sole
          discretion without your consent and without notice. This Agreement constitutes the entire
          agreement between you and Apexway and governs your use of the Services, superseding any
          prior agreements (whether written or oral) between you and Apexway regarding the subject
          matter hereof. Nothing in this Agreement shall prevent us from complying with the law and
          applicable regulations.
        </>,
      ],
    },
  ])

  return (
    <>
      <section className="section breadcrumbs-custom breadcrumbs-custom-overlay-3">
        <div id="Privacybg" className="breadcrumbs-custom-main bg-image bg-gray-700">
          <div className="container">
            <h3 className="breadcrumbs-custom-title">Terms Of Services</h3>
          </div>
        </div>
      </section>

      <section className="container mb-5">
        <div className="row ">
          <div className="col-lg-9 col-xl-8">
            {data.map((val, ind) => {
              return (
                <div key={ind} className="mt-5">
                  <h4>{val.heading}</h4>
                  <p className="pt-3">{val.text}</p>
                </div>
              )
            })}
          </div>
        </div>
      </section>
    </>
  )
}

export default TermsOfServices
