import React from 'react'
import { useNavigate } from 'react-router-dom'

export const ErrorPage = () => {
    const navigate = useNavigate()
  return (
    <div style={{minHeight:'70vh',display:'flex',justifyContent:'center',alignItems:'center'}}>

        <div style={{textAlign:'center'}}><h1 style={{fontSize:'8rem',color:'#303841'}}>404</h1>
        <h3 style={{color:'#303841'}}>Page not Found!</h3>
        <button onClick={()=>navigate('/')} className="btn btn-outline-success my-3 " >Go To Home</button>
        </div>
    </div>
  )
}
