import React, { useState } from 'react'

export const ContactPersonEmailField = ({allFields}) => {

    const {field, form, onChange, showLable} = allFields
    
  const [isDisabled, setIsDisabled] = useState(false)


  const checkBoxHandle = (e) =>{
    setIsDisabled(e.target.checked);
    const isChecked = e.target.checked

    let checbBoxObj = {
      target:{
        name:'UseOrganizationEmail',
        value:e.target.checked
      }
    }
    if(isChecked){
      onChange({target:{name:'contactPerson.Email',value:''}})
    }
    onChange(checbBoxObj)

  }



  return (
    <>

    {isDisabled && <div className="form-label-outside mb-2">Contact Person Email </div>}

      {showLable && !isDisabled && (
        <label className="form-label-outside mb-2" htmlFor={field.name}>
          {field.label}
        </label>
      )}
      {!isDisabled &&
        <input
        className={`${field.readOnly ? 'border-0 w-100' : 'form-input'} mb-1`}
        type={field.type}
        disabled={field.readOnly}
        form={form}
        name={field.name}
        id={field.id || field.name}
        readOnly={field.readOnly}
        required={field.required}
        value={field.value}
        placeholder={!isDisabled ? field.placeholder : ''}
        onChange={onChange}
      />
      }
      {/* {field.hint && !isDisabled && <div>Hint: {field.hint}</div>} */}
      <div>
      <input onClick={(e)=>checkBoxHandle(e)} type='checkbox' id="SameasOrganizationEmail" className="me-2 mt-2" />
      <label htmlFor="SameasOrganizationEmail"> Same as Organization Email</label>
      </div>
     
      </>
      )
}
