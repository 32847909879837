import { toast, ToastContainer } from 'react-toastify'

export const INFO_NOTIFIER = 'INFO_NOTIFIER'
export const SUCCESS_NOTIFIER = 'SUCCESS_NOTIFIER'
export const ERROR_NOTIFIER = 'ERROR_NOTIFIER'

const tostConfig = {
  position: 'top-right',
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: 0,
}

const info = (message) => toast.info(message, tostConfig)
const success = (message) => toast.success(message, tostConfig)
const error = (message) => toast.error(message, tostConfig)

export const toastNotifier = ({ type, message }) => {
  if (type === INFO_NOTIFIER) info(message)
  if (type === SUCCESS_NOTIFIER) success(message)
  if (type === ERROR_NOTIFIER) error(message)
}

export default function Toaster() {
  return (
    <ToastContainer
      position="top-center"
      autoClose={3000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      
    />
  )
}
